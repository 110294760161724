import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  ProposalMemberPropertyToUpdate,
  ProposalTeamMember,
} from '@src/stores/Missions/Proposal';
import UserAvatar from '@src/components/UserAvatar';
import { createUseStyles } from 'react-jss';
import {
  AdminMissionApplicationObject,
  MissionApplicationId,
} from '@a_team/models/dist/MissionApplicationObject';
import { MissionRoleId } from '@a_team/models/dist/MissionRole';
import {
  BorderRadius,
  Button,
  Colors,
  FontSizes,
  Icon,
  IconType,
  SecondaryInput,
  Spacing,
} from '@ateams/components';
import TooltipWrapped from '@src/components/TooltipWrapped';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { UserId } from '@a_team/models/dist/UserObject';
import {
  Checkbox,
  Icon as UiIcon,
  Input,
  Modal,
  ModalWidth,
  theme,
  Typography,
  Wysiwyg,
} from '@a_team/ui-components';
import { isValidUrl } from '@src/helpers/urls';
import {
  getHTMLStringTextLength,
  validateStringLength,
} from '@src/helpers/richTextEditor';
import TextButton from '@src/components/TextButton';
import StarredBuilderBanner from '@src/views/Profile/Main/StarredBuilderBanner';
import { ProposalDataCurrency } from '@a_team/models/dist/ProposalObject';
import { getProposalDataCurrencySymbol } from '../utils';
import { SmallButton } from '@src/components/SmallButton';
import { monthlyRateToHourlyRate } from '../../utils';
import { round } from 'lodash';
import { useQueryUserReviews } from '@src/rq/userReview';
import Recommendation from '@src/views/Profile/Main/Recommendations/Recommendation';
import LinkedinRecommendation from '@src/views/Profile/Main/Recommendations/LinkedinRecommendation';

interface Props {
  member: ProposalTeamMember;
  application: AdminMissionApplicationObject | undefined;
  index: number;
  missionRolesMargin: number;
  onMemberRemove: (aid: MissionApplicationId, rid: MissionRoleId) => void;
  onDataEdit: (
    aid: MissionApplicationId,
    property: ProposalMemberPropertyToUpdate,
    data: string | number | boolean | string[],
  ) => void;
  onDragStart: (aid: MissionApplicationId, fromIndex: number) => void;
  onIncludeReplyToggle: (aid: MissionApplicationId, checked: boolean) => void;
  onDragOver: (toIndex: number) => void;
  onDrop: (rid: MissionRoleId) => void;
  onOpenBlurbHistory: (userId: UserId) => void;
  isBeingDragged?: boolean;
  isDropArea?: boolean;
  showTfsPitch?: boolean;
  draggable: boolean;
  blurbHistoryCount?: number;
  onClickOnCopyApplicationDataClipboard?: () => void;
  onClickOnGenerateBlurb?: () => void;
  currency: ProposalDataCurrency;
  onClickOnAvatar?: () => void;
  monthlyRatesDisabled?: boolean;
  withLinkedInRecommendations?: boolean;
}
export const BLURB_TEXT_LENGTH_LIMIT = 1000;
const TALENT_CATEGORY_GROUP_SHOW_PORTFOLIO = 'Designers';

const useStyles = createUseStyles({
  memberContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  member: {
    marginBottom: 16,
    borderBottom: '1px solid #c0c0c0',
    padding: 16,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    background: '#F7F7F7',
    '&:last-of-type': {
      borderBottom: 'none',
      marginBottom: 0,
    },
    borderRadius: BorderRadius.medium,
  },
  memberStarred: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  modal: {
    maxWidth: '1000px',
  },
  memberTopContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    '& > div': {
      flex: 1,
    },
  },
  memberInner: {
    display: 'flex',
  },
  dragged: {
    background: 'linear-gradient(to left, #687bf7, #72d9ef)',
    color: '#fff',
    borderRadius: 5,
    transition: 'background-color 100ms linear',
  },
  input: {
    borderColor: '#C0C0C0',
  },
  headerRow: {
    display: 'flex',
    width: '100%',
  },
  dropArea: {
    position: 'relative',
    '& .inner': {
      visibility: 'hidden',
    },
    '&:before': {
      content: '"Drop Here"',
      color: 'black',
      border: '2px dashed #c0c0c0',
      borderRadius: 3,
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
  },
  blockLabel: {
    display: 'block',
  },
  greyText: {
    color: '#777',
  },
  textInputContainer: {
    marginTop: 12,
    width: '100%',
  },
  inputRow: {
    display: 'flex',
    gap: Spacing.small,
    marginTop: Spacing.small,
  },
  halfWidth: {
    flex: 1,
  },
  fullWidth: {
    width: '100%',
    flexShrink: 0,
  },
  blurbTextArea: {
    marginTop: 6,
    border: '1px solid #C0C0C0',
    borderRadius: 4,
    maxWidth: '100%',
    background: 'white',
    overflow: 'hidden',
    minHeight: 40,
    '&&': { paddingBottom: 0 },
    '& > div': {
      position: 'relative',
    },
    '&& .ProseMirror': {
      width: '100%',
      position: 'absolute',
      boxSizing: 'border-box',
      fontSize: 15,
      overflowY: 'hidden',
      padding: '8px 12px',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      '& p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& > p:first-of-type': {
        marginTop: 0,
      },
    },
  },
  blurbTextAreaCounter: {
    fontSize: '12px',
    textAlign: 'end',
    height: '21px',
    marginTop: '4px',
  },
  blurbTextAreaCounterError: {
    color: theme.colors.Red[600],
  },
  iconRow: {
    position: 'relative',
    '& label:first-of-type': {
      paddingLeft: 22,
    },
    '& > span:first-of-type': {
      position: 'absolute',
      left: 0,
      top: 3,
    },
  },
  noPaddingLabel: {
    paddingLeft: 0,
  },
  history: {
    cursor: 'pointer',
    color: theme.colors.Baracus[600],
  },
  root: {
    '&& .ProseMirror-focused, &&.menu-open .ProseMirror': {
      minHeight: 120,
      whiteSpace: 'unset',
      position: 'relative',
      overflowY: 'scroll',
    },
  },
  copyDataButton: {
    marginBottom: Spacing.medium,
    textAlign: 'left',
    width: '100%',
  },
  memberInfoContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flex: 1,
  },
  blurbLabelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  builderRateInputLabel: {
    marginRight: Spacing.small,
    marginBottom: Spacing.small,
    display: 'inline-block',
  },
  rateInput: {
    width: 55,
  },
  rateInputContainer: {
    width: 100,
  },
  secondaryText: {
    color: Colors.regularDark,
    fontSize: FontSizes.small,
    margin: `${Spacing.small}px 0`,
  },
  rateInputSeparator: {
    margin: `0 ${Spacing.small}px`,
  },
  disabled: {
    opacity: 0.5,
  },
});

export const Member = observer((props: Props): ReactElement => {
  const {
    member,
    application,
    missionRolesMargin,
    index,
    onIncludeReplyToggle,
    onMemberRemove,
    onDataEdit,
    onDragStart,
    onDragOver,
    draggable,
    isBeingDragged,
    isDropArea,
    onDrop,
    showTfsPitch,
    onOpenBlurbHistory,
    blurbHistoryCount = 0,
    onClickOnCopyApplicationDataClipboard,
    onClickOnGenerateBlurb,
    currency,
    onClickOnAvatar,
    monthlyRatesDisabled,
    withLinkedInRecommendations,
  } = props;
  const styles = useStyles();
  const [validation, setValidation] = useState({
    website: false,
    blurb: false,
    githubUrl: false,
    cvUrl: false,
    portfolioUrl: false,
    portfolioPassword: false,
  });
  const [
    builderMonthlyRateMonthInputState,
    setBuilderMonthlyRateMonthInputState,
  ] = useState<number | undefined>(undefined);
  const [
    builderMonthlyRateHourInputState,
    setBuilderMonthlyRateHourInputState,
  ] = useState<number | undefined>(undefined);
  const [reviewsModalOpen, setReviewsModalOpen] = useState(false);
  const [selectedReviews, setSelectedReviews] = useState<string[]>([]);
  const [selectedLinkedInRecommendations, setSelectedLinkedInRecommendations] =
    useState<string[]>([]);
  const { data: userReviews, isLoading: loadingReviews } = useQueryUserReviews({
    uid: member.userId || '',
    includeAllReviews: false,
    includeLinkedInRecommendations: withLinkedInRecommendations,
  });

  useEffect(() => {
    if (userReviews && userReviews.length > 0) {
      const reviews = userReviews
        .filter((r) => r.source !== 'linkedin')
        .map((r) => r.id);
      const linkedInRecs = userReviews
        .filter((r) => r.source === 'linkedin')
        .map((r) => r.id);

      setSelectedReviews(reviews);
      setSelectedLinkedInRecommendations(linkedInRecs);

      onDataEdit(member.aid, ProposalMemberPropertyToUpdate.Reviews, reviews);

      onDataEdit(
        member.aid,
        ProposalMemberPropertyToUpdate.LinkedInRecommendations,
        linkedInRecs,
      );
    }
  }, [userReviews]);

  useEffect(() => {
    if (builderMonthlyRateMonthInputState !== undefined) {
      handleChangeMonthlyRate(builderMonthlyRateMonthInputState);
      const hourlyRate = monthlyRateToHourlyRate(
        builderMonthlyRateMonthInputState,
      );
      setBuilderMonthlyRateHourInputState(round(hourlyRate, 2));
    }
  }, [builderMonthlyRateMonthInputState]);

  useEffect(() => {
    if (member.monthlyRate) {
      const monthlyRateWithoutMargin = Math.round(
        member.monthlyRate / (1 + missionRolesMargin),
      );
      if (monthlyRateWithoutMargin !== builderMonthlyRateMonthInputState) {
        setBuilderMonthlyRateMonthInputState(monthlyRateWithoutMargin);
        const hourlyRate = monthlyRateToHourlyRate(monthlyRateWithoutMargin);
        setBuilderMonthlyRateHourInputState(round(hourlyRate, 2));
      }
    }
  }, [member.monthlyRate]);

  const handleChangeRate = (builderRate: number, fee = missionRolesMargin) => {
    onDataEdit(
      member.aid,
      ProposalMemberPropertyToUpdate.BuilderHourlyRate,
      builderRate || 0,
    );
    onDataEdit(
      member.aid,
      ProposalMemberPropertyToUpdate.HourlyRate,
      Math.ceil(builderRate * (1 + fee)),
    );
  };

  const handleChangeMonthlyRate = (
    builderRate: number,
    fee = missionRolesMargin,
  ) => {
    onDataEdit(
      member.aid,
      ProposalMemberPropertyToUpdate.MonthlyRate,
      builderRate * (1 + fee),
    );
  };

  const handleReviewsChange = () => {
    onDataEdit(
      member.aid,
      ProposalMemberPropertyToUpdate.Reviews,
      selectedReviews,
    );

    onDataEdit(
      member.aid,
      ProposalMemberPropertyToUpdate.LinkedInRecommendations,
      selectedLinkedInRecommendations,
    );
  };

  const cvUrl = useMemo(() => {
    return (member.cvUrl || '').trim().replace(/ /g, '%20');
  }, [member.cvUrl]);

  const checkValidation = () => {
    const website = (member.tfsPitch?.website || '').trim();
    const githubUrl = (member.githubUrl || '').trim();
    const portfolioUrl = (member.portfolioUrl || '').trim();

    setValidation({
      website: website ? isValidUrl(website) : true,
      githubUrl: githubUrl ? isValidUrl(githubUrl) : true,
      cvUrl: cvUrl ? isValidUrl(cvUrl) : true,
      portfolioUrl: portfolioUrl ? isValidUrl(portfolioUrl) : true,
      blurb: validateStringLength(
        member.tfsPitch?.blurb,
        BLURB_TEXT_LENGTH_LIMIT,
      ),
      portfolioPassword: true,
    });
  };

  useEffect(() => {
    checkValidation();
  }, [member.tfsPitch, member.tfsPitch?.blurb]);

  const fieldIsInvalid = (
    value: string | undefined,
    isValid: boolean,
  ): boolean | undefined => {
    if (typeof value === 'undefined') return undefined;

    return !isValid;
  };

  const showPortfolio =
    member.roleCategory?.group === TALENT_CATEGORY_GROUP_SHOW_PORTFOLIO;

  return (
    <div className={styles.memberContainer}>
      {application?.starred && <StarredBuilderBanner isMemberTop={true} />}
      <div
        className={cx(
          styles.member,
          application?.starred && styles.memberStarred,
          {
            [styles.dragged]: isBeingDragged,
            [styles.dropArea]: isDropArea,
          },
        )}
        data-position={index}
        draggable={draggable}
        onDragStart={(e) =>
          onDragStart(member.aid, Number(e.currentTarget.dataset.position))
        }
        onDragOver={(e) => onDragOver(Number(e.currentTarget.dataset.position))}
        onDrop={(e) => {
          e.stopPropagation();
          onDrop(member.rid);
          checkValidation();
        }}
      >
        <div className={styles.headerRow}>
          <TextButton
            className={styles.copyDataButton}
            onClick={() => {
              if (onClickOnCopyApplicationDataClipboard) {
                onClickOnCopyApplicationDataClipboard();
              }
            }}
          >
            <Icon size="exact" type={IconType.Copy} /> Copy application and
            builder data to clipboard
          </TextButton>
          {!isBeingDragged && !isDropArea && (
            <TooltipWrapped
              style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'flex-end',
              }}
              title="Remove"
              position="top"
              arrow
              animation="fade"
            >
              <Icon
                type={IconType.Close}
                size={'xsmall'}
                muted
                onClick={() => onMemberRemove(member.aid, member.rid)}
              />
            </TooltipWrapped>
          )}
        </div>

        <div className={styles.memberInfoContainer}>
          <UserAvatar
            onClick={onClickOnAvatar}
            src={member.profilePictureURL}
            containerStyle={{ marginRight: Spacing.medium }}
          />
          <div style={{ flex: 1 }}>
            <div className={cx(styles.memberTopContainer)}>
              <div className={cx(styles.memberInner, 'inner')}>
                <div>
                  <div
                    className={styles.iconRow}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <UiIcon size="md" name="user" />
                    <span style={{ fontWeight: 500 }}>
                      <label>{member.fullName}</label>
                    </span>
                  </div>
                  <div
                    className={styles.iconRow}
                    style={{ marginTop: 8, minWidth: '165px' }}
                  >
                    <UiIcon size="md" name="dollar" />
                    <label className={styles.builderRateInputLabel}>
                      Builder hourly rate:
                    </label>{' '}
                    <div className={styles.rateInputContainer}>
                      <SecondaryInput
                        inputClassName={styles.rateInput}
                        prefix="$"
                        suffix={'/h'}
                        placeholder={'XX'}
                        onChange={(e) =>
                          handleChangeRate(parseInt(e.target.value))
                        }
                        value={member.builderHourlyRate || ''}
                      />
                    </div>
                    {member.hourlyRateRange && (
                      <p className={styles.secondaryText}>
                        Builder applied range:{' '}
                        {getProposalDataCurrencySymbol(currency)}
                        {member.hourlyRateRange.min} -{' '}
                        {getProposalDataCurrencySymbol(currency)}
                        {member.hourlyRateRange.max}
                        /h
                      </p>
                    )}
                    <Checkbox
                      disabled={!member.builderHourlyRate}
                      onChange={(e) =>
                        onDataEdit(
                          member.aid,
                          ProposalMemberPropertyToUpdate.ShowHourlyRate,
                          e.target.checked,
                        )
                      }
                      checked={
                        !member.builderHourlyRate
                          ? false
                          : props.member.showHourlyRate
                      }
                      label="Include hourly rate on proposal"
                    />
                  </div>
                </div>
              </div>

              <div style={{ alignSelf: 'flex-start', paddingBottom: 5 }}>
                <div className={styles.iconRow} style={{ display: 'flex' }}>
                  <UiIcon size="md" name="tag" />
                  <label>Team Fee: {missionRolesMargin * 100}%</label>
                </div>
                <div
                  className={cx(
                    styles.iconRow,
                    monthlyRatesDisabled && styles.disabled,
                  )}
                  style={{ marginTop: 8, minWidth: '165px' }}
                >
                  <UiIcon size="md" name="dollar" />
                  <label className={styles.builderRateInputLabel}>
                    Builder monthly rate:
                  </label>{' '}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div className={styles.rateInputContainer}>
                      <SecondaryInput
                        disabled={monthlyRatesDisabled}
                        inputClassName={styles.rateInput}
                        prefix="$"
                        suffix={'/m'}
                        placeholder={'XX'}
                        onChange={(e) =>
                          setBuilderMonthlyRateMonthInputState(
                            parseInt(e.target.value) || 0,
                          )
                        }
                        value={builderMonthlyRateMonthInputState || 0}
                      />
                    </div>
                    <div className={styles.rateInputSeparator}>OR</div>
                    <div className={styles.rateInputContainer}>
                      <SecondaryInput
                        disabled={true}
                        inputClassName={styles.rateInput}
                        prefix="$"
                        suffix={'/h'}
                        placeholder={'XX'}
                        value={builderMonthlyRateHourInputState || 0}
                      />
                    </div>
                  </div>
                  <p className={styles.secondaryText}>
                    {member.monthlyRateRange && (
                      <>
                        Builder applied range:{' '}
                        {getProposalDataCurrencySymbol(currency)}
                        {member.monthlyRateRange.min}{' '}
                        {member.monthlyRateRange.max
                          ? `-
                        ${getProposalDataCurrencySymbol(currency)}
                        ${member.monthlyRateRange.max}
                        /h`
                          : ''}
                      </>
                    )}
                  </p>
                  <Checkbox
                    disabled={
                      !monthlyRatesDisabled &&
                      !builderMonthlyRateMonthInputState
                    }
                    onChange={(e) =>
                      !monthlyRatesDisabled &&
                      onDataEdit(
                        member.aid,
                        ProposalMemberPropertyToUpdate.ShowMonthlyRate,
                        e.target.checked,
                      )
                    }
                    checked={
                      !monthlyRatesDisabled &&
                      !builderMonthlyRateMonthInputState
                        ? false
                        : props.member.showMonthlyRate
                    }
                    label="Include monthly rate on proposal"
                  />
                </div>
              </div>
            </div>
            {showTfsPitch && (
              <>
                <div className={styles.inputRow}>
                  <div className={cx(styles.fullWidth, styles.iconRow)}>
                    <UiIcon size="md" name="link" />
                    <label htmlFor="website" className={cx(styles.blockLabel)}>
                      Website{' '}
                      <span className={cx(styles.greyText)}>(optional):</span>
                    </label>
                    <Input
                      name="website"
                      placeholder="https://website.com"
                      onChange={(e) => {
                        onDataEdit(
                          member.aid,
                          ProposalMemberPropertyToUpdate.Website,
                          e.target.value,
                        );
                        checkValidation();
                      }}
                      value={member.tfsPitch?.website || ''}
                      size="stretch"
                      error={fieldIsInvalid(
                        member.tfsPitch?.website,
                        validation.website,
                      )}
                      errorText="Please enter a valid url"
                      className={styles.input}
                    ></Input>
                  </div>
                </div>
                <div className={styles.inputRow}>
                  <div className={cx(styles.halfWidth, styles.iconRow)}>
                    <UiIcon size="md" name="link" />
                    <label htmlFor="github" className={cx(styles.blockLabel)}>
                      Github{' '}
                      <span className={cx(styles.greyText)}>(optional):</span>
                    </label>
                    <Input
                      name="github"
                      placeholder="https://github.com"
                      onChange={(e) => {
                        onDataEdit(
                          member.aid,
                          ProposalMemberPropertyToUpdate.GithubUrl,
                          e.target.value,
                        );
                        checkValidation();
                      }}
                      value={member.githubUrl || ''}
                      size="stretch"
                      error={fieldIsInvalid(
                        member.githubUrl,
                        validation.githubUrl,
                      )}
                      errorText="Please enter a valid url"
                      className={styles.input}
                    ></Input>
                  </div>
                  <div className={cx(styles.halfWidth, styles.iconRow)}>
                    <UiIcon size="md" name="link" />
                    <label htmlFor="cvUrl" className={cx(styles.blockLabel)}>
                      CV{' '}
                      <span className={cx(styles.greyText)}>(optional):</span>
                    </label>
                    <Input
                      name="cvUrl"
                      onChange={(e) => {
                        onDataEdit(
                          member.aid,
                          ProposalMemberPropertyToUpdate.CVUrl,
                          e.target.value,
                        );
                        checkValidation();
                      }}
                      value={cvUrl || ''}
                      size="stretch"
                      error={fieldIsInvalid(cvUrl, validation.cvUrl)}
                      errorText="Please enter a valid url"
                      className={styles.input}
                    ></Input>
                  </div>
                </div>
                {showPortfolio && (
                  <div className={styles.inputRow}>
                    <div className={cx(styles.halfWidth, styles.iconRow)}>
                      <UiIcon size="md" name="link" />
                      <label
                        htmlFor="portfolioUrl"
                        className={cx(styles.blockLabel)}
                      >
                        Portfolio{' '}
                        <span className={cx(styles.greyText)}>(optional):</span>
                      </label>
                      <Input
                        name="portfolioUrl"
                        onChange={(e) => {
                          onDataEdit(
                            member.aid,
                            ProposalMemberPropertyToUpdate.PortfolioUrl,
                            e.target.value,
                          );
                          checkValidation();
                        }}
                        value={member.portfolioUrl || ''}
                        size="stretch"
                        error={fieldIsInvalid(
                          member.portfolioUrl,
                          validation.portfolioUrl,
                        )}
                        errorText="Please enter a valid url"
                        className={styles.input}
                      ></Input>
                    </div>
                    <div className={cx(styles.halfWidth, styles.iconRow)}>
                      <label
                        htmlFor="portfolioPassword"
                        className={cx(styles.blockLabel, styles.noPaddingLabel)}
                      >
                        Portfolio password{' '}
                        <span className={cx(styles.greyText)}>(optional):</span>
                      </label>
                      <Input
                        name="portfolioPassword"
                        onChange={(e) => {
                          onDataEdit(
                            member.aid,
                            ProposalMemberPropertyToUpdate.PortfolioPassword,
                            e.target.value,
                          );
                          checkValidation();
                        }}
                        value={member.portfolioPassword || ''}
                        size="stretch"
                        error={fieldIsInvalid(
                          member.portfolioPassword,
                          validation.portfolioPassword,
                        )}
                        errorText="Please enter a valid portfolio password"
                        className={styles.input}
                      ></Input>
                    </div>
                  </div>
                )}

                <div
                  className={cx(
                    styles.textInputContainer,
                    styles.fullWidth,
                    styles.iconRow,
                  )}
                >
                  <UiIcon size="md" name="pin" />

                  <div className={styles.blurbLabelContainer}>
                    <label htmlFor="blurb" className={cx(styles.blockLabel)}>
                      Blurb
                      <span
                        className={styles.history}
                        onClick={() =>
                          member.userId && onOpenBlurbHistory(member.userId)
                        }
                      >
                        {!!blurbHistoryCount && (
                          <>&nbsp;History ({blurbHistoryCount})</>
                        )}
                      </span>
                      :
                    </label>
                    <SmallButton
                      text="Generate blurb"
                      iconType={IconType.CrystalBall}
                      onClick={() =>
                        onClickOnGenerateBlurb && onClickOnGenerateBlurb()
                      }
                    />
                  </div>

                  <Wysiwyg
                    className={cx(styles.blurbTextArea, styles.root)}
                    placeholder="Short description..."
                    error={fieldIsInvalid(
                      member.tfsPitch?.blurb,
                      validation.blurb,
                    )}
                    html={member.tfsPitch?.blurb?.toString() || ''}
                    onChange={(val): void => {
                      onDataEdit(
                        member.aid,
                        ProposalMemberPropertyToUpdate.Blurb,
                        val,
                      );
                      checkValidation();
                    }}
                  />
                  <div
                    className={cx(
                      styles.blurbTextAreaCounter,
                      validation.blurb ? '' : styles.blurbTextAreaCounterError,
                    )}
                  >
                    {`${getHTMLStringTextLength(
                      member.tfsPitch?.blurb,
                    )} / ${BLURB_TEXT_LENGTH_LIMIT}`}
                  </div>
                </div>
                {application?.customQuestionsReplies &&
                  application.customQuestionsReplies.length > 0 && (
                    <Checkbox
                      checked={member.includeCustomQuestionReply}
                      onChange={(e) =>
                        onIncludeReplyToggle(application?.aid, e.target.checked)
                      }
                      label={"Include answer to client's custom question."}
                    />
                  )}
              </>
            )}
            {userReviews && (
              <>
                <label className={cx(styles.blockLabel, styles.noPaddingLabel)}>
                  Recommendations from past A.Team missions:
                </label>
                <Button
                  color={'backgroundDark'}
                  width={'auto'}
                  size={'xsmall'}
                  style={{
                    padding: 8,
                    border: '1px solid #C0C0C0',
                    marginTop: 8,
                  }}
                  squared
                  disabled={!loadingReviews && userReviews.length === 0}
                  loading={loadingReviews}
                  onClick={() => setReviewsModalOpen(true)}
                >
                  {!loadingReviews && userReviews.length === 0
                    ? 'No recommendations'
                    : member.reviews?.length
                    ? `${
                        (member.reviews?.length || 0) +
                        (member.linkedInRecommendations?.length || 0)
                      } recommendations selected`
                    : 'Select recommendations'}
                </Button>
                <Modal
                  width={ModalWidth.full}
                  className={styles.modal}
                  isOpen={reviewsModalOpen}
                  onClose={() => setReviewsModalOpen(false)}
                >
                  <Typography variant={'h3'} margin={'xxs'}>
                    Select Recommendations (
                    {selectedReviews.length +
                      selectedLinkedInRecommendations.length}{' '}
                    of {userReviews.length})
                  </Typography>
                  <Typography variant={'textSmall'}>
                    Select {member.fullName}’s recommendations that will be
                    displayed in the sample proposal.
                  </Typography>
                  <div
                    style={{
                      marginTop: 16,
                      marginBottom: 24,
                      padding: '8px 12px',
                      border: '1px solid #C0C0C0',
                    }}
                  >
                    <Checkbox
                      onChange={(e) => {
                        if (e.target.checked) {
                          const linkedInReviews = userReviews
                            .filter((r) => r.source === 'linkedin')
                            .map((r) => r.id);
                          const otherReviews = userReviews
                            .filter((r) => r.source !== 'linkedin')
                            .map((r) => r.id);
                          setSelectedLinkedInRecommendations(linkedInReviews);
                          setSelectedReviews(otherReviews);
                        } else {
                          setSelectedLinkedInRecommendations([]);
                          setSelectedReviews([]);
                        }
                      }}
                      checked={
                        selectedReviews.length +
                          selectedLinkedInRecommendations.length ===
                        userReviews.length
                      }
                      label={
                        'Select all recommendations from past A.Team missions'
                      }
                    />
                  </div>
                  {userReviews.map((review) => (
                    <div
                      key={review.id}
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        padding: 16,
                        ...(selectedReviews.includes(review.id) && {
                          backgroundColor: '#FBFFF7',
                        }),
                      }}
                    >
                      <Checkbox
                        style={{ marginTop: 24 }}
                        onChange={(e) => {
                          if (review.source === 'linkedin') {
                            if (e.target.checked) {
                              setSelectedLinkedInRecommendations([
                                ...selectedLinkedInRecommendations,
                                review.id,
                              ]);
                            } else {
                              setSelectedLinkedInRecommendations(
                                selectedLinkedInRecommendations.filter(
                                  (r) => r !== review.id,
                                ),
                              );
                            }
                          } else {
                            if (e.target.checked) {
                              setSelectedReviews([
                                ...selectedReviews,
                                review.id,
                              ]);
                            } else {
                              setSelectedReviews(
                                selectedReviews.filter((r) => r !== review.id),
                              );
                            }
                          }
                        }}
                        checked={
                          review.source === 'linkedin'
                            ? selectedLinkedInRecommendations.includes(
                                review.id,
                              )
                            : selectedReviews.includes(review.id)
                        }
                      />
                      {review.source === 'linkedin' ? (
                        <LinkedinRecommendation
                          id={review.id}
                          recommendation={review}
                          isEditMode={false}
                          onVisibilityChange={() => console.info('click')}
                        />
                      ) : (
                        <Recommendation
                          {...review}
                          isEditMode={false}
                          onVisibilityChange={() => console.info('click')}
                        />
                      )}
                    </div>
                  ))}
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      onClick={() => {
                        setReviewsModalOpen(false);
                        handleReviewsChange();
                      }}
                      width={'auto'}
                      size={'small'}
                      squared
                    >
                      Confirm selection
                    </Button>
                  </div>
                </Modal>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
