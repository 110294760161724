import { observable, action } from 'mobx';
import { Stores } from '@src/stores/index';
import { UserCV } from './Profile/Profile';

export interface UIStoreData {
  notificationSlider?: boolean;
}

export default class UIStore implements UIStoreData {
  @observable public notificationSlider: UIStoreData['notificationSlider'] =
    false;

  @observable public timesheetDescription = '';
  @observable public timesheetDescriptionHtml = '';
  @observable public timesheetVideoLink = '';
  @observable public originalTimesheetDescriptionHtml = '';
  @observable public gptUsageLogIdForTimesheetSummary: string | undefined =
    undefined;
  @observable public profileImageModalOpen = false;
  @observable public userCv: UserCV | undefined = undefined;

  public constructor(rootStore: Stores, initialState?: UIStoreData) {
    if (initialState) {
      this.notificationSlider = initialState.notificationSlider || false;
    }
  }

  @action setTimesheetDescription = (description: string) => {
    this.timesheetDescription = description;
  };

  @action setTimesheetDescriptionHtml = (descriptionHtml: string) => {
    this.timesheetDescriptionHtml = descriptionHtml;
  };

  @action setTimesheetVideoLink = (videoLink: string) => {
    this.timesheetVideoLink = videoLink;
  };

  @action setGptUsageLogIdForTimesheetSummary = (id?: string) => {
    this.gptUsageLogIdForTimesheetSummary = id;
  };

  @action toggleNotificationSlider = () => {
    this.notificationSlider = !this.notificationSlider;
  };

  @action setOriginalTimesheetDescriptionHtml = (descriptionHtml: string) => {
    this.originalTimesheetDescriptionHtml = descriptionHtml;
  };

  @action toggleProfileImageModal = (value?: boolean) => {
    this.profileImageModalOpen =
      value !== undefined ? value : !this.profileImageModalOpen;
  };

  @action setUserCv = (cv: UserCV) => {
    this.userCv = cv;
    this.userCv.cvUploadedAt = new Date();
  };

  public serialize(): UIStoreData {
    return {
      notificationSlider: this.notificationSlider,
    };
  }
}
