import { Colors, Icon, IconType } from '@ateams/components';
import { Icon as UiIcon } from '@a_team/ui-components';
import React, { useEffect, useMemo, useState } from 'react';

import FileDropArea from '@src/components/FileDropArea';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { useResumeUpload } from '@src/hooks/useResumeUpload';
import Profile, { UserCV } from '@src/stores/Profile/Profile';
import cx from 'classnames';
import { isNil } from 'lodash';
import { createUseStyles } from 'react-jss';
import { SidebarSection } from '../SidebarSection';
import plus from './plus.svg';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import { useGetLastResumeParsed } from '@src/rq/users';
import { format } from 'date-fns';
import resumeIcon from './resume-icon.svg';
import { FileWithPath } from 'react-dropzone';
import customLoadingIcon from './custom-loading-icon.svg';

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginTop: 16,
  },
  wrapperContainer: {
    marginBottom: 40,
  },
  textButton: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '15px',
    fontWeight: 400,
    height: '24px',
    color: '#7000E3',
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.9,
    },
    '& + div': {
      width: 'auto',
    },
  },
  removeIcon: {
    padding: 0,
  },
  uploader: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  plus: {
    width: 32,
    height: 32,
    borderRadius: 100,
    overflow: 'hidden',
    backgroundImage: `url(${plus})`,
    color: 'white',
    fontSize: 18,
    textAlign: 'center',
    lineHeight: '28px',
  },
  container: {
    marginBottom: 0,
    position: 'relative',
    background: 'white',
  },
  containerError: {
    border: '1px solid #F69DA5',
    borderRadius: 8,
    padding: '16px',
  },
  showSuccessMessage: {
    border: '1px solid #DADADC',
    borderRadius: 8,
    padding: '16px',
  },
  description: {
    fontSize: '12px',
    color: '#62646A',
  },
  prompt: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 500,
    color: '#222',

    '& span': {
      color: '#62646A',
      fontSize: '14px',
      fontWeight: 400,
    },
  },
  confirmationButton: {
    fontSize: '14px',
    fontWeight: 400,
    height: '24px',
    color: '#7000E3',
    background: 'none',
    border: 'none',
    padding: '0',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.9,
    },
  },
  separator: {
    color: '#A54CFF',
  },
  confirmationButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  successMessage: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    marginTop: '-12px',
    backgroundColor: '#F6FCF3',
    padding: '28px 16px 16px 16px',
    borderRadius: '8px',
    fontSize: '14px',
    color: '#559C2B',
  },
  resumeIcon: {
    width: '16px',
    height: '16px',
    marginRight: '8px',
  },
  uploadingContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '15px',
    fontWeight: 400,
    height: '24px',
    color: '#62646A',
  },
  uploadingText: {
    marginLeft: '8px',
  },
  spinningIcon: {
    animation: '$spin 1.5s linear infinite',
  },
  '@keyframes spin': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  infoMessage: {
    background: '#FCFAFF',
    fontSize: '12px',
    color: Colors.secondaryDark,
    padding: '28px 16px 16px 16px',
    borderRadius: '8px',
    marginTop: '-12px',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '8px',
  },
});

interface ResumeUploaderProps {
  canView: boolean;
  canUpload: boolean;
  profile: Profile;
  onRemove: () => void;
  onUpload: (cvUrl: string) => void;
}

const THREE_MONTHS_IN_MS = 90 * 24 * 60 * 60 * 1000;

function ResumeUploader(props: ResumeUploaderProps) {
  const styles = useStyles();
  const { uiStore } = useStores();
  const { data: lastResumeParsed } = useGetLastResumeParsed();
  const { profile } = props;
  const { userCV } = profile;
  const [isUpToDateConfirmation, setIsUpToDateConfirmation] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const lastUpdated =
    profile.userCV.cvUploadedAt ?? lastResumeParsed?.updatedAt;

  const isResumeOlderThanThreeMonths = lastUpdated
    ? new Date().getTime() - new Date(lastUpdated).getTime() >
      THREE_MONTHS_IN_MS
    : false;

  const cv = uiStore.userCv;

  useEffect(() => {
    uiStore.setUserCv(userCV);
  }, [userCV]);

  const { uploading, handleFileSelected, handleUpload } = useResumeUpload({
    onUpload: (downloadUrl) => {
      props.onUpload(downloadUrl);

      const newCv: UserCV = {
        ...cv,
        label: cv?.label || '',
        downloadUrl,
      };

      uiStore.setUserCv(newCv);
    },
  });

  const readOnly = useMemo(() => {
    return props.canView && !props.canUpload;
  }, [props.canUpload, props.canView]);

  const handleRemove = () => {
    uiStore.setUserCv({
      ...cv,
      label: cv?.label || '',
      downloadUrl: undefined,
    });
    props.onRemove();
  };

  if (readOnly && !cv?.downloadUrl) {
    return null;
  }

  if (!props.canUpload && !readOnly) {
    return null;
  }

  const lastUpdatedFormatted = lastUpdated
    ? format(new Date(lastUpdated), 'MMM d, yyyy')
    : undefined;

  const onUpdateUploadedAt = () => {
    if (profile.userCV.downloadUrl) {
      handleUpload(profile.userCV.downloadUrl);
      setIsUpToDateConfirmation(true);
      setShowSuccessMessage(true);
    }
  };

  const mostRecentExperience = lastResumeParsed?.mostRecentExperience;

  const handleResumeUpload = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/pdf';

    input.onchange = async (e) => {
      const target = e.target as HTMLInputElement;
      if (target.files?.length) {
        const files = Array.from(target.files) as FileWithPath[];
        await handleFileSelected(files, []);
      }
    };

    input.click();
  };

  return (
    <div className={styles.wrapperContainer}>
      <SidebarSection
        className={cx(styles.container, {
          [styles.containerError]:
            isResumeOlderThanThreeMonths && !showSuccessMessage,
          [styles.showSuccessMessage]: showSuccessMessage && cv?.downloadUrl,
        })}
        title={'Resume'}
        readonly
      >
        {lastUpdated && cv?.downloadUrl && (
          <div className={styles.description}>
            Last uploaded: {lastUpdatedFormatted}
          </div>
        )}
        <div className={styles.wrapper}>
          {cv?.downloadUrl && !uploading && (
            <a
              href={cv.downloadUrl}
              target="_blank"
              className={styles.textButton}
              rel="noopener noreferrer"
            >
              <img
                className={styles.resumeIcon}
                src={resumeIcon}
                alt="Resume"
              />
              {cv.label}
            </a>
          )}
          {uploading && (
            <div className={styles.uploadingContainer}>
              <img
                className={cx(styles.resumeIcon, styles.spinningIcon)}
                src={customLoadingIcon}
                alt="Resume"
              />
              <span className={styles.uploadingText}>Uploading...</span>
            </div>
          )}
          {cv?.downloadUrl && !readOnly && (
            <Icon
              className={styles.removeIcon}
              type={IconType.Close}
              size={'xsmall'}
              onClick={handleRemove}
            />
          )}
          {!cv?.downloadUrl && !readOnly && !uploading && (
            <>
              <FileDropArea
                disabled={!isNil(uploading)}
                accept={['application/pdf']}
                onFileDrop={handleFileSelected}
              >
                {({ openDialog }) => (
                  <div
                    className={cx(styles.uploader, {
                      isLoading: !isNil(uploading),
                    })}
                    onClick={(e) => {
                      e.stopPropagation();
                      openDialog();
                    }}
                  >
                    <div className={styles.plus}>
                      <span>+</span>
                    </div>
                    <div className={styles.prompt}>
                      Upload your Resume
                      <span>PDF file format</span>
                    </div>
                  </div>
                )}
              </FileDropArea>
            </>
          )}
          <LoadingIndicator
            successMsg={'Done'}
            loadingMsg={'Uploading Resume'}
            loading={uploading}
          />
        </div>
        {isResumeOlderThanThreeMonths && !showSuccessMessage && (
          <>
            <div>Is your resume up to date?</div>
            <div className={styles.confirmationButtonsContainer}>
              <button
                className={styles.confirmationButton}
                onClick={onUpdateUploadedAt}
              >
                Yes, it's up to date
              </button>
              <span className={styles.separator}>|</span>
              <button
                className={styles.confirmationButton}
                onClick={handleResumeUpload}
              >
                No, upload new version
              </button>
            </div>
          </>
        )}
      </SidebarSection>

      {showSuccessMessage && cv?.downloadUrl ? (
        <div className={styles.successMessage}>
          <UiIcon color="Green@800" name="statusPositiveNoBorder" size="sm" />
          {isUpToDateConfirmation
            ? 'Your resume is up to date.'
            : 'Your resume has been uploaded successfully.'}
        </div>
      ) : mostRecentExperience && isResumeOlderThanThreeMonths ? (
        <div className={styles.infoMessage}>
          <Icon size="smaller" type={IconType.SuggestedTeams} />
          The most recent experience on your resume: {mostRecentExperience}
        </div>
      ) : null}
    </div>
  );
}

export default observer(ResumeUploader);
