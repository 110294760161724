import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Icon, Toggle } from '@a_team/ui-components';
import { Breakpoints } from '@ateams/components';
import { LinkedInReview } from '@ateams/api/dist/endpoints/UserReviews';
import DeleteLinkedinRecommendationModal from '@src/components/Modal/DeleteLinkedinRecommendationModal';

interface LinkedInRecommendationProps {
  id: string;
  recommendation: LinkedInReview;
  isEditMode?: boolean;
  onVisibilityChange: (visible: boolean) => void;
  onDelete?: () => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    gap: '16px',
  },

  logoContainer: {
    marginRight: '12px',
  },
  logo: {
    width: '40px',
    height: '40px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#5CBE73',
    color: '#FFFFFF',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  logoImage: {
    width: '40px',
    height: '40px',
    borderRadius: '8px',
    objectFit: 'cover',
  },
  infoContainer: {
    flex: 1,
    marginLeft: '0',
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4px',
  },
  linkedInLogo: {
    marginRight: '8px',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  recommendationTitle: {
    fontSize: '16px',
    fontWeight: '500',
  },
  metaContainer: {
    fontSize: '14px',
    color: '#6E7177',
    marginBottom: '4px',
  },
  recommenderTitle: {
    display: 'block', // Block for mobile
    marginTop: '4px',
  },
  publishedDate: {
    display: 'inline-block',
  },
  dotSeparator: {
    display: 'none', // Hidden on mobile
  },
  recommendationText: {
    fontSize: '16px',
    lineHeight: '1.5',
    color: '#35373A',
    marginTop: '16px',
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '24px',
  },
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  toggleLabel: {
    fontSize: '14px',
    color: '#35373A',
  },
  deleteButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#FF5630',
    fontSize: '14px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    padding: '0',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  footerSeparator: {
    height: '24px',
    width: '1px',
    backgroundColor: '#E0E0E0',
    margin: '0 16px',
  },
  footerActions: {
    display: 'flex',
    alignItems: 'center',
  },

  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      padding: '24px',
      flexDirection: 'row',
    },
    metaContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    recommenderTitle: {
      display: 'inline', // Inline for desktop
      marginTop: 0,
    },
    dotSeparator: {
      display: 'inline',
      margin: '0 8px',
    },
  },
});

const LinkedInRecommendation = ({
  recommendation,
  isEditMode = false,
  onVisibilityChange,
  onDelete,
}: LinkedInRecommendationProps): JSX.Element => {
  const styles = useStyles();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleToggle = (isChecked: boolean) => {
    onVisibilityChange(isChecked);
  };

  const formatDate = (date: Date): string => {
    return new Date(date).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const handleDelete = () => {
    onDelete?.();
    setIsDeleteModalOpen(false);
  };

  const recommenderName = `${
    recommendation.recommender.firstName
  } ${recommendation.recommender.lastName.charAt(0)}.`;
  const recommenderTitle =
    recommendation.recommender.position?.title &&
    recommendation.recommender.position?.companyName
      ? `${recommendation.recommender.position.title} at ${recommendation.recommender.position.companyName}`
      : recommendation.recommender.occupation;
  const publishedDate = formatDate(recommendation.createdAt);
  const recommendationText = recommendation.text;

  const companyLogo = recommendation.recommender.position?.companyLogo;
  const visible = recommendation.visibility.visible;

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        {companyLogo ? (
          <img
            src={companyLogo}
            alt={`${
              recommendation.recommender.position?.companyName || 'LinkedIn'
            } logo`}
            className={styles.logoImage}
          />
        ) : (
          <div className={styles.logo}>in</div>
        )}
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.titleRow}>
          <span className={styles.linkedInLogo}>in</span>
          <span className={styles.recommendationTitle}>
            LinkedIn recommendation from {recommenderName}
          </span>
        </div>
        <div className={styles.metaContainer}>
          <span className={styles.publishedDate}>
            Published on {publishedDate}
          </span>
          <span className={styles.dotSeparator}> · </span>
          {recommenderTitle && (
            <span className={styles.recommenderTitle}>{recommenderTitle}</span>
          )}
        </div>
        <div className={styles.recommendationText}>{recommendationText}</div>
        {isEditMode && (
          <div className={styles.footer}>
            <div className={styles.footerActions}>
              <div className={styles.toggleContainer}>
                <Toggle checked={!!visible} onChange={handleToggle} />
                <span className={styles.toggleLabel}>
                  {visible ? 'Visible on profile' : 'Hidden from profile'}
                </span>
              </div>
              {onDelete && (
                <>
                  <div className={styles.footerSeparator} />
                  <button
                    className={styles.deleteButton}
                    onClick={() => setIsDeleteModalOpen(true)}
                  >
                    <Icon name="delete" size="md" color="Red@600" />
                    <span>Delete</span>
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {onDelete && (
        <DeleteLinkedinRecommendationModal
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default LinkedInRecommendation;
