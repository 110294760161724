import { MissionId } from '@a_team/models/dist/MissionObject';
import {
  CreateRolePaymentTermRequest,
  MissionRoleId,
  PendingPaymentTerm,
} from '@a_team/models/dist/MissionRole';
import { apiAdmin, apiMissions, apiProposals, apiUser } from '@ateams/api';
import { Stores, useStores } from '@src/stores';
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { queryKeys } from './keys';
import { UserId, UserSuggestedTeamUp } from '@a_team/models/dist/UserObject';
import { QueryNextToken } from '@a_team/models/dist/misc';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import {
  CustomQuestionRecommendationRequest,
  MissionApplicationPitchRequest,
  MissionsMatchesOnData,
} from '@a_team/models/dist/MissionApplicationObject';
import AuthStore from '@src/stores/Auth';

const MAX_RECOMMENDED_MISSIONS = 10;

const queryNarrativeTeammateSuggestions = async (
  next: string | undefined,
  stores: Stores,
  missionId: MissionId,
  rid: MissionRoleId,
  searchQuery: string,
) => {
  const { auth, error: errorStore } = stores;
  try {
    const missionsResponse = await apiMissions.getNarrativeTeammateSuggestions(
      auth,
      missionId,
      rid,
      searchQuery,
      next,
    );

    return {
      teammateSuggestions: missionsResponse.items,
      next: missionsResponse.next || undefined,
    };
  } catch (error) {
    errorStore.handleError(error as Error);

    throw error;
  }
};

const queryMissionApplicationAnalyses = async (
  next: string | undefined,
  stores: Stores,
  applicationId: string,
) => {
  const { auth, error: errorStore } = stores;

  try {
    const analyses = await apiMissions.missionApplicationAnalyses(
      auth,
      applicationId,
      next,
    );

    return {
      analyses: analyses.items,
      next: analyses.next || undefined,
    };
  } catch (error) {
    errorStore.handleError(error as Error);

    throw error;
  }
};

export const useNarrativeTeammateSuggestions = ({
  missionId,
  rid,
  searchQuery,
  onSuccess,
}: {
  missionId: MissionId;
  rid: MissionRoleId;
  searchQuery: string;
  onSuccess?: (
    data: InfiniteData<{
      teammateSuggestions: UserSuggestedTeamUp[];
      next: string | undefined;
    }>,
  ) => void;
}) => {
  const stores = useStores();
  return useInfiniteQuery({
    queryKey: queryKeys.missions.narrativeTeammateSuggestions(
      missionId,
      rid,
      searchQuery,
    ),
    queryFn: ({ pageParam = undefined }: { pageParam?: QueryNextToken }) =>
      queryNarrativeTeammateSuggestions(
        pageParam,
        stores,
        missionId,
        rid,
        searchQuery,
      ),
    getNextPageParam: (lastPage) => lastPage.next,
    onSuccess,
  });
};

export const useGetMissionApplicationAnalyses = (applicationId: string) => {
  const stores = useStores();

  return useInfiniteQuery({
    queryKey: queryKeys.missions.missionApplicationAnalysis(
      applicationId || '',
    ),
    queryFn: ({ pageParam = undefined }: { pageParam?: QueryNextToken }) =>
      queryMissionApplicationAnalyses(pageParam, stores, applicationId),
    getNextPageParam: (lastPage) => lastPage.next,
    keepPreviousData: true,
  });
};

export const useAnalyzeMissionApplication = () => {
  const stores = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (applicationId: string) => {
      return apiMissions.analyzeMissionApplication(stores.auth, applicationId);
    },
    onSuccess: (_, applicationId: string) => {
      queryClient.invalidateQueries(
        queryKeys.missions.missionApplicationAnalysis(applicationId),
      );
    },
  });
};

export const useGenerateMissionApplicationPitch = () => {
  const { auth } = useStores();
  const analytics = useAnalytics();

  return useMutation({
    mutationFn: async ({
      mid,
      rid,
      data,
    }: {
      mid: string;
      rid: string;
      data: MissionApplicationPitchRequest;
      rewrite?: boolean;
    }) => {
      return apiMissions.generatePitchSuggestion(auth, mid, rid, data);
    },
    onSuccess: (_, { rewrite }) => {
      if (auth.uid) {
        rewrite
          ? analytics.trackPitchRewriteClicked(auth.uid)
          : analytics.trackPitchStartWritingClicked(auth.uid);
      }
    },
  });
};

export const useGenerateCustomQuestionRecommendation = () => {
  const { auth } = useStores();
  const analytics = useAnalytics();

  return useMutation({
    mutationFn: async ({
      mid,
      rid,
      data,
    }: {
      mid: string;
      rid: string;
      data: CustomQuestionRecommendationRequest;
    }) => {
      return apiMissions.generateQuestionRecommendation(auth, mid, rid, data);
    },
    onSuccess: (_, { mid, rid, data }) => {
      if (auth.uid) {
        analytics.trackQuestionRecommendationGenerated(
          auth.uid,
          mid,
          rid,
          data.questionId,
        );
      }
    },
  });
};

export const useGetRecommendedMissionsQuery = () => {
  const { auth, missionControl } = useStores();

  const isQueryEnabled = () => {
    if (auth.limitedAccess) return false;
    if (auth.withOnboardingV2 === undefined) return false;
    if (auth.withOnboardingV2 === true && !auth.onboardingCompleted)
      return false;
    return true;
  };

  return useQuery({
    queryKey: queryKeys.missions.recommendedMissions(auth.uid ?? ''),
    enabled: isQueryEnabled(),
    queryFn: () => {
      missionControl.setLoadingRecommended(true);
      return apiMissions.getRecommendedMissions(auth, MAX_RECOMMENDED_MISSIONS);
    },
    onSuccess: (data) => {
      missionControl.setRecommendedMissions(data);
      missionControl.setLoadingRecommended(false);
    },
    onError: () => {
      missionControl.setLoadingRecommended(false);
    },
  });
};

export const useGetAllMissionsQuery = () => {
  const { auth, missionControl } = useStores();

  return useQuery({
    queryKey: queryKeys.missions.allMissions(auth.uid ?? ''),
    queryFn: () => {
      missionControl.setLoading(true);
      return apiMissions.queryMissionsCards(auth, {
        filterByOpenRoles: true,
        filterByRoleCategories: [],
      });
    },
    onSuccess: (data) => {
      missionControl.setAllMissions(data);
      missionControl.setLoading(false);
    },
    onError: (err) => {
      missionControl.setError('Failed to load all missions', err as Error);
      missionControl.setLoading(false);
    },
  });
};

export const useGetAppliedMissionsQuery = () => {
  const { auth, missionControl } = useStores();

  return useQuery({
    queryKey: queryKeys.missions.appliedMissions(auth.uid ?? ''),
    queryFn: () => {
      missionControl.setLoading(true);
      return apiUser.getAppliedMissions(
        auth,
        missionControl.appliedMissionsFilters,
      );
    },
    onSuccess: (data) => {
      missionControl.updateAppliedMissions(data, false);
      missionControl.setLoading(false);
    },
    onError: (err) => {
      missionControl.setError(
        'Failed to load applied to missions',
        err as Error,
      );
      missionControl.setLoading(false);
    },
  });
};

export const useGetAppliedMissionsQueryV2 = () => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.missions.appliedMissionsV2(auth.uid ?? ''),
    queryFn: () => {
      return apiUser.getAppliedMissionsV2(auth);
    },
  });
};

export const useGetCompletedMissionsQueryV2 = () => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.missions.completedMissionsV2(auth.uid ?? ''),
    queryFn: () => {
      return apiUser.getCompletedMissionsV2(auth);
    },
  });
};

export const useGetNotInterestedMissionsQueryV2 = () => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.missions.notInterestedMissionsV2(auth.uid ?? ''),
    queryFn: () => {
      return apiUser.getNotInterestedMissionsV2(auth);
    },
  });
};

export const useGetCompletedMissionsQuery = () => {
  const { auth, missionControl } = useStores();

  return useQuery({
    queryKey: queryKeys.missions.completedMissions(auth.uid ?? ''),
    queryFn: () => {
      missionControl.setLoading(true);
      return apiUser.getCompletedMissions(auth);
    },
    onSuccess: (data) => {
      missionControl.setCompletedMissions(data);
      missionControl.setLoading(false);
    },
    onError: (err) => {
      missionControl.setError(
        'Failed to load completed missions',
        err as Error,
      );
      missionControl.setLoading(false);
    },
  });
};

export const useGetNotInterestedMissionsQuery = () => {
  const { auth, missionControl } = useStores();

  return useQuery({
    queryKey: queryKeys.missions.notInterestedMissions(auth.uid ?? ''),
    queryFn: () => {
      missionControl.setLoading(true);
      return apiUser.getNotInterestedMissions(auth);
    },
    onSuccess: (data) => {
      missionControl.updateNotInterestedMissionsWithMoreResults(data, false);
      missionControl.setLoading(false);
    },
    onError: (err) => {
      missionControl.setError(
        'Failed to load not interested missions',
        err as Error,
      );
      missionControl.setLoading(false);
    },
  });
};

const fetchMissionMatchesOnData =
  (auth: AuthStore, uid: UserId, mids: MissionId[]) =>
  async (): Promise<MissionsMatchesOnData[]> => {
    return mids.length > 0
      ? apiMissions.getMissionsMatchesOnData(auth, uid, mids)
      : [];
  };

export const useGetMissionMatchesOnData = (
  auth: AuthStore,
  uid: UserId,
  mids: MissionId[] = [],
) => {
  return useQuery(
    queryKeys.missions.missionsMatchesOnData(mids),
    fetchMissionMatchesOnData(auth, uid, mids),
  );
};

export const useGetMissionDataForSourcedBuilder = ({
  enabled,
}: { enabled?: boolean } = {}) => {
  const { auth } = useStores();

  return useQuery({
    retry: false,
    enabled,
    queryKey: queryKeys.missions.missionDataForSourcedBuilder(auth.uid ?? ''),
    queryFn: () => {
      return apiMissions.getMissionDataForSourcedBuilder(auth);
    },
  });
};

export const useGetBuilderTypeAndMissionData = () => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.missions.builderTypeAndMissionData(auth.uid ?? ''),
    queryFn: () => apiMissions.getBuilderTypeAndMissionDataByUserId(auth),
    enabled: !!auth?.currentUser?.wasScrubbed,
  });
};

export const useAdminQueryBuilderActiveMissions = (builderId: string) => {
  const { auth } = useStores();

  return useQuery({
    enabled: !!builderId,
    queryKey: queryKeys.missions.adminQueryActiveMissionsByBuilderId(builderId),
    queryFn: () => apiAdmin.getBuilderActiveMissions(auth, builderId),
  });
};

export const useAdminMigrateTimesheetToNewMission = (timesheetId: string) => {
  const { auth } = useStores();

  return useMutation({
    mutationKey: queryKeys.missions.adminMigrateTimesheet(timesheetId),
    mutationFn: (newMissionId: string) =>
      apiAdmin.migrateTimesheet(auth, {
        timesheetId,
        newMissionId,
      }),
  });
};

export const useGetOpenMissionsV2 = () => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.missions.openMissionsV2(auth.uid ?? ''),
    queryFn: () => apiMissions.getOpenMissionsV2(auth),
  });
};

export const useAdminCreatePaymentTerm = (
  onSuccess: (newPaymentTerm: PendingPaymentTerm) => void,
) => {
  const { auth } = useStores();

  return useMutation({
    mutationKey: queryKeys.missions.adminCreateRolePayment(auth.uid ?? ''),
    mutationFn: (request: CreateRolePaymentTermRequest) =>
      apiAdmin.createRolePaymentTerm(auth, request),
    onSuccess,
  });
};

export const useAdminGetBuilderApprovedProposal = (
  builderId?: string,
  missionId?: string,
) => {
  const { auth } = useStores();

  const enabled = !!builderId && !!missionId && auth.withNewRatesOnApplication;

  return useQuery({
    enabled,
    queryKey: queryKeys.missions.adminBuilderApprovedProposal(
      auth.uid ?? '',
      builderId ?? '',
      missionId ?? '',
    ),
    queryFn: () =>
      apiProposals.getApprovedBuilderProposalForMission(
        auth,
        builderId ?? '',
        missionId ?? '',
      ),
  });
};

export const useUpdateNotInterestedMissionMutation = () => {
  const { auth } = useStores();

  return useMutation({
    mutationFn: ({
      missionId,
      reason,
    }: {
      missionId: string;
      reason: string;
    }) =>
      apiUser.updateCurrentUserNotInterestedMissions(auth, {
        missionId,
        reason,
      }),
  });
};
