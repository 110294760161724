import React from 'react';
import { Button } from '@a_team/ui-components';
import MainLayout from '@src/layouts/Main';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import { UPLOADCARE_ASSETS_BASE_URL } from '@src/config';
import { Breakpoints } from '@ateams/components';
import { useUpdateUserPreference } from '@src/rq/users';
import { PreferenceKey } from '@a_team/models/dist/UserPreferences';

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url("${UPLOADCARE_ASSETS_BASE_URL}/0f0d514d-0963-4f7b-ab97-49d03412d617/-/preview/922x2000")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    textAlign: 'center',
    overflow: 'auto', // In case content is taller than the viewport
    zIndex: 9999,
    backgroundColor: 'white',
  },
  content: {
    padding: '2rem',
  },
  welcome: {
    fontSize: 18,
    textAlign: 'center',
  },
  title: {
    fontSize: 38,
    fontWeight: 800,
    lineHeight: '120%',
    textAlign: 'center',
    color: '#5400AA',
    marginTop: 16,
  },
  subtitle: {
    fontSize: 38,
    fontWeight: 800,
    lineHeight: '120%',
    textAlign: 'center',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
  },
  button: {
    backgroundColor: '#FFFFFF !important',
    border: 'none !important',
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 8,
    padding: '14px 48px !important',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      backgroundImage: `url("${UPLOADCARE_ASSETS_BASE_URL}/530db995-e2d2-47de-90a1-e3d0710a35dc/-/preview/2000x1250")`,
    },
    title: {
      fontSize: 54,
    },
    subtitle: {
      fontSize: 54,
    },
  },
});

const Setup = () => {
  const styles = useStyles();

  const { mutate: updateUserPreference, isLoading } = useUpdateUserPreference();

  const handleUpdateUserHasAcceptedYouAreInPreference = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    updateUserPreference({
      key: PreferenceKey.HasAcceptedYouAreIn,
      value: true,
    });
  };

  return (
    <MainLayout title="Setup Page">
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.welcome}>Welcome to A.Team</div>
          <div className={styles.title}>You're in</div>
          <div className={styles.subtitle}>
            Let's find the perfect
            <br />
            mission for you
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              variant="ghost"
              onClick={handleUpdateUserHasAcceptedYouAreInPreference}
              disabled={isLoading}
            >
              {isLoading ? 'Getting started...' : 'Get started'}
            </Button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default observer(Setup);
