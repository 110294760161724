import { MissionId } from '@a_team/models/dist/MissionObject';
import { MissionRoleId } from '@a_team/models/dist/MissionRole';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const missionKeys = createQueryKeys('missions', {
  narrativeTeammateSuggestions: (
    missionId: MissionId,
    rid: MissionRoleId,
    querySearch: string,
  ) => ({
    missionId,
    rid,
    querySearch,
  }),
  missionApplicationAnalysis: (applicationId: string) => ({
    applicationId,
  }),
  missionApplicationMissingRequirements: (applicationId?: string) => ({
    applicationId,
  }),
  customQuesitonRecommendation: (qid: string) => ({
    qid,
  }),
  allMissions: (uid: string) => ({
    uid,
  }),
  appliedMissions: (uid: string) => ({
    uid,
  }),
  completedMissions: (uid: string) => ({
    uid,
  }),
  notInterestedMissions: (uid: string) => ({
    uid,
  }),
  recommendedMissions: (uid: string) => ({
    uid,
  }),
  missionsMatchesOnData: (mids: MissionId[]) => ({
    mids,
  }),
  missionDataForSourcedBuilder: (uid: string) => ({
    uid,
  }),
  builderTypeAndMissionData: (uid: string) => ({
    uid,
  }),
  appliedMissionsV2: (uid: string) => ({
    uid,
  }),
  completedMissionsV2: (uid: string) => ({
    uid,
  }),
  notInterestedMissionsV2: (uid: string) => ({
    uid,
  }),
  adminQueryActiveMissionsByBuilderId: (builderId: string) => ({ builderId }),
  adminMigrateTimesheet: (timesheetId: string) => ({ timesheetId }),
  openMissionsV2: (uid: string) => ({
    uid,
  }),
  adminCreateRolePayment: (adminId: string) => ({ adminId }),
  adminBuilderApprovedProposal: (
    adminId: string,
    builderId: string,
    missionId: string,
  ) => ({ adminId, missionId, builderId }),
});
