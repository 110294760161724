import React, { ReactElement, useState } from 'react';
import { createUseStyles } from 'react-jss';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import {
  Button as CallToActionButton,
  TabItem,
  Tabs,
  Card,
} from '@ateams/components';
import { AdminNoteCategory } from '@a_team/models/dist/AdminNotesObject';

interface Props {
  tabs: TabItem[];
  onSave: (text: string, category: AdminNoteCategory) => Promise<void>;
}

const useStyles = createUseStyles({
  noteCard: {
    display: 'flex',
    flexDirection: 'column',
  },
  cta: {
    alignSelf: 'flex-end',
    display: 'flex',
  },
  tabsContainer: {
    marginBottom: 20,
  },
});

export const AddNoteCard = (props: Props): ReactElement => {
  const styles = useStyles();
  const [text, setText] = useState<string>('');
  const [activeTab, setActiveTab] = useState<TabItem>(props.tabs[0]);

  const saveNote = () => {
    text &&
      props.onSave(text, activeTab.id as AdminNoteCategory).then(() => {
        setText('');
      });
  };

  return (
    <Card className={styles.noteCard}>
      <p>Note type:</p>
      <div className={styles.tabsContainer}>
        <Tabs
          items={props.tabs.filter(
            (tab) =>
              tab.id !== AdminNoteCategory.ProposalBlurb &&
              tab.id !== AdminNoteCategory.InterviewFeedback,
          )}
          onClickOnItem={(item) => setActiveTab(item)}
          selectedItem={activeTab}
        ></Tabs>
      </div>
      <OutlinedInput
        type="text"
        value={text}
        multiline
        placeholder="Additional notes..."
        onChange={(e): void => setText(e.target.value)}
        margin="none"
      />
      <div className={styles.cta} style={{ marginTop: 16 }}>
        <CallToActionButton
          onClick={saveNote}
          disabled={!text}
          size="small"
          width="auto"
        >
          Add Note
        </CallToActionButton>
      </div>
    </Card>
  );
};
