import MissionRole, {
  MissionAdminRole,
  MissionRoleStatus,
} from '@a_team/models/dist/MissionRole';
import Section from '@src/views/Profile/Main/partials/Section';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
  BorderColors,
  BorderRadius,
  Colors,
  FontWeights,
  ToggleSwitch,
} from '@ateams/components';
import cx from 'classnames';
import { useStores } from '@src/stores';
import { AVG_WEEKS_PER_MONTH, HOURS_PER_WEEK } from '../TeamWorkRoleInput';
import { round } from 'lodash';
import { MissionAdminObject } from '@a_team/models/dist/MissionObject';
import RateInputField from './RateInputField';
import { BuilderRow } from './BuilderRow';
import { toBuilderRate, toClientRate } from './utils';
import { PaymentTerm } from './PaymentTerm';
import { useAdminGetBuilderApprovedProposal } from '@src/rq/missions';
import { BasicUserObject } from '@a_team/models/dist/UserObject';
import { HourlyRateEquivalent } from './HourlyRateEquivalent';

const useStyles = createUseStyles({
  ratesWrapper: {
    border: `1px solid ${BorderColors.lighter}`,
    borderTop: 0,
    padding: 24,

    '&.withTopBorder': {
      border: `1px solid ${BorderColors.lighter}`,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
  },
  ratesRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    alignItems: 'center',
    marginBottom: 24,
  },
  budgetWrapper: {
    border: `1px solid ${BorderColors.lighter}`,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderTop: 0,
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
    padding: 24,
  },
  budgetColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  budgetLabel: {
    fontSize: 15,
    fontWeight: FontWeights.semiBold,
  },
  budgetInputs: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  clientBudget: {
    backgroundColor: Colors.backgroundLight,
    padding: '10px 12px',
    borderRadius: BorderRadius.medium,
    width: '100%',
    marginBottom: 8,
  },
  budgetControl: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
  },
});

interface RatesV2Props {
  role?: MissionRole;
  disabled?: boolean;
  onPaymentTermChange(): void;
  onChange(role: MissionRole): void;
}

export const RatesV2 = (props: RatesV2Props) => {
  const styles = useStyles();
  const { role, disabled = false, onChange, onPaymentTermChange } = props;
  const { auth, missions } = useStores();

  /**
   * for backwards compatibility, we need to determine the state using either new budget settings
   * if present, else revert to legacy budget settings.
   */
  const [data, setData] = useState<Partial<MissionAdminRole>>({
    ...role,
    budgetSettings: {
      showHourlyBudget: role?.budgetSettings
        ? role.budgetSettings.showHourlyBudget
        : role?.showRateRangeToBuilders ?? false,
      showMonthlyBudget: role?.budgetSettings
        ? role.budgetSettings.showMonthlyBudget
        : (!!role?.collectMonthlyRate && !!role?.showRateRangeToBuilders) ??
          false,
      requireHourlyRate: role?.budgetSettings?.requireHourlyRate ?? true,
      requireMonthlyRate: role?.budgetSettings
        ? role.budgetSettings.requireMonthlyRate
        : role?.collectMonthlyRate ?? false,
    },
  });
  const { pendingPaymentTerm } = data;

  const currentMission = missions.currentMission?.data as MissionAdminObject;
  const clientMargin = currentMission?.clientMargin;
  const hasClientMargin = typeof clientMargin === 'number' && clientMargin > 0;
  const builder = role?.user as BasicUserObject;
  const { data: builderProposal, isLoading } =
    useAdminGetBuilderApprovedProposal(builder?.uid, currentMission?.mid);

  const builderRate = useMemo(() => {
    let rate = data.hourlyRate;
    if (data.isFullTimeRetainer) {
      rate = data.monthlyRate;
    }

    return rate || '';
  }, [data.isFullTimeRetainer, data.hourlyRate, data.monthlyRate]);

  const hourlyRateEquivalent = useMemo(() => {
    if (!data.monthlyRate || data.monthlyRate === 0) {
      return 0;
    }

    return data.monthlyRate / AVG_WEEKS_PER_MONTH / HOURS_PER_WEEK;
  }, [data?.monthlyRate]);

  const validRateRange = useMemo(() => {
    let { builderRateMin, builderRateMax } = data;
    builderRateMin = Number(builderRateMin);
    builderRateMax = Number(builderRateMax);

    return (
      !isNaN(builderRateMin) &&
      builderRateMin >= 1 &&
      !isNaN(builderRateMax) &&
      builderRateMax >= builderRateMin
    );
  }, [data.builderRateMin, data.builderRateMax]);

  const isRolePaymentTermSwitchingDisabled = useMemo(() => {
    if (data.status === MissionRoleStatus.ScheduledToSwitch) {
      return true;
    }

    return (
      (!auth.withRolePaymentTermSwitching &&
        data.status === MissionRoleStatus.Active) ||
      data.status === MissionRoleStatus.ScheduledToEnd ||
      (data.isFullTimeRetainer ? false : !validRateRange)
    );
  }, [data.status, validRateRange, auth.withRolePaymentTermSwitching]);

  const hasRoleMarkupError =
    typeof data.margin === 'number' && (data.margin < 0 || data.margin > 1);

  const clientRate = useMemo(() => {
    const { hourlyRate, margin, isFullTimeRetainer, monthlyRate } = data;
    const builderRate = isFullTimeRetainer ? monthlyRate : hourlyRate;

    if (!builderRate || isNaN(builderRate)) {
      return 0;
    }

    let roleMargin = Number(margin);

    if (!roleMargin || isNaN(roleMargin)) {
      roleMargin = currentMission.rolesMargin ?? 0;
    }

    return round(builderRate * (1 + roleMargin), 2);
  }, [
    data.hourlyRate,
    data.margin,
    data.isFullTimeRetainer,
    data.monthlyRate,
    currentMission.rolesMargin,
  ]);

  useEffect(() => {
    if (!data.isFullTimeRetainer) {
      setData({
        ...data,
        budgetSettings: {
          ...data.budgetSettings,
          requireHourlyRate: true,
        },
      });
    } else {
      setData({
        ...data,
        budgetSettings: {
          ...data.budgetSettings,
          requireMonthlyRate: true,
        },
      });
    }
  }, [data.isFullTimeRetainer]);

  const handleChange = (update: Partial<MissionAdminRole>): void => {
    const newRole = { ...data, ...update };
    /**
     * as soon as we have the updated budget visibility, we'll override the original
     * `showRateRangeToBuilders` property.
     */
    newRole.showRateRangeToBuilders = false;
    setData(newRole);

    if (newRole.category) {
      onChange(newRole as MissionAdminRole);
    }
  };

  const handlePaymentTermChange = (update: Partial<MissionAdminRole>): void => {
    if (data.status === MissionRoleStatus.Active) {
      onPaymentTermChange();
    } else {
      handleChange(update);
    }
  };

  const handleBuilderRateChange = (rate: number): void => {
    if (data.isFullTimeRetainer) {
      handleChange({
        monthlyRate: rate,
        hourlyRate: hourlyRateEquivalent, // for now we still charge by hourly rate, so we set this as a calculated hourly rate
      });
    } else {
      handleChange({
        hourlyRate: rate,
        monthlyRate: undefined, // incase the role doesn't have monthly retainer, remove the monthly rate
      });
    }
  };

  const handleRoleMarkupChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.valueAsNumber;
    const margin =
      typeof value === 'number' && !isNaN(value)
        ? Number((value / 100).toFixed(2))
        : null;

    const newRole = { ...data, margin };
    setData(newRole);
    onChange(newRole as MissionAdminRole);
  };

  const handleClientRateChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.valueAsNumber;
    const margin = data.margin;

    let builderRate = 0;

    if (margin && !isNaN(margin) && !isNaN(value)) {
      builderRate = round(value / (1 + margin), 2);
    }

    if (builderRate > 0) {
      handleBuilderRateChange(builderRate);
    }
  };

  return (
    <Section title="Rates">
      {builderProposal && !isLoading && (
        <BuilderRow
          avatarUrl={builder.profilePictureURL}
          fullName={builder.fullName}
          proposedAt={builderProposal.proposedAt}
          hourlyRate={toBuilderRate(
            builderProposal.hourlyRate ?? 0,
            (data.margin ?? 0) * 100,
            currentMission.rolesMargin,
          )}
          monthlyRate={toBuilderRate(
            builderProposal.monthlyRate ?? 0,
            (data.margin ?? 0) * 100,
            currentMission.rolesMargin,
          )}
        />
      )}
      <div
        className={cx(styles.ratesWrapper, {
          withTopBorder: isLoading || !builderProposal,
        })}
      >
        <PaymentTerm
          pendingPaymentTerm={pendingPaymentTerm}
          isFullTimeRetainer={data.isFullTimeRetainer}
          disabled={isRolePaymentTermSwitchingDisabled}
          onChange={(checked): void =>
            handlePaymentTermChange({
              isFullTimeRetainer: checked,
              collectMonthlyRate: checked,
            })
          }
        />
        <div className={styles.ratesRow}>
          <RateInputField
            prefix="$"
            suffix={data.isFullTimeRetainer ? '/m' : '/hr'}
            label="Builder rate"
            placeholder="xxx"
            value={builderRate}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleBuilderRateChange(Number(e.target.valueAsNumber))
            }
          />
          {data.isFullTimeRetainer && (
            <HourlyRateEquivalent monthlyRate={data.monthlyRate ?? 0} />
          )}
          <RateInputField
            suffix="%"
            label="Platform fee"
            placeholder="VAT"
            disabled={disabled}
            value={data.marginVAT ? data.marginVAT * 100 : ''}
            onChange={(e): void =>
              handleChange({
                marginVAT: Number(e.target.value) / 100 || undefined,
              })
            }
          />
          {auth.withCustomRoleMarkups && (
            <RateInputField
              suffix="%"
              label="Role markup"
              placeholder="xxx"
              disabled={hasClientMargin || disabled}
              className={cx({ error: hasRoleMarkupError })}
              defaultValue={
                typeof currentMission?.rolesMargin === 'number'
                  ? Number((currentMission?.rolesMargin * 100).toFixed(2))
                  : undefined
              }
              value={
                typeof data.margin === 'number'
                  ? Number((data.margin * 100).toFixed(2))
                  : undefined
              }
              type="number"
              onChange={handleRoleMarkupChange}
            />
          )}
        </div>
        <div className={styles.ratesRow}>
          <RateInputField
            label="Client rate"
            placeholder="xxx"
            prefix="$"
            value={clientRate}
            suffix={data.isFullTimeRetainer ? '/m' : '/hr'}
            onChange={handleClientRateChange}
          />
        </div>
      </div>
      <div className={styles.budgetWrapper}>
        <div className={styles.budgetColumn}>
          <h4 className={styles.budgetLabel}>Builder hourly budget</h4>
          <div className={styles.budgetInputs}>
            <RateInputField
              prefix="$"
              suffix="/hr"
              placeholder="Min..."
              value={data.builderRateMin ? data.builderRateMin : ''}
              onChange={(e): void =>
                handleChange({ builderRateMin: Number(e.target.value) })
              }
            />
            <span>-</span>
            <RateInputField
              prefix="$"
              suffix="/hr"
              placeholder="Max..."
              value={data.builderRateMax ? data.builderRateMax : ''}
              onChange={(e): void =>
                handleChange({ builderRateMax: Number(e.target.value) })
              }
            />
          </div>
          <div className={styles.clientBudget}>
            Company's budget is $
            {toClientRate(
              data.builderRateMin,
              (data.margin ?? 0) * 100,
              currentMission.rolesMargin,
            )}{' '}
            -{' '}
            {toClientRate(
              data.builderRateMax,
              (data.margin ?? 0) * 100,
              currentMission.rolesMargin,
            )}
          </div>
          <div className={styles.budgetControl}>
            <ToggleSwitch
              size={'small'}
              checkedBackgroundColor="secondary"
              onChange={(checked): void => {
                // it's required to show hourly rates if the role is not a full time retainer
                if (!data.isFullTimeRetainer && !checked) {
                  return;
                }
                handleChange({
                  budgetSettings: {
                    ...data.budgetSettings,
                    requireHourlyRate: checked,
                    ...(!!data.budgetSettings?.showHourlyBudget && !checked
                      ? { showHourlyBudget: false }
                      : undefined),
                  },
                });
              }}
              checked={!!data.budgetSettings?.requireHourlyRate}
              disabled={disabled}
            />
            <span>Require hourly rate on application</span>
          </div>
          <div className={styles.budgetControl}>
            <ToggleSwitch
              size={'small'}
              checkedBackgroundColor="secondary"
              onChange={(checked): void => {
                const showShowBudget =
                  checked && !!data.budgetSettings?.requireHourlyRate;
                handleChange({
                  budgetSettings: {
                    ...data.budgetSettings,
                    showHourlyBudget: showShowBudget,
                  },
                });
              }}
              checked={!!data.budgetSettings?.showHourlyBudget}
              disabled={disabled || !data.budgetSettings?.requireHourlyRate}
            />
            <span>Show client’s hourly budget</span>
          </div>
        </div>
        <div className={styles.budgetColumn}>
          <h4 className={styles.budgetLabel}>Builder monthly budget</h4>
          <div className={styles.budgetInputs}>
            <RateInputField
              prefix="$"
              suffix="/m"
              placeholder="Min..."
              value={
                data.builderMonthlyRateMin
                  ? round(data.builderMonthlyRateMin, 2)
                  : ''
              }
              onChange={(e): void =>
                handleChange({ builderMonthlyRateMin: Number(e.target.value) })
              }
            />
            <span>-</span>
            <RateInputField
              prefix="$"
              suffix="/m"
              placeholder="Max..."
              value={
                data.builderMonthlyRateMax
                  ? round(data.builderMonthlyRateMax, 2)
                  : ''
              }
              onChange={(e): void =>
                handleChange({ builderMonthlyRateMax: Number(e.target.value) })
              }
            />
          </div>
          <div className={styles.clientBudget}>
            {' '}
            Company's budget is $
            {toClientRate(
              data.builderMonthlyRateMin,
              (data.margin ?? 0) * 100,
              currentMission.rolesMargin,
            )}{' '}
            -{' '}
            {toClientRate(
              data.builderMonthlyRateMax,
              (data.margin ?? 0) * 100,
              currentMission.rolesMargin,
            )}
          </div>
          <div className={styles.budgetControl}>
            <ToggleSwitch
              size={'small'}
              checkedBackgroundColor="secondary"
              onChange={(checked): void => {
                // With full time retainer it's mandatory to collect monthly rate
                if (data.isFullTimeRetainer && !checked) {
                  return;
                }
                handleChange({
                  budgetSettings: {
                    ...data.budgetSettings,
                    requireMonthlyRate: checked,
                    ...(!!data.budgetSettings?.showMonthlyBudget && !checked
                      ? { showMonthlyBudget: false }
                      : undefined),
                  },
                });
              }}
              checked={!!data.budgetSettings?.requireMonthlyRate}
              disabled={disabled || !validRateRange}
            />
            <span>Require monthly rate on application</span>
          </div>
          <div className={styles.budgetControl}>
            <ToggleSwitch
              size={'small'}
              checkedBackgroundColor="secondary"
              onChange={(checked): void => {
                handleChange({
                  budgetSettings: {
                    ...data.budgetSettings,
                    showMonthlyBudget:
                      checked && !!data.budgetSettings?.requireMonthlyRate,
                  },
                });
              }}
              checked={!!data.budgetSettings?.showMonthlyBudget}
              disabled={disabled || !data.budgetSettings?.requireMonthlyRate}
            />
            <span>Show client’s monthly budget</span>
          </div>
        </div>
      </div>
    </Section>
  );
};
