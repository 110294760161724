import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useForm, FormProvider, FieldError } from 'react-hook-form';
import MainLayout from '@src/layouts/Main';
import { ShareSection } from './components/ShareSection';
import { BuilderSection, BuilderData } from './components/BuilderSection';
import { Project, ProjectsSection } from './components/ProjectsSection';
import { MostProudOfSection } from './components/MostProudOfSection';
import { Skill, SkillsSection } from './components/SkillsSection';
import { BuilderScores } from './components/BuilderScores';
import { GuildsRecommendationSection } from './components/GuildsRecommendationSection';
import {
  BorderRadius,
  Colors,
  FontSizes,
  FontWeights,
  formatLocation,
  Spacing,
  Icon,
  IconType,
} from '@ateams/components';
import LocationObject from '@a_team/models/dist/LocationObject';
import { LocationAndAvailabilitySection } from './components/LocationAndAvailabilitySection';
import { OverallImpressionSection } from './components/OverallImpressionSection';
import {
  CustomUserTagSelectOption,
  VettingFeedbackFormAdditionalMaterial,
  VettingFeedbackFormDefaultsV2,
  VettingFeedbackFormSelectOption,
  VettingProcessFeedbackRoleExpertiseScore,
  VettingProcessFeedbackRoleExpertiseScoreType,
  VettingProcessFeedbackScoresV2,
} from '@ateams/api/dist/endpoints/vetting-process-feedback-v2';
import { useStores } from '@src/stores';
import { useEffect, useRef } from 'react';
import TooltipWrapped from '@src/components/TooltipWrapped';
import { formatDistanceToNow } from 'date-fns';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';
import cn from 'classnames';
import { AdminNotesScore } from '@a_team/models/dist/AdminNotesObject';
import { VettingFeedbackFormAvailabilityOption } from '@a_team/models/dist/vetting-processes/feedbackV2';
import { useHistory } from 'react-router-dom';
import { VettingDashboardCompletedReviewsLocation } from '@src/locations';
import { RoleExpertiseScoresSection } from './components/RoleExpertiseScoresSection';

export const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: Spacing.large,
  },
  content: {
    width: 800,
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.large,
    justifyContent: 'center',
  },
  builderSection: {
    padding: Spacing.small,
    border: `1px solid ${Colors.regularLight}`,
    borderRadius: BorderRadius.default,
    backgroundColor: Colors.backgroundLight,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  saveDraftButton: {
    padding: `${Spacing.xsmall}px ${Spacing.small}px`,
    backgroundColor: Colors.backgroundDark,
    color: Colors.dark,
    borderRadius: BorderRadius.medium,
    border: 'none',
    cursor: 'pointer',
    height: 40,
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semiBold,
  },
  submitButton: {
    padding: `${Spacing.xsmall}px ${Spacing.small}px`,
    backgroundColor: Colors.secondaryDark,
    color: Colors.light,
    borderRadius: BorderRadius.medium,
    border: 'none',
    cursor: 'pointer',
    height: 40,
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semiBold,
  },
  disabled: {
    opacity: 0.5,
  },
  tooltipIcon: {
    width: 12,
    height: 12,
  },
  tooltipText: {
    fontSize: FontSizes.small,
  },
  lastSaveTooltip: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: Spacing.xsmall,
  },
});

export interface VettingFeedbackFormV2Props {
  vpid: string;
  feedbackFormDefaults: VettingFeedbackFormDefaultsV2;
  isViewMode: boolean;
}

export interface SkillError {
  notes?: FieldError;
}

export interface RoleExpertiseScoreError {
  score?: FieldError;
  reason?: FieldError;
}

export interface ProjectError {
  impressions?: FieldError;
}

export interface ScoresError {
  expertise?: FieldError;
  interactionExperience?: FieldError;
  englishLevel?: FieldError;
  accent?: FieldError;
}

interface VettingFeedbackFormData {
  additionalMaterialsFiles: VettingFeedbackFormAdditionalMaterial[];
  interviewDate: Date | undefined;
  askedNotToBeRecorded: boolean;
  scores: VettingProcessFeedbackScoresV2;
  expertiseReason: string;
  roleExpertiseScores: VettingProcessFeedbackRoleExpertiseScore[];
  individualContributor: boolean;
  teamLead: boolean;
  consultantStrategist: boolean;
  cSuiteLevel: boolean;
  interactionExperienceReason: string;
  projects: Project[];
  mostProudOf: string;
  workOnProductThatScaled: boolean;
  scaleNotes: string;
  guildsRecommendation: CustomUserTagSelectOption[];
  location: LocationObject | null;
  availability:
    | VettingFeedbackFormSelectOption<VettingFeedbackFormAvailabilityOption>
    | undefined;
  locationImpressions: string;
  skills: Skill[];
  skillsImpressions: string;
  callSummary: string;
  vetterNotes: string;
}

type ErrorType = {
  scores?: ScoresError;
  expertiseReason?: FieldError;
  roleExpertiseScores?: Record<string, RoleExpertiseScoreError>;
  interactionExperienceReason?: FieldError;
  projectsGlobal?: FieldError;
  projects?: Record<string, ProjectError> | FieldError;
  mostProudOf?: FieldError;
  scaleNotes?: FieldError;
  skills?: Record<string, SkillError>;
  skillsImpressions?: FieldError;
  locationAndAvailability?: FieldError;
  locationImpressions?: FieldError;
  callSummary?: FieldError;
  vetterNotes?: FieldError;
};

const VettingFeedbackFormV2 = ({
  vpid,
  feedbackFormDefaults,
  isViewMode,
}: VettingFeedbackFormV2Props) => {
  const styles = useStyles();
  const { auth, vettingProcessesV2 } = useStores();
  const [isSaving, setIsSaving] = useState(false);
  const [lastSaveDate, setLastSaveDate] = useState<Date | null>(
    feedbackFormDefaults.lastSaveDate
      ? new Date(feedbackFormDefaults.lastSaveDate)
      : null,
  );
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [isDraft, setIsDraft] = useState(feedbackFormDefaults.isDraft);
  const [loading, setLoading] = useLoadingState();
  const history = useHistory();

  const lastSaveTooltip = useMemo(() => {
    if (isSaving) {
      return (
        <div className={styles.lastSaveTooltip}>
          <Icon type={IconType.RefreshWhite} className={styles.tooltipIcon} />
          <div className={styles.tooltipText}>Saving...</div>
        </div>
      );
    }
    if (!lastSaveDate) {
      return (
        <div className={styles.lastSaveTooltip}>
          <Icon type={IconType.CloseRed} className={styles.tooltipIcon} />
          <div className={styles.tooltipText}>Not saved</div>
        </div>
      );
    }

    return (
      <div className={styles.lastSaveTooltip}>
        <Icon type={IconType.CheckWhite} className={styles.tooltipIcon} />
        <div className={styles.tooltipText}>
          Saved {formatDistanceToNow(lastSaveDate, { addSuffix: true })}
        </div>
      </div>
    );
  }, [lastSaveDate, isSaving]);

  const validateMinLength = (
    text: string,
    minLength: number,
    onlyIfNotEmpty: boolean,
  ): FieldError | undefined => {
    if (onlyIfNotEmpty && text.length === 0) {
      return undefined;
    }

    return text.length < minLength
      ? {
          type: 'required',
          message: `Enter at least ${minLength} characters.`,
        }
      : undefined;
  };

  const getProjectImpressionsLength = (project: Project) =>
    project.impressions?.length || 0;

  const validateProjects = (
    projects: Project[],
  ): {
    projects: Record<string, ProjectError> | undefined;
    projectsGlobal: FieldError | undefined;
  } => {
    if (!projects?.length) {
      return { projects: undefined, projectsGlobal: undefined };
    }

    const hasErrors = projects.some(
      (project) =>
        getProjectImpressionsLength(project) > 0 &&
        getProjectImpressionsLength(project) < 20,
    );

    if (
      !hasErrors &&
      !projects.some((project) => getProjectImpressionsLength(project) > 0)
    ) {
      return {
        projects: undefined,
        projectsGlobal: {
          type: 'required',
          message: 'Please add impressions to at least 1 project.',
        },
      };
    }

    if (!hasErrors) {
      return { projects: undefined, projectsGlobal: undefined };
    }

    return {
      projects: projects.reduce((acc, project) => {
        const impressionsLength = getProjectImpressionsLength(project);
        if (impressionsLength > 0 && impressionsLength < 20) {
          acc[project.id] = {
            impressions: {
              type: 'required',
              message: 'Enter at least 20 characters.',
            },
          };
        }
        return acc;
      }, {} as Record<string, ProjectError>),
      projectsGlobal: undefined,
    };
  };

  const validateSkills = (skills: Skill[]) => {
    if (!skills?.length) return undefined;

    const hasErrors = skills.some(
      (skill) => skill.score !== -1 && skill.notes.length < 20,
    );

    if (!hasErrors) return undefined;

    return skills.reduce((acc, skill) => {
      if (skill.score !== -1 && skill.notes.length < 20) {
        acc[skill.id] = {
          notes: {
            type: 'required',
            message: 'Enter at least 20 characters.',
          },
        };
      }
      return acc;
    }, {} as Record<string, SkillError>);
  };

  const validateRoleExpertiseScores = (
    roleExpertiseScores: VettingProcessFeedbackRoleExpertiseScore[],
  ) => {
    if (!roleExpertiseScores?.length) return undefined;

    const hasErrors = roleExpertiseScores.some(
      (roleExpertiseScore) =>
        (roleExpertiseScore.roleType ===
          VettingProcessFeedbackRoleExpertiseScoreType.PRIMARY &&
          (roleExpertiseScore.score === -1 ||
            roleExpertiseScore.reason.length < 20)) ||
        (roleExpertiseScore.roleType !==
          VettingProcessFeedbackRoleExpertiseScoreType.PRIMARY &&
          roleExpertiseScore.score !== -1 &&
          roleExpertiseScore.reason.length < 20),
    );

    if (!hasErrors) return undefined;

    return roleExpertiseScores.reduce((acc, roleExpertiseScore) => {
      if (
        roleExpertiseScore.roleType ===
          VettingProcessFeedbackRoleExpertiseScoreType.PRIMARY &&
        roleExpertiseScore.score === -1
      ) {
        acc[roleExpertiseScore.roleId] = {
          score: {
            type: 'required',
            message: 'Please select a score.',
          },
        };
      }

      if (
        roleExpertiseScore.score !== -1 &&
        roleExpertiseScore.reason.length < 20
      ) {
        acc[roleExpertiseScore.roleId] = {
          reason: {
            type: 'required',
            message: 'Enter at least 20 characters.',
          },
        };
      }
      return acc;
    }, {} as Record<string, RoleExpertiseScoreError>);
  };

  const validateRequiredScore = (
    score: AdminNotesScore | undefined,
  ): FieldError | undefined => {
    return score === undefined
      ? {
          type: 'required',
          message: 'Please select a score.',
        }
      : undefined;
  };

  const validateScores = (scores: VettingProcessFeedbackScoresV2) => {
    const errors = {
      accent: validateRequiredScore(scores.accent),
      englishLevel: validateRequiredScore(scores.englishLevel),
      expertise: withNewRoleExpertiseScore
        ? undefined
        : validateRequiredScore(scores.expertise),
      interactionExperience: validateRequiredScore(
        scores.interactionExperience,
      ),
    };

    removeUndefined(errors);

    if (!Object.keys(errors).length) return undefined;

    return errors as
      | Record<keyof VettingProcessFeedbackScoresV2, FieldError>
      | undefined;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const removeUndefined = (errors: any) => {
    // Remove undefined errors
    return Object.keys(errors).forEach((key) => {
      if (errors[key as keyof typeof errors] === undefined) {
        delete errors[key as keyof typeof errors];
      }
    });
  };

  const scrollToFirstError = (errors: ErrorType) => {
    // If there are errors, scroll to the first one
    if (Object.keys(errors).length > 0) {
      const firstErrorKey = Object.keys(errors)[0] as keyof ErrorType;
      const firstError = errors[firstErrorKey];

      if (
        firstError &&
        typeof firstError === 'object' &&
        !('type' in firstError)
      ) {
        // Handle nested errors (projects, skills)
        const firstNestedKey = Object.keys(firstError)[0];
        const element = document.querySelector(
          `[data-name="${firstErrorKey}.${firstNestedKey}"], [name="${firstErrorKey}.${firstNestedKey}"]`,
        );
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      } else {
        // Handle direct errors
        const element = document.querySelector(
          `[data-name="${firstErrorKey}"], [name="${firstErrorKey}"]`,
        );
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  };

  const validateForm = (
    data: VettingFeedbackFormData,
    autoscrollToFirstError: boolean,
  ) => {
    const { projects, projectsGlobal } = validateProjects(data.projects);

    const errors: ErrorType = {
      scores: validateScores(data.scores),
      expertiseReason: !withNewRoleExpertiseScore
        ? validateMinLength(data.expertiseReason, 40, false)
        : undefined,
      roleExpertiseScores: validateRoleExpertiseScores(
        data.roleExpertiseScores,
      ),
      interactionExperienceReason: validateMinLength(
        data.interactionExperienceReason,
        40,
        false,
      ),
      projectsGlobal,
      projects,
      mostProudOf: validateMinLength(data.mostProudOf, 40, true),
      scaleNotes: data.workOnProductThatScaled
        ? validateMinLength(data.scaleNotes, 20, false)
        : undefined,
      skills: validateSkills(data.skills),
      skillsImpressions: validateMinLength(data.skillsImpressions, 40, true),
      locationImpressions:
        data.location || data.availability
          ? validateMinLength(data.locationImpressions, 20, false)
          : undefined,
      callSummary: validateMinLength(data.callSummary, 280, false),
      vetterNotes: validateMinLength(data.vetterNotes, 280, false),
    };

    removeUndefined(errors);

    if (autoscrollToFirstError) {
      scrollToFirstError(errors);
    }

    return {
      values: data,
      errors,
    };
  };

  const withNewRoleExpertiseScore = useMemo(
    () =>
      auth.withNewRoleExpertiseScore &&
      feedbackFormDefaults.primaryRoleCategoryId
        ? true
        : false,
    [
      auth.withNewRoleExpertiseScore,
      feedbackFormDefaults.primaryRoleCategoryId,
    ],
  );

  const methods = useForm<VettingFeedbackFormData>({
    defaultValues: {
      additionalMaterialsFiles:
        feedbackFormDefaults.additionalMaterialsFiles || [],
      interviewDate: feedbackFormDefaults.interviewDate
        ? new Date(feedbackFormDefaults.interviewDate)
        : new Date(),
      askedNotToBeRecorded: feedbackFormDefaults.askedNotToBeRecorded,
      scores: feedbackFormDefaults.scores,
      expertiseReason: feedbackFormDefaults.expertiseReason,
      interactionExperienceReason:
        feedbackFormDefaults.interactionExperienceReason,
      projects: feedbackFormDefaults.projects || [],
      mostProudOf: feedbackFormDefaults.mostProudOf,
      workOnProductThatScaled: feedbackFormDefaults.workOnProductThatScaled,
      scaleNotes: feedbackFormDefaults.scaleNotes,
      guildsRecommendation: feedbackFormDefaults.guildsRecommendation || [],
      location: feedbackFormDefaults.location,
      availability: feedbackFormDefaults.availability,
      locationImpressions: feedbackFormDefaults.locationImpressions,
      skills: feedbackFormDefaults.skills || [],
      skillsImpressions: feedbackFormDefaults.skillsImpressions,
      callSummary: feedbackFormDefaults.callSummary,
      vetterNotes: feedbackFormDefaults.vetterNotes,
      roleExpertiseScores: feedbackFormDefaults.roleExpertiseScores || [],
      individualContributor: feedbackFormDefaults.individualContributor,
      teamLead: feedbackFormDefaults.teamLead,
      consultantStrategist: feedbackFormDefaults.consultantStrategist,
      cSuiteLevel: feedbackFormDefaults.cSuiteLevel,
    },
    resolver: (data) => validateForm(data, true),
  });

  const { handleSubmit, watch, setValue } = methods;
  const roleExpertiseScores: VettingProcessFeedbackRoleExpertiseScore[] =
    watch('roleExpertiseScores') || [];

  useEffect(() => {
    if (withNewRoleExpertiseScore) {
      const newExpertise = Math.max(
        ...roleExpertiseScores.map(
          (roleExpertiseScore) => roleExpertiseScore.score,
        ),
      ) as AdminNotesScore;

      setValue('scores.expertise', newExpertise);
    }
  }, [withNewRoleExpertiseScore, roleExpertiseScores]);

  const saveForm = async (
    isDraft: boolean,
    jumpToFirstError: boolean,
  ): Promise<boolean> => {
    try {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      const data = methods.getValues();

      const validation = validateForm(data, jumpToFirstError);
      if (
        !isDraft &&
        jumpToFirstError &&
        Object.keys(validation.errors).length > 0
      ) {
        return false;
      }

      setIsSaving(true);

      await vettingProcessesV2.submitFeedbackForm(
        vpid,
        {
          interviewDate: data.interviewDate,
          askedNotToBeRecorded: data.askedNotToBeRecorded,
          scores: data.scores,
          expertiseReason: data.expertiseReason,
          interactionExperienceReason: data.interactionExperienceReason,
          projects: data.projects.map((project) => ({
            id: project.id,
            impressions: project.impressions,
          })),
          mostProudOf: data.mostProudOf,
          workOnProductThatScaled: data.workOnProductThatScaled,
          scaleNotes: data.scaleNotes,
          guildsRecommendation: data.guildsRecommendation,
          location: data.location || null,
          availability: data.availability as
            | VettingFeedbackFormSelectOption<VettingFeedbackFormAvailabilityOption>
            | undefined,
          locationImpressions: data.locationImpressions,
          additionalMaterialsFiles: data.additionalMaterialsFiles.filter(
            (additionalMaterial) => !!additionalMaterial.url,
          ),
          skills: data.skills,
          skillsImpressions: data.skillsImpressions,
          callSummary: data.callSummary,
          vetterNotes: data.vetterNotes,
          roleExpertiseScores: data.roleExpertiseScores.filter(
            (score) => score.score >= 0,
          ),
          individualContributor: data.individualContributor,
          teamLead: data.teamLead,
          consultantStrategist: data.consultantStrategist,
          cSuiteLevel: data.cSuiteLevel,
          isDraft,
        },
        data.additionalMaterialsFiles
          .map((additionalMaterial) => additionalMaterial.file)
          .filter((file): file is File => !!file),
      );
      setIsSaving(false);
      setLastSaveDate(new Date());
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setIsSaving(false);
    }
  };

  const onSubmit = async (autoscrollToFirstError: boolean) => {
    try {
      setLoading(true);

      setIsDraft(false);

      if (await saveForm(false, autoscrollToFirstError)) {
        setLoading('Form submitted');
        const redirectTo = `${VettingDashboardCompletedReviewsLocation}${
          auth.isAdmin ? '?is-admin-mode=true' : ''
        }`;
        history.push(redirectTo);
      } else {
        setLoading('Please fix the errors and try again.');
      }
    } catch (error) {
      console.error(error);
      setLoading('An error occurred');
    }
  };

  const onSaveDraft = async (autoscrollToFirstError: boolean) => {
    try {
      if (isSaving) return;
      setLoading(saveForm(isDraft, autoscrollToFirstError), 'Form saved');
    } catch (error) {
      console.error(error);
      setLoading('An error occurred');
    }
  };

  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const subscription = watch(() => {
      // Clear any existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Set new timeout
      timeoutRef.current = setTimeout(() => {
        if (isDraft) {
          onSaveDraft(false);
        }
      }, 5000);
    });

    return () => {
      // Cleanup: clear subscription and any pending timeout
      subscription.unsubscribe();
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [watch]);

  const builderData: BuilderData = {
    name: feedbackFormDefaults.name,
    email: feedbackFormDefaults.email,
    location: feedbackFormDefaults.userLocation
      ? formatLocation(feedbackFormDefaults.userLocation)
      : '',
    avatarUrl: feedbackFormDefaults.avatarUrl || '',
    profileUrl: feedbackFormDefaults.profileUrl || '',
    linkedinUrl: feedbackFormDefaults.linkedInUrl || '',
    resumeUrl: feedbackFormDefaults.resumeUrl || '',
    portfolio: feedbackFormDefaults.portfolio,
    evaluationCalls: feedbackFormDefaults.evaluationCalls || [],
    uploadedMaterials: feedbackFormDefaults.uploadedMaterials || [],
    additionalMaterialDetails:
      feedbackFormDefaults.additionalMaterialDetails || '',
    missionsBuilderAppliedTo: feedbackFormDefaults.appliedMissions || [],
    evaluationType: feedbackFormDefaults.evaluationType,
    talentCategory: feedbackFormDefaults.talentCategory,
    admin: feedbackFormDefaults.admin,
    interviewer: feedbackFormDefaults.interviewer,
  };

  return (
    <MainLayout
      title="Vetting Feedback Form"
      contentClassName={styles.container}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(() => onSubmit(true))}
          className={styles.content}
        >
          <ShareSection vpid={vpid} />
          <BuilderSection builderData={builderData} isViewMode={isViewMode} />
          <BuilderScores
            isViewMode={isViewMode}
            withNewRoleExpertiseScore={withNewRoleExpertiseScore}
          />
          {withNewRoleExpertiseScore && <RoleExpertiseScoresSection />}
          <ProjectsSection
            uid={feedbackFormDefaults.uid}
            selectedProjects={feedbackFormDefaults.selectedProjects || []}
            showAllProjects={showAllProjects}
            setShowAllProjects={setShowAllProjects}
            isViewMode={isViewMode}
          />
          <MostProudOfSection isViewMode={isViewMode} />
          <SkillsSection
            talentCategoryIds={feedbackFormDefaults.talentCategoryIds}
            isViewMode={isViewMode}
          />
          <GuildsRecommendationSection
            guildsValues={feedbackFormDefaults.guildsValues}
            isViewMode={isViewMode}
          />
          <LocationAndAvailabilitySection
            availabilityOptions={feedbackFormDefaults.availabilityOptions}
            isViewMode={isViewMode}
          />
          <OverallImpressionSection isViewMode={isViewMode} />
          {!isViewMode && (
            <div className={styles.buttonsContainer}>
              <TooltipWrapped position="right" arrow html={lastSaveTooltip}>
                {isDraft ? (
                  <button
                    type="button"
                    className={cn(
                      styles.saveDraftButton,
                      isSaving && styles.disabled,
                    )}
                    onClick={() => onSaveDraft(false)}
                    disabled={isSaving}
                  >
                    Save draft
                  </button>
                ) : (
                  <></>
                )}
              </TooltipWrapped>
              <button
                type="submit"
                className={cn(styles.submitButton, isSaving && styles.disabled)}
                disabled={isSaving}
              >
                Submit form
              </button>
            </div>
          )}
        </form>
      </FormProvider>
      <LoadingIndicator loading={loading} />
    </MainLayout>
  );
};

export default observer(VettingFeedbackFormV2);
