import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import OutlinedInput, {
  OutlinedInputProps,
} from '@src/components/Inputs/OutlinedInput';
import { Card, Icon, IconType } from '@ateams/components';
import { useStores } from '@src/stores';
import UserObject, {
  AnonymousUserObject,
  BasicUserObject,
  UserType,
} from '@a_team/models/dist/UserObject';
import useClickOutside from '@src/hooks/useClickOutside';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import UserAvatar from '@src/components/UserAvatar';
import useDebounceState from '@src/hooks/useDebounceState';
import { UserItem } from './UserItem';

export interface MultipleUserSelectorProps {
  users?: (BasicUserObject | UserObject)[];
  displaySelection?: boolean;
  required?: boolean;
  margin?: OutlinedInputProps['margin'];
  placeholder?: string;
  onSelect(
    user: (AnonymousUserObject | BasicUserObject)[] | null | undefined,
  ): void;
  disabled?: boolean;
  includeClientUsers?: boolean;
}

const useStyles = createUseStyles({
  menu: {
    padding: 0,
    overflowY: 'scroll',
    margin: 0,
    display: 'block',
    width: '100%',
    maxHeight: 336,
  },
  userIcon: {
    marginRight: 8,
  },
  selectedUsers: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 8,
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: '8px 16px',
    borderRadius: 100,
    backgroundColor: '#EDEDF0',
  },
});

const MultipleUserSelector = observer((props: MultipleUserSelectorProps) => {
  const stores = useStores();
  const {
    auth,
    users: { getUsersByString, adminQueryByString },
  } = stores;

  const { disabled = false } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [userResults, setUserResults] = useState<
    (BasicUserObject | AnonymousUserObject)[]
  >([]);
  const [selectedUsers, setSelectedUsers] = useState<
    BasicUserObject[] | undefined | null
  >(props.users);

  const [searchQuery, setSearchQuery] = useDebounceState<string>(
    '',
    (query) => getUsers(query, props.includeClientUsers ?? false),
    400,
  );

  const menuRef = useRef(null);
  const styles = useStyles();

  useEffect((): void => {
    setSelectedUsers(props.users);
  }, [props.users]);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(e.target.value);
    !isOpen && setIsOpen(true);
    if (e.target.value === '') {
      setIsOpen(false);
    }
  };

  const getUsers = async (
    query: string,
    includeCompanyUsers = false,
  ): Promise<void> => {
    if (query !== '') {
      const result = auth.isAdmin
        ? await adminQueryByString(query)
        : await getUsersByString(query);
      const filtered = result.filter(
        (user) =>
          (includeCompanyUsers && user.type === UserType.CompanyUser) ||
          user.type === UserType.User,
      );
      setUserResults(
        filtered.length
          ? filtered
          : [{ username: null, fullName: searchQuery }],
      );
    }
  };

  const onUserSelect = (
    user: AnonymousUserObject | BasicUserObject | null,
  ): void => {
    if (
      user &&
      'uid' in user &&
      !selectedUsers?.find((u) => u.uid === user.uid)
    ) {
      const newSelectedUsers = selectedUsers
        ? [...selectedUsers, user]
        : [user];
      setSelectedUsers(newSelectedUsers);
      props.onSelect(newSelectedUsers);
    }
    setSearchQuery('');
    setIsOpen(false);
  };

  const onRemoveUser = (user: BasicUserObject): void => {
    const newSelectedUsers = selectedUsers?.filter((u) => u.uid !== user.uid);
    setSelectedUsers(newSelectedUsers);
    props.onSelect(newSelectedUsers);
  };

  useClickOutside(menuRef, () => setIsOpen(false));

  return (
    <div>
      <OutlinedInput
        disabled={disabled}
        type="text"
        placeholder={props.placeholder || 'Add a person...'}
        name="team"
        onChange={onChange}
        value={searchQuery}
        required={props.required}
        style={{ marginBottom: 8 }}
        dropdown={
          isOpen && (
            <Card className={styles.menu}>
              <div ref={menuRef}>
                {userResults.map((user) => {
                  return (
                    <UserItem
                      user={user}
                      onUserSelect={onUserSelect}
                      key={'uid' in user ? user.uid : user.fullName}
                    />
                  );
                })}
              </div>
            </Card>
          )
        }
      />
      <div className={styles.selectedUsers}>
        {selectedUsers?.map((user) => (
          <span className={styles.user} key={user.uid}>
            <UserAvatar
              src={user.username !== null ? user.profilePictureURL : undefined}
              size={24}
              containerClassName={styles.userIcon}
            />
            <span>{user.fullName}</span>
            <Icon
              type={IconType.CloseGray}
              size="xsmall"
              onClick={() => onRemoveUser(user)}
            />
          </span>
        ))}
      </div>
    </div>
  );
});
export default MultipleUserSelector;
