import {
  ExperienceMember,
  MetricData,
  ProjectDescriptionBlurbNode,
  ProjectDescriptionBlurbNodeType,
} from '@a_team/models/dist/ExperienceObject';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { useProjectDescriptionSuggestionMutation } from '@src/rq/experiences';
import { useQueryTalentIndustries } from '@src/rq/industries';
import { useStores } from '@src/stores';
import { Expertise } from '@src/stores/Profile/models';
import { Editor } from '@tiptap/react';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useMemo, useState } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import ErrorForm from '../common/ErrorForm';
import CommonStyles from '../common/styles';
import Suggestion from './Suggestion';
import { useEditor } from '@src/hooks/useEditor';
import TextEditor from '@src/components/TextEditor';
import { countCharsForEditorValue } from '@src/components/TextEditor/helper';

interface DescriptionProps {
  roles?: { value: string; label: string }[] | undefined;
  allSkills?: Expertise[] | undefined;
  isNewProject?: boolean;
  descriptionHeader?: string;
}

const Description = ({
  roles,
  allSkills,
  isNewProject,
  descriptionHeader,
}: DescriptionProps) => {
  const {
    register,
    unregister,
    trigger,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();

  const { auth } = useStores();
  const analytics = useAnalytics();
  const commonStyles = CommonStyles();

  const [isDiscarded, setIsDiscarded] = useState(false);
  const [hasInsertedSuggestion, setHasInsertedSuggestion] = useState(false);
  const [hasEditedAfterInsert, setHasEditedAfterInsert] = useState(false);
  const [valuesAfterInsertOrDiscard, setValuesAfterInsertOrDiscard] =
    useState<FieldValues>();
  const { data: allIndustries } = useQueryTalentIndustries();

  const { mutate, reset, data, isLoading } =
    useProjectDescriptionSuggestionMutation();

  useEffect(() => {
    if (valuesAfterInsertOrDiscard && auth.withProjectDescriptionAssistant) {
      const currentValues = getValues ? getValues() : {};
      if (!isEqual(currentValues, valuesAfterInsertOrDiscard)) {
        setHasEditedAfterInsert(true);
        setIsDiscarded(false);
      } else {
        setHasEditedAfterInsert(false);
      }
    }
  }, [valuesAfterInsertOrDiscard, getValues && getValues()]);

  useEffect(() => {
    register('descriptionHTML', {
      validate: (value) => {
        const cleanedValue = (value ?? '').replace(
          /<br>|<h3><\/h3>|<p><\/p>/g,
          '',
        );

        const charCount = countCharsForEditorValue(value);

        if (charCount < 100) {
          return '100 characters minimum. The description must contain at least one title and one paragraph.';
        } else if (charCount > 1000) {
          return 'The description cannot exceed 1000 characters.';
        }

        const headingRegex = /^<h3[\s\S]*?<\/h3>/i;
        const paragraphRegex = /<p[^>]*>.*<\/p>/gi;

        const hasHeading = headingRegex.test(cleanedValue);
        const hasParagraph = paragraphRegex.test(cleanedValue);

        if (!hasHeading && !hasParagraph) {
          return 'The project details must start with a headline and contain at least one paragraph';
        }

        if (!hasHeading) {
          return 'The project details must start with a headline';
        }

        if (!hasParagraph) {
          return 'The project details must contain at least one paragraph';
        }

        return true;
      },
    });

    return () => {
      unregister('descriptionHTML');
    };
  }, [register, unregister]);

  const descriptionInitialValue = useMemo(() => {
    let initialValue = `<h3></h3><p></p>`;
    const descriptionHTML = watch('descriptionHTML');
    const description = watch('description');
    if (descriptionHTML) {
      initialValue = descriptionHTML;
    } else if (description) {
      let finalDescription = description;

      if (description.includes('\n')) {
        const paragraphs = description.split('\n\n');

        finalDescription = paragraphs
          .filter((paragraph: string) => paragraph.trim() !== '')
          .map((paragraph: string) => `<p>${paragraph}</p>`)
          .join('');
      }

      initialValue = `<h3>${
        descriptionHeader || ''
      }</h3><p>${finalDescription}</p>`;
    }

    return initialValue;
  }, [watch('descriptionHTML'), watch('description')]);

  const onDescriptionChange = (editor: Editor) => {
    const currentContentPlainText = editor.getText();

    setValue('description', currentContentPlainText);
    setValue('descriptionHTML', editor.getHTML());
    trigger('description');
    trigger('descriptionHTML');
  };

  const { editor } = useEditor({
    initialValue: descriptionInitialValue,
    onDescriptionChange,
  });

  useEffect(() => {
    if (editor) {
      const currentContentPlainText = editor.getText();
      if (currentContentPlainText) {
        setValue('description', currentContentPlainText);
        setValue('descriptionHTML', editor.getHTML());
        trigger('description');
        trigger('descriptionHTML');
      }
    }
  }, [editor, setValue, trigger]);

  if (!editor) {
    return null;
  }

  const charCount = countCharsForEditorValue(editor.getHTML());

  const onInsertSuggestion = (suggestion: ProjectDescriptionBlurbNode[]) => {
    let contentHTML = '';

    suggestion.forEach((node) => {
      if (node.type === ProjectDescriptionBlurbNodeType.Title) {
        contentHTML += `<h3>${node.content}</h3>`;
      } else if (node.type === ProjectDescriptionBlurbNodeType.Paragraph) {
        contentHTML += `<p>${node.content}</p>`;
      }
    });

    editor.commands.setContent(contentHTML);

    const currentContentPlainText = editor.getText();

    setValue('description', currentContentPlainText);
    setValue('descriptionHTML', editor.getHTML());
    trigger('description');
    trigger('descriptionHTML');
    setHasInsertedSuggestion(true);
    setValuesAfterInsertOrDiscard(getValues && getValues());
    setHasEditedAfterInsert(false);
    reset();

    analytics.trackProjectDescriptionInsertSuggestionClicked(
      auth.uid ?? '',
      suggestion.map((node) => node.content).join('\n'),
    );
  };

  const onGenerateSuggestion = () => {
    if (!getValues) return;

    const {
      title,
      description,
      companyData,
      industry,
      skills,
      jobRole,
      jobRoleId,
      metrics,
      startDate,
      endDate,
      members,
      hasZeroToOneExperience,
      hasManagedPeople,
      numberOfPeopleManaged,
    } = getValues();

    const industryName = allIndustries?.find(
      (option) => option.id === industry,
    )?.name;

    const metricsString = (metrics as MetricData[])
      ?.map(
        (metric, i) =>
          `Metric ${i + 1}:  ${metric.title} ${metric.description}`,
      )
      .join('\n');

    const role =
      jobRole || roles?.find((role) => role.value === jobRoleId)?.label || '';
    const membersString = (members as ExperienceMember[])
      .filter((member) => {
        return !('uid' in member) || member.uid !== auth.uid;
      })
      .map((member, index) => {
        const number = index + 1;
        if ('fullName' in member) {
          const role = roles?.find(
            (role) => role.value === member.memberRole,
          )?.label;
          return `Member ${number} name: ${member.fullName}${
            role ? `, Member ${number} role: ${role}` : ''
          }`;
        }
        return undefined;
      })
      .filter((item) => item !== undefined)
      .join(', ');

    const skillNames = (skills as string[])
      ?.map((skill) => allSkills?.find((s) => s.id === skill)?.name)
      .join(', ');

    mutate({
      title: title,
      description: description || '',
      companyName: companyData?.name || '',
      industry: industryName || '',
      metrics: metricsString || '',
      role: role,
      skills: skillNames,
      timeFrame: startDate
        ? `${startDate} - ${endDate || 'Present'}`
        : undefined,
      teamMembers: membersString,
      hasZeroToOneExperience: hasZeroToOneExperience,
      hasManagedPeople: hasManagedPeople,
      numberOfPeopleManaged: numberOfPeopleManaged,
    });
  };

  const onDiscardSuggestion = (suggestion: ProjectDescriptionBlurbNode[]) => {
    setIsDiscarded(true);
    setValuesAfterInsertOrDiscard(getValues && getValues());
    reset();

    analytics.trackProjectDescriptionDiscardSuggestionClicked(
      auth.uid ?? '',
      suggestion.map((node) => node.content).join('\n'),
    );
  };

  return (
    <div
      className={commonStyles.container}
      data-testing-id="profile-project-card-description-text-input"
    >
      <div className={commonStyles.title}>Project details</div>
      {editor && <TextEditor editor={editor} charCount={charCount} />}
      {auth.withProjectDescriptionAssistant && (
        <Suggestion
          roles={roles}
          isNewProject={isNewProject}
          hasEditedAfterInsert={hasEditedAfterInsert}
          isDiscarded={isDiscarded}
          charCount={charCount}
          isLoading={isLoading}
          onGenerateSuggestion={onGenerateSuggestion}
          onDiscardSuggestion={onDiscardSuggestion}
          suggestion={data?.projectDescriptionBlurbNodes}
          onInsertSuggestion={onInsertSuggestion}
          hasInsertedSuggestion={hasInsertedSuggestion}
        />
      )}

      <ErrorForm field="description" errors={errors} />
      <ErrorForm field="descriptionHTML" errors={errors} />
    </div>
  );
};

export default Description;
