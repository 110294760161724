import { Button, Modal } from '@a_team/ui-components';
import { Breakpoints } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface DeleteLinkedinRecommendationModalProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  isDeletionLoading?: boolean;
}

const useStyles = createUseStyles({
  modal: {
    '& > div': {
      padding: 20,
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'center',
    margin: 0,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 40,
  },
  desc: {
    margin: '8px 0 0 0',
    textAlign: 'center',
    color: '#818388',
  },
  btnsContainer: {
    display: 'flex',
    gap: 12,
    '& button': {
      height: '40px !important',
      width: '140px !important',
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      minWidth: 680,
      borderRadius: '16px !important',
      '& > div': {
        padding: 40,
      },
    },
  },
});

const DeleteLinkedinRecommendationModal = ({
  open,
  onClose,
  onDelete,
  isDeletionLoading,
}: DeleteLinkedinRecommendationModalProps): JSX.Element => {
  const styles = useStyles();

  return (
    <Modal
      variant="slideUp"
      isOpen={open}
      onClose={onClose}
      className={styles.modal}
      shouldHideGradientStroke={true}
    >
      <div className={styles.container}>
        <div>
          <h4 className={styles.title}>Delete this recommendation?</h4>
          <p className={styles.desc}>
            Deleting will permanently remove this recommendation from your
            profile and it cannot be reimported. Alternately, you can hide the
            recommendation from all users.
          </p>
        </div>
        <div className={styles.btnsContainer}>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="main" onClick={onDelete} loading={isDeletionLoading}>
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteLinkedinRecommendationModal;
