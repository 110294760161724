import { Icon, Input, Select } from '@a_team/ui-components';
import { useQueryTalentIndustries } from '@src/rq/industries';
import cx from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import defaultProjectLogo from './defaultProjectLogo.svg';
import { ExistingProject, NewProject } from '@src/stores/Profile/models';
import {
  Breakpoints,
  Colors,
  SelectOption,
  TextColors,
} from '@ateams/components';
import { NewCompanyId } from '@src/components/CompanyPicker';
import FileDropArea from '@src/components/FileDropArea';
import useLoadingState from '@src/hooks/useLoadingState';
import * as uploadcareApi from '@src/logic/uploadcare';
import { isNil } from 'lodash';
import { FileRejection, FileWithPath } from 'react-dropzone';
import editSvg from './edit.svg';
import trashSvg from './trash.svg';
import ErrorForm from '../common/ErrorForm';
import { Company } from '@a_team/models/dist/Company';
import { isValidUrl, withHttps } from '@src/helpers/urls';
import CompanyLogoFallback from '@src/components/CompanyLogoFallback';
import { CompanyV2Picker } from '@src/components/CompanyV2Picker';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';

interface HeaderProps {
  isOwner: boolean;
  selectedProject: NewProject | ExistingProject;
}

const useStyles = createUseStyles({
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logoWrapper: {
    width: '72px',
    height: '72px',
    position: 'relative',
  },
  inputWrapper: {
    '& div': {
      width: '100%',
    },
  },
  uploaderButton: {
    position: 'absolute',
    bottom: 0,
    right: -40,
    width: '32px',
    height: '32px',
    border: '1px solid #DADADC',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  removeButton: {
    position: 'absolute',
    bottom: 0,
    right: -80,
    width: '32px',
    height: '32px',
    border: '1px solid #DADADC',
    padding: 0,
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  projectTitleWrapper: {
    marginTop: 40,
  },
  projectTitleInput: {
    width: '100%',
    padding: 16,
    marginTop: 4,
    border: '1px solid #DADADC',
    borderRadius: 8,
    fontStyle: 'normal',
    fontSize: 15,
    color: '#222222',
    '&::placeholder': {
      color: '#818388',
    },
    '&:disabled': {
      backgroundColor: '#FFFFFF',
    },
  },
  header: {
    marginTop: 40,
    display: 'flex',
    gap: 24,
    flexDirection: 'column',
    '& > div': {
      flex: 1,
    },
  },
  label: {
    fontSize: 12,
  },
  borderlessInput: {
    fontSize: '15px !important',
    '&::placeholder': {
      color: '#818388 !important',
    },
  },
  companyNameWrapper: {
    '& span[class^="companyAvatarsContainer"]': {
      display: 'none',
    },
    '& input[class^="companyPickerInput"] + div + span': {
      display: 'none',
    },
    '& > span': {
      display: 'none',
    },
    '& input[class*="companyNameInput"]': {
      '&::placeholder': {
        color: `${TextColors.lighter} !important`,
      },
    },
  },
  companyNameInput: {
    border: 'none !important',
    padding: '0',
    fontSize: '15px !important',
    '& + span': {
      display: 'none',
    },
  },
  inputWithIcon: {
    paddingLeft: '25px !important',
  },
  purpleIsImportant: {
    color: '#6D00D7 !important',
  },
  industriesSelect: {
    marginTop: 4,
    '& > div': {
      border: 'none !important',
    },
  },
  industrySelect: {
    marginTop: 4,
    '& > div': {
      border: 'none !important',
    },
  },
  linkedCompanyError: {
    border: '1px solid #F37883',
    padding: 16,
    borderRadius: 8,
    '& input': {
      color: '#F63041',
    },
    '& input + div + span': {
      color: '#F63041 !important',
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    header: {
      flexDirection: 'row',
    },
  },
});

const Header = ({ isOwner, selectedProject }: HeaderProps): JSX.Element => {
  const {
    register,
    unregister,
    trigger,
    control,
    setValue,
    watch,
    formState: { errors },
    getValues,
  } = useFormContext();

  const { auth } = useStores();
  const styles = useStyles();

  const { data: allIndustries } = useQueryTalentIndustries();
  const [uploading, setUploading] = useLoadingState(null);
  const [selectedCompany, setSelectedCompany] = useState<Company>();
  const [companyInputHasFocus, setCompanyInputHasFocus] = useState(false);

  useEffect(() => {
    if (auth.withMultipleIndustriesForExperiences) {
      register('industries', { required: 'Industry is required' });
    } else {
      register('industry', { required: 'Industry is required' });
    }

    register('companyName', {
      validate: (companyName) => {
        const optOutCompanyWithNoWebsite = getValues(
          'optOutCompanyWithNoWebsite',
        );

        const companyV2Id = getValues('companyV2Id');

        if (!companyName) {
          return 'Company is required';
        }

        if (companyName && !companyV2Id && !optOutCompanyWithNoWebsite) {
          return 'Select an existing company from the dropdown or create a new one';
        }

        return true;
      },
    });

    return () => {
      if (auth.withMultipleIndustriesForExperiences) {
        unregister('industries');
      } else {
        unregister('industry');
      }
      unregister('companyName');
    };
  }, [register, unregister]);

  const logoUrl = watch('logoURL');
  const companyId = watch('companyV2Id');
  const industryId = watch('industry');
  const industryIds = watch('industries') ?? [];
  const isCompanySelected = !!companyId;

  const [industry, industries] = useMemo(() => {
    if (auth.withMultipleIndustriesForExperiences) {
      const industries = allIndustries
        ?.filter((option) => industryIds.includes(option.id))
        .map((option) => ({ value: option?.id, label: option?.name }));

      return [null, industries];
    } else {
      const found = allIndustries?.find((option) => option.id === industryId);
      const industry = found
        ? { value: found?.id, label: found?.name }
        : undefined;

      return [industry, null];
    }
  }, [allIndustries, industryIds, industryId]);

  const handleFileSelected = async (
    files: Array<FileWithPath>,
    rejectedFiles: Array<FileRejection>,
  ) => {
    try {
      setUploading(null);

      if (rejectedFiles.length > 0) {
        return setUploading(new Error(rejectedFiles[0].errors[0].message));
      }

      if (!files.length) {
        return;
      }

      const [file] = files;

      setUploading(true);

      const logoUrl = await uploadcareApi.uploadFile(
        new File([file], file.name, { type: file.type }),
      );

      setValue('logoURL', logoUrl);

      setUploading('Resume Uploaded');
      setUploading(null);
    } catch (err) {
      setUploading(err as Error);
    }
  };

  const isCustomCompanyLogo = logoUrl?.includes('ucarecdn.com');

  const onSelectedCompaniesV2Change = (
    companies: Array<Company & { url: string }>,
  ) => {
    if (companies.length === 0) {
      return;
    }
    // if the selected company is the current company do nothing as there was no change
    setSelectedCompany(companies[0] ?? undefined);
    setValue('companyName', companies[0]?.name ?? null);
    setValue(
      'optOutCompanyWithNoWebsite',
      !!companies[0]?.name && !companies[0]?.url,
    );
    setValue('websiteURL', companies[0]?.url ?? null);
    setValue(
      'companyV2Id',
      (companies[0]?.id === NewCompanyId ? null : companies[0]?.id) ?? null,
    );

    trigger('companyV2Id');
    trigger('companyName');
    trigger('optOutCompanyWithNoWebsite');
    trigger('websiteURL');

    if (companies[0]?.id === companyId) {
      return;
    }
    // if we have a custom logo, we don't change the custom logo
    if (!isCustomCompanyLogo) {
      setValue('logoURL', companies[0]?.logoUrl ?? '');
    }
  };

  const onRemoveIcon = () => {
    setValue('logoURL', '');
  };

  const addHttps = (value: string) => {
    return value !== '' ? withHttps(value) : value;
  };

  return (
    <div>
      <div className={styles.centerContent}>
        <span className={styles.logoWrapper}>
          <CompanyLogoFallback
            logoUrl={logoUrl || selectedCompany?.logoUrl || defaultProjectLogo}
            size={72}
          />

          {(isOwner || !selectedProject.companyV2Id) && isCompanySelected && (
            <>
              <div className={styles.uploaderButton}>
                <FileDropArea
                  disabled={!isNil(uploading)}
                  onFileDrop={handleFileSelected}
                >
                  {({ openDialog }) => (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        openDialog();
                      }}
                    >
                      <img src={editSvg} alt="Edit" width="16" height="16" />
                    </div>
                  )}
                </FileDropArea>
              </div>

              {isCustomCompanyLogo && (
                <button
                  type="button"
                  className={styles.removeButton}
                  onClick={onRemoveIcon}
                >
                  <img src={trashSvg} alt="Remove" width="16" height="16" />
                </button>
              )}
            </>
          )}
        </span>
      </div>
      <div className={styles.projectTitleWrapper}>
        <label className={styles.label}>Project title</label>
        <Controller
          name="title"
          control={control}
          rules={{ required: 'Title is required' }}
          defaultValue=""
          render={({ field }) => (
            <input
              {...field}
              className={styles.projectTitleInput}
              type="text"
              disabled={!isOwner && !!selectedProject.title}
              placeholder="Enter the name of the project"
              data-testing-id="profile-project-card-title-text-input"
            />
          )}
        />
        <ErrorForm field="title" errors={errors} />
      </div>
      <div className={styles.header}>
        <div className={styles.companyNameWrapper}>
          <div
            className={cx([
              {
                [styles.linkedCompanyError]:
                  !!errors['companyName'] && !companyInputHasFocus,
              },
            ])}
          >
            <label className={styles.label}>Company</label>
            <div>
              <CompanyV2Picker
                className={styles.companyNameInput}
                variant="borderless"
                initiallySelectedCompanyIds={companyId ? [companyId] : []}
                displayName={
                  selectedProject?.companyName ||
                  selectedProject?.companyData?.name ||
                  undefined
                }
                placeholder="Enter name or website"
                onSelectCompanies={onSelectedCompaniesV2Change}
                error=""
                disabled={!isOwner && !!selectedProject.companyV2Id}
                onlySearchVerified
                canAddCompany
                onChangeProps={{
                  onInputChange: (value) => {
                    setCompanyInputHasFocus(true);
                    setValue('companyName', value);
                    trigger('companyName');
                  },
                  onBlur: () => {
                    setCompanyInputHasFocus(false);
                  },
                }}
              />
            </div>
          </div>
          {!companyInputHasFocus && (
            <ErrorForm field="companyName" errors={errors} />
          )}
        </div>
        <div data-testing-id="profile-project-card-industry-select">
          {auth.withMultipleIndustriesForExperiences ? (
            <>
              <label className={styles.label}>Project industry</label>
              <Select
                className={styles.industriesSelect}
                styles={{
                  placeholder: (base) => ({
                    ...base,
                    margin: 0,
                    fontSize: 15,
                    color: `${TextColors.lighter} !important`,
                    '& > span': {
                      backgroundColor: `${Colors.secondaryDark} !important`,
                    },
                  }),
                  menu: (base) => ({
                    ...base,
                    width: 'fit-content',
                  }),
                  control: (base) => ({
                    ...base,
                    height: '21px',
                    minHeight: '21px',
                    border: 'none',
                    backgroundColor: 'white',
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    height: '21px',
                    minHeight: '24px',
                    padding: '0px',
                  }),
                  input: (base) => ({
                    ...base,
                    margin: '0px',
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  indicatorsContainer: (base) => ({
                    ...base,
                    height: '21px',
                  }),
                }}
                placeholder="Industry"
                value={industries}
                onChange={(data) => {
                  setValue(
                    'industries',
                    (data as SelectOption[]).map((d) => d.value),
                  );
                  trigger('industries');
                }}
                isMulti
                options={
                  allIndustries?.map((industry) => {
                    return { label: industry.name, value: industry.id };
                  }) || []
                }
                isOptionDisabled={(option) => {
                  if (!industries) {
                    return false;
                  }

                  if (industries.length < 2) {
                    return false;
                  }

                  if (
                    industries.find(
                      (i) => i.value === (option as SelectOption).value,
                    )
                  ) {
                    return false;
                  }

                  return true;
                }}
                isDisabled={!isOwner && !!selectedProject.industries?.length}
              />
              <ErrorForm field="industries" errors={errors} />
            </>
          ) : (
            <>
              <label className={styles.label}>Industry</label>
              <Select
                className={styles.industrySelect}
                styles={{
                  placeholder: (base) => ({
                    ...base,
                    color: '#818388',
                    margin: 0,
                    fontSize: 15,
                  }),
                  menu: (base) => ({
                    ...base,
                    width: 'fit-content',
                  }),
                  control: (base) => ({
                    ...base,
                    height: '21px',
                    minHeight: '21px',
                    border: 'none',
                    backgroundColor: 'white',
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    height: '21px',
                    minHeight: '21px',
                    padding: '0px',
                  }),
                  input: (base) => ({
                    ...base,
                    margin: '0px',
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  indicatorsContainer: (base) => ({
                    ...base,
                    height: '21px',
                  }),
                }}
                placeholder="Select"
                value={industry}
                onChange={(data) => {
                  setValue('industry', (data as SelectOption).value);
                  trigger('industry');
                }}
                options={
                  allIndustries?.map((industry) => {
                    return { label: industry.name, value: industry.id };
                  }) || []
                }
                isDisabled={!isOwner && !!selectedProject.industry}
              />
              <ErrorForm field="industry" errors={errors} />
            </>
          )}
        </div>
        <div>
          <label className={styles.label}>Project Link (Optional)</label>
          <Controller
            name="projectUrl"
            control={control}
            rules={{
              validate: (value) => {
                if (value && !isValidUrl(value)) {
                  return 'Invalid URL';
                }

                return true;
              },
            }}
            render={({ field }) => (
              <div className={styles.inputWrapper}>
                <Input
                  {...field}
                  className={cx(
                    styles.borderlessInput,
                    styles.inputWithIcon,
                    styles.purpleIsImportant,
                  )}
                  variant="borderless"
                  placeholder="Enter link"
                  icon={
                    <Icon
                      size="md"
                      name="link"
                      color={field.value ? 'Hannibal@600' : 'inherit'}
                      style={{
                        position: 'absolute',
                        top: '3px',
                        left: 0,
                      }}
                    />
                  }
                  onBlur={(e) => {
                    setValue('projectUrl', addHttps(e.target.value));
                    trigger('projectUrl');
                  }}
                  data-testing-id="profile-project-card-url-text-input"
                  disabled={!isOwner && !!selectedProject.projectUrl}
                />
              </div>
            )}
          />
          <ErrorForm field="projectUrl" errors={errors} />
        </div>
      </div>
    </div>
  );
};

export default observer(Header);
