import React from 'react';
import { createUseStyles } from 'react-jss';
import { BorderColors, FontWeights } from '@ateams/components';
import { format } from 'date-fns';
import UserAvatar from '@src/components/UserAvatar';
import { DateISOString } from '@a_team/models/dist/misc';

const useStyles = createUseStyles({
  builderRow: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: 24,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    border: `1px solid ${BorderColors.lighter}`,
  },
  builderMeta: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  builderInfo: {
    fontWeight: FontWeights.semiBold,
  },
  builderRates: {
    marginTop: 8,
  },
  builderCurrentRate: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',

    '& > span': {
      display: 'inline-block',
      minWidth: 200,
    },

    '& > strong': {
      fontWeight: FontWeights.semiBold,
    },
  },
});

interface BuilderProps {
  avatarUrl: string;
  fullName: string;
  proposedAt: DateISOString;
  hourlyRate: number;
  monthlyRate: number;
}

export const BuilderRow: React.FC<BuilderProps> = (props: BuilderProps) => {
  const styles = useStyles();

  const { avatarUrl, fullName, proposedAt, hourlyRate } = props;

  return (
    <div className={styles.builderRow}>
      <div className={styles.builderMeta}>
        <UserAvatar src={avatarUrl} size={24} />
        <div className={styles.builderInfo}>
          {fullName} most recently proposed for this role on{' '}
          {format(new Date(proposedAt), 'MMM dd, yyyy')}
        </div>
      </div>
      <div className={styles.builderRates}>
        {props.hourlyRate > 0 && (
          <div className={styles.builderCurrentRate}>
            <span>
              <strong>Builder proposed hourly rate:</strong>
            </span>
            <span>${hourlyRate} per hour</span>
          </div>
        )}
        {props.monthlyRate > 0 && (
          <div className={styles.builderCurrentRate}>
            <span>
              <strong>Builder proposed monthly rate:</strong>
            </span>
            <span>${props.monthlyRate.toLocaleString()} per month</span>
          </div>
        )}
      </div>
    </div>
  );
};
