import { MissionRoleId } from '@a_team/models/dist/MissionRole';
import LoadingIndicator from '@src/components/LoadingIndicator';
import TextButton from '@src/components/TextButton';
import { REGISTRATION_BASE_URL } from '@src/config';
import useLoadingState from '@src/hooks/useLoadingState';
import copy from 'copy-to-clipboard';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface CopySourcedBuilderInviteUrlProps {
  roleId: MissionRoleId;
}

const useStyles = createUseStyles({
  container: {},
});

const CopySourcedBuilderInviteUrl = ({
  roleId,
}: CopySourcedBuilderInviteUrlProps) => {
  const styles = useStyles();
  const [loading, setLoading] = useLoadingState();

  const onClick = () => {
    const url = `${REGISTRATION_BASE_URL}?utm_role=${roleId}`;
    copy(url);

    setLoading(new Promise((resolve) => resolve('URL copied to clipboard')));
  };

  return (
    <>
      <TextButton
        onClick={onClick}
        className={styles.container}
        color="primary"
      >
        Copy sourced builder invite URL
      </TextButton>
      <LoadingIndicator loading={loading} />
    </>
  );
};

export default CopySourcedBuilderInviteUrl;
