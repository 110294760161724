import { DateISOString } from './misc';
import { MissionId } from './MissionObject';
import { MissionApplicationId } from './MissionApplicationObject';
import RoleCategoryObject, { RoleCategoryId } from './RoleCategory';
import { UserCardObject, UserId } from './UserObject';
import { MissionRoleId } from './MissionRole';
import { TalentSkill } from './TalentCategories';

export type ProposalId = string;

export interface ProposalBasicObject {
  pid: ProposalId;
  slug?: string;
  name?: string;
  webflowId?: string;
}

export enum TeamNarrativeType {
  COMPANY = 'company',
  PROJECT = 'project',
  MISSION = 'mission',
  INVITATION = 'invitation',
  STARRED = 'starred',
  TEAM_UP = 'teamUp',
}

export interface TeamNarrative {
  type: TeamNarrativeType;
  text: string;
  builderUids: string[];
}

export enum ProposalDataCurrency {
  USD = 'USD',
  EUR = 'EUR',
}

export interface ProposalData {
  missionSpecId: string;
  mid: MissionId;
  candidates: ProposalCandidate[];
  teamNarratives?: TeamNarrative[];
  teamBlurb?: string;
  currency?: ProposalDataCurrency;
}

export interface ProposalUpdateData {
  isShared?: boolean;
}

export enum ProposalCandidateRecommendation {
  Recommended = 'Recommended',
  Alternative = 'Alternative',
}

export interface ProposalTfsPitch {
  blurb?: string;
  website?: string;
}

export interface ProposalCandidate {
  rid: MissionRoleId;
  aid: MissionApplicationId;
  userId?: UserCardObject['uid'];
  recommendation?: ProposalCandidateRecommendation;
  hourlyRate?: number;
  isTeamLead?: boolean;
  roleCategoryId?: RoleCategoryId;
  tfsPitch?: ProposalTfsPitch;
  includeCustomQuestionReply?: boolean;
  githubUrl?: string;
  cvUrl?: string;
  portfolioUrl?: string;
  portfolioPassword?: string;
  monthlyRate?: number;
  showMonthlyRate?: boolean;
  showHourlyRate?: boolean;
  gptUsageLogId?: string;
  reviews?: string[];
  linkedInRecommendations?: string[];

  // more fields we can use later to overwrite
  // rid: MissionRoleId;
  // fullName: string;
  // profilePictureURL: string;
  // summary: MissionApplicationObject['summary'];
  // address: MissionApplicationObject['address'];
  // jobExperiences: MissionApplicationObject['jobExperiences'];
  // projectExperiences: MissionApplicationObject['projectExperiences'];
  // expertiseTags: MissionApplicationObject['expertiseTags'];
}

export interface ProposalObject extends ProposalBasicObject, ProposalData {
  publicURL: string;
  createdAt?: DateISOString;
}

export interface SampleProposalRole {
  title: string;
  roleCategory?: RoleCategoryObject;
  builders: Array<
    UserCardObject & {
      hourlyRate?: number;
      blurb?: string;
      skills: TalentSkill['name'][];
      roleCategory?: RoleCategoryObject | null;
    }
  >;
}

export interface SampleProposalObject extends ProposalBasicObject {
  clientAppURL?: string;
  createdAt: DateISOString;
  name: string;
  publicURL: string;
  roles: SampleProposalRole[];
  slug?: string;
  webflowId?: string;
  hubspotId?: string;
}

/**
 * Information about a `tfsPitch` and its parent proposal context.
 *
 * _We need these `BuilderTfsPitchDescriptor`s when we want to show history of blurbs / tfsPitches_
 */
export interface BuilderTfsPitchDescriptor {
  createdAt: DateISOString;
  tfsPitch: ProposalTfsPitch;
  hourlyRate?: number;
  role: { rid: string; title: string };
  uid: UserId;
  proposalId: ProposalId;
}

export enum ProposalTemplate {
  'SampleV2' = 'sampleV2',
  'SampleV3' = 'sampleV3',
}

export interface ProposalTemplateMap {
  companyName?: string;
  hideRates?: boolean;
}
