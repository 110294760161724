import { DateISOString } from '../misc';
import {
  TalentSkill,
  TalentSkillId,
  TalentSpecialization,
} from '../TalentCategories';
import RoleCategoryObject from '../RoleCategory';
import { AdminNotesScore } from '../AdminNotesObject';
import { UserBadge, UserId } from '../UserObject';
import { CompanySize, ManagementExperience } from './pre-vetting';
import LocationObject from '../LocationObject';
import { AvailableType } from '../AvailabilityObject';
import { LocalHourRange } from '../WorkingHoursObject';
import { TalentIndustry, TalentIndustryId } from '../TalentIndustry';
import { VettingProcessFeedbackAdditionalMaterial } from './feedback-additional-material';

export interface VettingProcessFeedbackScores {
  expertise?: AdminNotesScore;
  interactionExperience?: AdminNotesScore;
  englishLevel?: AdminNotesScore;
  accent?: AdminNotesScore;
}

export interface VettingProcessFeedbackRole {
  role: VettingProcessFeedbackRoles;
  tags: string[];
}

export enum VettingProcessFeedbackRoles {
  FullStackDeveloper = 'FullStackDeveloper',
  BackEnd = 'BackEndDeveloper',
  FrontEnd = 'FrontEndDeveloper',
  DevOps = 'Devops',
  QAEngineer = 'QaEngineer',
  DataScientist = 'DataScientist',
  SoftwareArchitect = 'SoftwareArchitect',
  SoftwareEngineer = 'SoftwareEngineer',
  MobileDeveloper = 'MobileDeveloper',
  IndividualContributor = 'IndividualContributor',
  TeamLead = 'TeamLead',
  CXOLevel = 'CxoLevel',
  HandsOnContributor = 'HandsOnContributor',
  ConsultantOrStrategist = 'ConsultantOrStrategist',
  TechnicalProductManager = 'TechnicalProductManager',
  DesignProductManager = 'DesignProductManager',
  StrategyProductManager = 'StrategyProductManager',
  GrowthProductManager = 'GrowthProductManager',
  ProductMarketingManager = 'ProductMarketingManager',
  MarketingProductManager = 'MarketingProductManager',
  ProjectManager = 'ProjectManager',
  ProductOwner = 'ProductOwner',
  ArtDirector = 'ArtDirector',
  BrandDesigner = 'BrandDesigner',
  GameDesigner = 'GameDesigner',
  GraphicsDesigner = 'GraphicsDesigner',
  IndustrialDesigner = 'IndustrialDesigner',
  MotionDesigner = 'MotionDesigner',
  ProductDesigner = 'ProductDesigner',
  UXDesigner = 'UxDesigner',
  UXResearcher = 'UxResearcher',
  PPC = 'PPC',
  SEO = 'SEO',
  GrowthHacker = 'GrowthHacker',
  Strategy = 'Strategy',
  ContentCreation = 'ContentCreation',
  EmailMarketing = 'EmailMarketing',
  SocialMarketing = 'SocialMarketing',
  Branding = 'Branding',
  PerformanceMarketing = 'PerformanceMarketing',
  Testing = 'Testing',
  CMO = 'CMO',
}

export const VettingProcessFeedbackRoleTags = {
  [VettingProcessFeedbackRoles.FullStackDeveloper]: {
    BackEndOriented: 'BackEndOriented',
    FrontEndOriented: 'FrontEndOriented',
    DevOpsOriented: 'DevopsOriented',
    UIUXOriented: 'UIUXOriented',
  },
  [VettingProcessFeedbackRoles.QAEngineer]: {
    Manual: 'Manual',
    Automation: 'Automation',
  },
  [VettingProcessFeedbackRoles.DataScientist]: {
    DataAnalyst: 'DataAnalyst',
    DataEngineer: 'DataEngineer',
    DataScientist: 'DataScientist',
  },
  [VettingProcessFeedbackRoles.MobileDeveloper]: {
    Android: 'Android',
    IOS: 'IOS',
    ReactNativeOrOther: 'ReactNativeOrOther',
  },
};

export type VettingProcessFeedbackCompanySizes = {
  companySize: CompanySize;
  description: string;
}[];

export interface VettingProcessFeedbackAcquiredOrProgressed {
  hasBeenAcquiredOrProgressed: boolean;
  description?: string;
}

export interface VettingProcessFeedbackBuiltProductsFromScratch {
  hasBuiltProductsFromScratch: boolean;
  areTheyStillUsed?: boolean;
  wasSignificantOnProductFromScratchBuildingProcess?: boolean;
  wasSignificantOnProductFromScratchBuildingProcessDescription?: string;
}

export interface VettingProccessFeedbackScaledProducts {
  hasWorkedOnProductThatScaled: boolean;
  description?: string;
}

export interface VettingProcessFeedbackPeopleManaged {
  managementExperience: ManagementExperience;
  description?: string;
}

export interface VettingProcessFeedbackTechnicalBackground {
  hasTechnicalBackground: boolean;
  rolesDescription?: string;
}

export enum WorkElseWhere {
  FullTime = 'FullTime',
  PartTime = 'PartTime',
  No = 'No',
}

export interface VettingProcessFeedbackProject {
  companyName?: string;
  impactOrRole?: string;
  role?: TalentSpecialization;
  description?: string;
  skills: TalentSkill[];
  industries: TalentIndustry[];
}

export interface VettingProcessFeedbackSkill {
  skill: TalentSkillId;
  level?: number;
  comment?: string;
}

export interface VettingProcessFeedbackTalentSkill {
  skill: TalentSkill;
  level?: number;
  comment?: string;
}

export interface VettingProcessFeedbackIndustries {
  talentIndustries: TalentIndustryId[];
  comment?: string;
}

export interface VettingProcessFeedbackTalentIndustries {
  talentIndustries: TalentIndustry[];
  comment?: string;
}

export interface VettingProcessFeedbackComment {
  id: string;
  user: {
    uid: UserId;
    fullName: string;
    profilePictureURL: string;
    profileURL: string;
  };
  comment: string;
  createdAt?: DateISOString;
}

export interface VettingProcessFeedback {
  answers: {
    location?: LocationObject;
    worksElseWhere?: WorkElseWhere;
    availability: {
      availability?: {
        type: AvailableType;
        availableFrom?: DateISOString;
        weeklyHoursAvailable?: number;
      };
      workingHours?: Partial<LocalHourRange>;
      hoursOfOverlapWithEST?: number;
      note?: string;
    };
    callRecording?: string;
    additionalMaterialsFiles?: VettingProcessFeedbackAdditionalMaterial[];
    additionalMaterials?: string;
    scores: VettingProcessFeedbackScores;
    badge?: UserBadge;
    roles: VettingProcessFeedbackRole[];
    userRole?: RoleCategoryObject;
    experience: {
      companySizes: VettingProcessFeedbackCompanySizes;
      acquiredOrProgressed?: VettingProcessFeedbackAcquiredOrProgressed;
      builtProductsFromScratch?: VettingProcessFeedbackBuiltProductsFromScratch;
      workedOnProductThatScaled?: VettingProccessFeedbackScaledProducts;
      peopleManaged?: VettingProcessFeedbackPeopleManaged;
      teamSize?: string;
      technicalBackground?: VettingProcessFeedbackTechnicalBackground;
    };
    projects: VettingProcessFeedbackProject[];
    biggestImpactDescription?: string;
    skills: VettingProcessFeedbackTalentSkill[];
    skillsComment?: string;
    industries: VettingProcessFeedbackTalentIndustries;
    backgroundComments: VettingProcessFeedbackComment[];
    generalComments: VettingProcessFeedbackComment[];
    summaryComments: VettingProcessFeedbackComment[];
  };
  isDraft?: boolean;
  submitDate?: DateISOString;
  lastSubmitDate?: DateISOString;
  lastSaveDate?: DateISOString;
  feedbackAccepted?: boolean;
}
