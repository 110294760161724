import { round } from 'lodash';

export const toClientRate = (
  builderRate?: number,
  margin?: number,
  missionRoleMargin = 0,
) => {
  let roleMargin = margin && margin > 0 ? 1 + margin / 100 : 1;

  if (roleMargin === 1 && missionRoleMargin > 0) {
    roleMargin = 1 + missionRoleMargin;
  }

  return builderRate ? round(builderRate * roleMargin, 2) : 0;
};

export const toBuilderRate = (
  clientRate?: number,
  margin?: number,
  missionRoleMargin = 0,
) => {
  let roleMargin = margin && margin > 0 ? 1 + margin / 100 : 1;

  if (roleMargin === 1 && missionRoleMargin > 0) {
    roleMargin = 1 + missionRoleMargin;
  }

  return clientRate ? round(clientRate / roleMargin, 2) : 0;
};
