import { ServiceAuth, ServiceEndpoint } from './utils';
import { UserReviewObject } from '@a_team/models/dist/UserReviewObject';
import { LinkedinRecommendationsObject } from '@a_team/models/dist/LinkedinRecommendationsObject';

// Define the UserReviewWithSource type directly in this file since we can't easily import from the API service
export type ATeamReview = UserReviewObject & {
  source: 'ateam';
};

export type LinkedInReview = LinkedinRecommendationsObject & {
  source: 'linkedin';
};

export type UserReviewWithSource = ATeamReview | LinkedInReview;

export const BasePath = '/user-reviews';

export default class UserReviewsEndpoint extends ServiceEndpoint {
  public getUserReviews(
    auth: ServiceAuth,
    params: {
      uid: string;
      includeAllReviews?: boolean;
      includeLinkedInRecommendations?: boolean;
    },
  ): Promise<UserReviewWithSource[]> {
    return this.fetch(auth, `${BasePath}/${params.uid}`, {
      includeAllReviews: params.includeAllReviews,
      includeLinkedInRecommendations: params.includeLinkedInRecommendations,
    });
  }

  public changeUserReviewVisibility(
    auth: ServiceAuth,
    reviewId: string,
    visible: boolean,
  ): Promise<{ success: boolean }> {
    return this.fetch(auth, `${BasePath}/${reviewId}/visibility`, null, 'put', {
      visible,
    });
  }

  public changeLinkedInRecommendationVisibility(
    auth: ServiceAuth,
    recommendationId: string,
    visible: boolean,
  ): Promise<{ success: boolean }> {
    return this.fetch(
      auth,
      `${BasePath}/linkedin-recommendations/${recommendationId}/visibility`,
      null,
      'put',
      {
        visible,
      },
    );
  }

  public deleteLinkedInRecommendation(
    auth: ServiceAuth,
    recommendationId: string,
  ): Promise<{ success: boolean }> {
    return this.fetch(
      auth,
      `${BasePath}/linkedin-recommendations/${recommendationId}`,
      null,
      'delete',
    );
  }
}
