import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { Checkbox } from '@ateams/components';
import { ScrubQueryParameters } from '@ateams/api/dist/endpoints/TeamGraph';

interface Props {
  disabled?: boolean;
  scrubCriteria: ScrubQueryParameters;
  onChange: (scrubCriteria: ScrubQueryParameters) => void;
}

const useStyles = createUseStyles({
  checkbox: {
    marginTop: '0.5em',
  },
});

const ScrubbingChecklist = (props: Props): ReactElement => {
  const styles = useStyles();
  const { scrubCriteria, disabled, onChange } = props;
  const {
    includeExceptional,
    includeVerified,
    includeInsufficient,
    includeLegacyUnknown,
    includeUnknown,
  } = scrubCriteria ?? {};

  const handleCheck = (scrubCriteria: ScrubQueryParameters) => {
    onChange(scrubCriteria);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Checkbox
        className={styles.checkbox}
        disabled={disabled}
        onChange={(e) =>
          handleCheck({
            includeExceptional: e.target.checked ? undefined : e.target.checked,
          })
        }
        checked={includeExceptional ?? true}
        label="Exceptional"
        margin="none"
      />

      <Checkbox
        className={styles.checkbox}
        disabled={disabled}
        onChange={(e) =>
          handleCheck({
            includeVerified: e.target.checked ? undefined : e.target.checked,
          })
        }
        checked={includeVerified ?? true}
        label="Verified"
        margin="none"
      />

      <Checkbox
        className={styles.checkbox}
        disabled={disabled}
        onChange={(e) =>
          handleCheck({
            includeLegacyUnknown: e.target.checked
              ? undefined
              : e.target.checked,
          })
        }
        checked={includeLegacyUnknown ?? true}
        label="Legacy Unknown"
        margin="none"
      />

      <Checkbox
        className={styles.checkbox}
        disabled={disabled}
        onChange={(e) =>
          handleCheck({
            includeUnknown: e.target.checked ? e.target.checked : undefined,
          })
        }
        checked={includeUnknown}
        label="Unknown"
        margin="none"
      />

      <Checkbox
        className={styles.checkbox}
        disabled={disabled}
        onChange={(e) =>
          handleCheck({
            includeInsufficient: e.target.checked
              ? e.target.checked
              : undefined,
          })
        }
        checked={includeInsufficient}
        label="Insufficient"
        margin="none"
      />
    </div>
  );
};

export default ScrubbingChecklist;
