import { Button, Modal } from '@a_team/ui-components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import { useUpdateNotInterestedMissionMutation } from '@src/rq/missions';
import { useStores } from '@src/stores';
import { queryKeys } from '@src/rq/keys';
import { useQueryClient } from '@tanstack/react-query';

interface NotInterestedModalProps {
  open: boolean;
  onClose: () => void;
  clientName: string;
  missionId: string;
}

const useStyles = createUseStyles({
  modal: {
    padding: 24,
    '& > div': {
      padding: 0,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginTop: 0,
    marginBottom: 8,
    textAlign: 'center',
  },
  desc: {
    color: '#818388',
    marginTop: 0,
    marginBottom: 40,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12,

    '& > button': {
      borderRadius: 8,
      padding: '8px 16px',
      fontSize: 14,
      height: 40,
    },
    '& > button:first-child': {
      width: 100,
    },
    '& > button:last-child': {
      width: 140,
    },
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    modal: {
      width: '680px !important',
      padding: 40,
      '& > div': {
        padding: 0,
      },
    },
  },
});

const NotInterestedModal = ({
  open,
  onClose,
  clientName,
  missionId,
}: NotInterestedModalProps): JSX.Element => {
  const styles = useStyles();
  const { mutate: updateNotInterestedMission, isLoading } =
    useUpdateNotInterestedMissionMutation();

  const queryClient = useQueryClient();
  const { auth } = useStores();

  const handleConfirm = () => {
    updateNotInterestedMission(
      {
        missionId,
        reason: 'Not interested on onboarding',
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: queryKeys.missions.builderTypeAndMissionData(
              auth.uid ?? '',
            ),
          });
          onClose();
        },
      },
    );
  };

  return (
    <Modal
      shouldHideGradientStroke
      className={styles.modal}
      variant="slideUp"
      isOpen={open}
      onClose={onClose}
    >
      <div className={styles.container}>
        <h3 className={styles.title}>
          Not interested in the {clientName} project?
        </h3>
        <div className={styles.desc}>
          <span>
            Our team thought you'd be a good match for this role and fast
            tracked your profile.
          </span>
          <span>
            If you're not interested, you can discover other opportunities by
            completing the A.Team onboarding process.
          </span>
        </div>
        <div className={styles.buttonsWrapper}>
          <Button disabled={isLoading} onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            loading={isLoading}
            onClick={handleConfirm}
          >
            Not interested
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NotInterestedModal;
