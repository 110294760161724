import { useStores } from '@src/stores';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiUserReviews } from '@ateams/api';
import { queryKeys } from './keys';
import { UserReviewWithSource } from '@ateams/api/dist/endpoints/UserReviews';

export const useQueryUserReviews = ({
  uid,
  includeAllReviews = false,
  includeLinkedInRecommendations = false,
  onSuccess,
  enabled,
}: {
  uid: string;
  includeAllReviews?: boolean;
  includeLinkedInRecommendations?: boolean;
  onSuccess?: (data: UserReviewWithSource[]) => void;
  enabled?: boolean;
}) => {
  const { auth } = useStores();
  const isEnabled = enabled || auth.onboardingCompleted;

  return useQuery({
    queryKey: queryKeys.userReviews.list(uid, includeAllReviews),
    queryFn: async () => {
      return await apiUserReviews.getUserReviews(auth, {
        uid,
        includeAllReviews,
        includeLinkedInRecommendations,
      });
    },
    onSuccess,
    enabled: isEnabled,
  });
};

export const useMutationChangeUserReviewVisibility = ({
  uid,
  includeAllReviews,
}: {
  uid: string;
  includeAllReviews: boolean;
}) => {
  const { auth } = useStores();
  const queryClient = useQueryClient();

  return useMutation(
    async (args: { reviewId: string; visible: boolean }) => {
      return await apiUserReviews.changeUserReviewVisibility(
        auth,
        args.reviewId,
        args.visible,
      );
    },
    {
      onMutate: async ({ reviewId, visible }) => {
        // Cancel any outgoing refetches so they don't overwrite our optimistic update
        await queryClient.cancelQueries(
          queryKeys.userReviews.list(uid, includeAllReviews),
        );

        // Snapshot the previous value
        const previousReviews = queryClient.getQueryData<
          UserReviewWithSource[]
        >(queryKeys.userReviews.list(uid, includeAllReviews));

        // Optimistically update to the new value
        queryClient.setQueryData(
          queryKeys.userReviews.list(uid, includeAllReviews),
          (old: UserReviewWithSource[] = []) =>
            old.map((review) =>
              review.id === reviewId ? { ...review, visible } : review,
            ),
        );

        // Return a context object with the snapshotted value
        return { previousReviews };
      },
      onError: (err, newReview, context) => {
        // If the mutation fails, use the context returned from onMutate to roll back
        const previousReviews = context as {
          previousReviews?: UserReviewWithSource[];
        };

        queryClient.setQueryData(
          queryKeys.userReviews.list(uid, includeAllReviews),
          previousReviews,
        );
      },
      onSettled: () => {
        // Always refetch after error or success to ensure we have the correct data
        queryClient.invalidateQueries(
          queryKeys.userReviews.list(uid, includeAllReviews),
        );
      },
    },
  );
};

export const useMutationChangeLinkedInRecommendationVisibility = ({
  uid,
  includeAllReviews,
}: {
  uid: string;
  includeAllReviews: boolean;
}) => {
  const { auth } = useStores();
  const queryClient = useQueryClient();

  return useMutation(
    async (args: { recommendationId: string; visible: boolean }) => {
      return await apiUserReviews.changeLinkedInRecommendationVisibility(
        auth,
        args.recommendationId,
        args.visible,
      );
    },
    {
      onMutate: async ({ recommendationId, visible }) => {
        // Cancel any outgoing refetches so they don't overwrite our optimistic update
        await queryClient.cancelQueries(
          queryKeys.userReviews.list(uid, includeAllReviews),
        );

        // Snapshot the previous value
        const previousReviews = queryClient.getQueryData<
          UserReviewWithSource[]
        >(queryKeys.userReviews.list(uid, includeAllReviews));

        // Optimistically update to the new value
        queryClient.setQueryData(
          queryKeys.userReviews.list(uid, includeAllReviews),
          (old: UserReviewWithSource[] = []) =>
            old.map((review) =>
              review.source === 'linkedin' && review.id === recommendationId
                ? { ...review, visible }
                : review,
            ),
        );

        // Return a context object with the snapshotted value
        return { previousReviews };
      },
      onError: (err, newReview, context) => {
        // If the mutation fails, use the context returned from onMutate to roll back
        const previousReviews = context as {
          previousReviews?: UserReviewWithSource[];
        };

        queryClient.setQueryData(
          queryKeys.userReviews.list(uid, includeAllReviews),
          previousReviews,
        );
      },
      onSettled: () => {
        // Always refetch after error or success to ensure we have the correct data
        queryClient.invalidateQueries(
          queryKeys.userReviews.list(uid, includeAllReviews),
        );
      },
    },
  );
};

export const useMutationDeleteLinkedInRecommendation = ({
  uid,
  includeAllReviews,
}: {
  uid: string;
  includeAllReviews: boolean;
}) => {
  const { auth } = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (args: { recommendationId: string }) => {
      return await apiUserReviews.deleteLinkedInRecommendation(
        auth,
        args.recommendationId,
      );
    },
    onMutate: async ({ recommendationId }) => {
      // Cancel any outgoing refetches so they don't overwrite our optimistic update
      await queryClient.cancelQueries({
        queryKey: queryKeys.userReviews.list(uid, includeAllReviews),
      });

      // Snapshot the previous value
      const previousReviews = queryClient.getQueryData<UserReviewWithSource[]>(
        queryKeys.userReviews.list(uid, includeAllReviews),
      );

      // Optimistically update to the new value by removing the recommendation
      queryClient.setQueryData(
        queryKeys.userReviews.list(uid, includeAllReviews),
        (old: UserReviewWithSource[] = []) =>
          old.filter(
            (review) =>
              !(review.source === 'linkedin' && review.id === recommendationId),
          ),
      );

      // Return a context object with the snapshotted value
      return { previousReviews };
    },
    onError: (err, variables, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      const previousReviews = context as {
        previousReviews?: UserReviewWithSource[];
      };

      queryClient.setQueryData(
        queryKeys.userReviews.list(uid, includeAllReviews),
        previousReviews.previousReviews,
      );
    },
    onSettled: () => {
      // Always refetch after error or success to ensure we have the correct data
      queryClient.invalidateQueries({
        queryKey: queryKeys.userReviews.list(uid, includeAllReviews),
      });
    },
  });
};
