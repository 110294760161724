import {
  ExperienceMemberRole,
  ExperienceUserMember,
} from '@a_team/models/dist/ExperienceObject';
import { CollaboratorStatus, UserStatus } from '@a_team/models/dist/UserObject';
import { WorkingHoursSchema } from '@a_team/models/dist/WorkingHoursObject';
import { PreferencesDtoDisabledCategoriesEnum } from '@a_team/user-notification-service-js-sdk';
import {
  Breakpoints,
  Button,
  IconType,
  NotificationBanner,
} from '@ateams/components';
import LoadingIndicator from '@src/components/LoadingIndicator';
import AllLocationsModal from '@src/components/Modal/AllLocationsModal';
import { FieldEditedData, getFieldsEdited } from '@src/helpers/applications';
import useLoadingState from '@src/hooks/useLoadingState';
import useToggle from '@src/hooks/useToggle';
import { SignInLocation } from '@src/locations';
import { useGetUserCalendar } from '@src/rq/profile';
import { useGetProfileSuggestions } from '@src/rq/profileSuggestions';
import { useStores } from '@src/stores';
import Profile from '@src/stores/Profile/Profile';
import { Expertise } from '@src/stores/Profile/models';
import QualityGuidance from '@src/views/Application/QualityGuidance';
import { AboutMeV2 } from '@src/views/Profile/Main/AboutMeV2';
import AvailabilityBannerV2 from '@src/views/Profile/Main/AvailabilityBannerV2';
import AvailabilityV2 from '@src/views/Profile/Main/AvailabilityV2';
import ClientQuestions from '@src/views/Profile/Main/ClientQuestions';
import HourlyRateRangeV2 from '@src/views/Profile/Main/HourlyRateRangeV2';
import Location from '@src/views/Profile/Main/Location';
import PitchV2 from '@src/views/Profile/Main/PitchV2';
import { Projects } from '@src/views/Profile/Main/Projects';
import Role from '@src/views/Profile/Main/Role';
import { ConfirmRoleModal } from '@src/views/Profile/Main/Role/ConfirmRoleModal';
import StarredBuilderBanner from '@src/views/Profile/Main/StarredBuilderBanner';
import { GuidanceMessage } from '@src/views/Profile/Main/partials/Section/GuidanceMessage';
import { GuidanceTooltip } from '@src/views/Profile/Main/partials/Section/GuidanceTooltip/GuidanceTooltip';
import { TooltipContent } from '@src/views/Profile/Main/partials/Section/TooltipContent';
import RolesModal from '@src/views/Profile/Shared/RolesModal';
import SkillsModal from '@src/views/Profile/Shared/SkillsModal';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useScreenClass } from 'react-grid-system';
import { createUseStyles } from 'react-jss';
import { Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import ApplicationAnalysis from './ApplicationAnalysis';
import { CalendarBanner } from './CalendarBanner';
import ConfirmSubmissionModal from './ConfirmSubmissionModal';
import NoCalendarModal from './NoCalendarModal';
import ProfileCompleteness from './ProfileCompleteness';
import Reasons from './Reasons.tsx';
import RoleSkills from './RoleSkills';
import TeamUp from './TeamUp';
import { WorkingHoursOverlap } from './WorkingHoursOverlap';
import ProfileRates from './ProfileRates';
import MissionRoleRates from './MissionRoleRates';
import Jobs from './Jobs';
import ProfileRatesView from './ProfileRates/ProfileRatesView';
import { skipConnectCalendarToApply } from '@src/config';
import Resume from './Resume';
import MissionRoleRatesV2 from './MissionRoleRatesV2';

interface Props {
  profile?: Profile;
  style?: CSSProperties;
  skipScrollToTop?: boolean;
}

const useStyles = createUseStyles({
  wrapper: {
    padding: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 830,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    wrapper: {
      padding: '0px 0px',
    },
  },
  markAsRead: {
    backgroundColor: '#FFF7B3',
    color: '#222222',
    fontSize: '15px',
    fontWeight: 400,
    padding: '16px',
    borderRadius: '8px',
  },
  markAsReadButton: {
    backgroundColor: '#FFFFFF',
    color: '#222222',
    width: '133px',
    height: '40px',
    borderRadius: '100px',
    fontSize: '15px',
    fontWeight: 400,
    padding: '0px',
  },
  starredBuilderContainer: {
    paddingBottom: 40,
  },
  projectsGuidance: {
    lineHeight: '19px',
    marginTop: 24,
  },
});

const MainApplication = (props: Props) => {
  const screenClass = useScreenClass();
  const [loading, setLoading] = useLoadingState();
  const [loadingCalendar, setLoadingCalendar] = useLoadingState();
  const [rolesModalOpen, toggleRolesModalOpen] = useToggle();
  const [roleConfirmModalOpen, toggleRoleConfirmModalOpen] = useToggle();
  const [skillsModalOpen, toggleSkillsModalOpen] = useToggle();
  const [locationsModalOpen, toggleLocationsModalOpen] = useToggle();
  const [skillsToEdit, setSkillsToEdit] = useState<Expertise[] | undefined>();
  const { users, notificationPreferencesUser, auth } = useStores();
  const { withWorkingHours } = auth;
  const { data: calendar, isLoading: isLoadingCalendar } = useGetUserCalendar();
  const styles = useStyles();
  const { data: profileSuggestions } = useGetProfileSuggestions();
  const suggestedProjects = profileSuggestions?.projects?.projects || [];
  const hasCalendarSetup = useMemo(() => {
    if (isLoadingCalendar) {
      return false;
    }
    if (
      auth.withSharedCalendarFlow &&
      isEmpty(calendar?.calcom?.selectedCalendar)
    ) {
      return calendar?.calcom?.hasAvailability ?? false;
    }
    if (isEmpty(calendar?.calcom?.selectedCalendar)) {
      return false;
    }

    return !isEmpty(calendar?.calcom?.selectedCalendar);
  }, [calendar, isLoadingCalendar]);

  const hasFullySetupCalendar = useMemo(() => {
    return !isEmpty(calendar?.calcom?.selectedCalendar);
  }, [calendar, isLoadingCalendar]);

  useEffect(() => {
    setLoadingCalendar(isLoadingCalendar);
  }, [isLoadingCalendar]);

  const stores = useStores();
  const { missions } = stores;
  const { currentMission } = missions;

  const needsLogin = useMemo(
    () => auth.currentUser?.status === UserStatus.Rejected,
    [auth.currentUser?.status],
  );

  const profile = useMemo(() => {
    return props.profile || users.profile;
  }, [props.profile, users.profile]);

  useEffect(() => {
    profile?.getGeneralTalentCategory();
  }, []);

  // scroll to top on load
  useEffect(() => {
    if (profile?.application && !props.skipScrollToTop) {
      window?.scrollTo(0, 0);
    }
  }, []);

  const currentRole = profile?.application?.currentRole;

  const showAvailabilityBanner = useMemo(() => {
    return profile?.showAvailabilityBanner && screenClass !== 'xs';
  }, [profile?.showAvailabilityBanner, screenClass]);

  useEffect(() => {
    notificationPreferencesUser.fetchNotificationPreferencesData();
  }, []);

  const fieldsEdited = useMemo<FieldEditedData>(
    () =>
      getFieldsEdited(
        missions?.currentApplication?.application?.applicationData,
      ),
    [missions.currentApplication?.application?.applicationData.changes],
  );

  const fieldsEditedCount = useMemo<number>(() => {
    type ObjectKey = keyof typeof fieldsEdited;
    return Object.keys(fieldsEdited).filter(
      (key) => fieldsEdited[key as ObjectKey],
    ).length;
  }, [fieldsEdited]);

  const missionNotificationsDisabled = useMemo(
    () =>
      notificationPreferencesUser.preferences.disabledCategories?.includes(
        PreferencesDtoDisabledCategoriesEnum.MissionNotification,
      ),
    [notificationPreferencesUser.preferences],
  );

  const projects = useMemo(() => {
    if (!profile) {
      return [];
    }

    let displayedProjects = profile?.displayedProjects;

    displayedProjects = displayedProjects.filter((project) => {
      return project.members.find(
        (member) =>
          (member as ExperienceUserMember).uid === profile.data.uid &&
          (member.experienceRole === ExperienceMemberRole.Owner ||
            member.collaboratorStatus === CollaboratorStatus.Active),
      );
    });

    return [...suggestedProjects, ...displayedProjects];
  }, [suggestedProjects, profile?.displayedProjects]);

  if (!profile) {
    return null;
  }

  if (needsLogin) {
    return <Redirect to={SignInLocation} />;
  }

  const onKeepNotificationsChange = (keepNotifications: boolean) => {
    if (keepNotifications) {
      setLoading(
        notificationPreferencesUser.removeDisabledCategory(
          PreferencesDtoDisabledCategoriesEnum.MissionNotification,
        ),
      );
    } else {
      setLoading(
        notificationPreferencesUser.addDisabledCategory(
          PreferencesDtoDisabledCategoriesEnum.MissionNotification,
        ),
      );
    }
  };

  const onAboutChange = (newAbout: string) => {
    profile?.application?.setAbout(newAbout);
    profile?.setAboutMe(newAbout);
  };

  const onEditSkills = (type: 'required' | 'preferred') => {
    setSkillsToEdit(
      type === 'required'
        ? profile.application?.missingSkillsExpertise
        : profile.application?.missingPreferredSkillsExpertise,
    );
    toggleSkillsModalOpen();
  };

  const onMarkAsReadClick = async () => {
    if (currentMission && profile.application?.aid) {
      const res = await currentMission.updateMissionApplicationStatus(
        profile.application?.aid,
        {},
      );
      missions.currentApplication?.application?.setApplicationData(res);
    }
  };

  const renderInfoBanner = () => {
    if (profile.application && !profile.readonly && !hasFullySetupCalendar) {
      return <CalendarBanner />;
    }

    if (profile?.showUnderReviewBanner) {
      if (auth.readonlyAccess) {
        return (
          <NotificationBanner
            title={'Thanks for resubmitting your profile'}
            text={
              <span>
                We’ll review your updated profile and let you know if your
                skills align with the needs of the companies in the A.Team
                network. You won’t be able to edit your profile again until
                we’ve completed our review.
              </span>
            }
          />
        );
      }
      return (
        <>
          {auth.withNewOnboardingBannerWithLongProcessingTimes ? (
            <NotificationBanner
              title={'Your profile is under review'}
              className={styles.notificationBanner}
              text={
                <span>
                  Thanks for requesting to join A.Team! We’re getting a high
                  volume of builder applications right now, so it might take a
                  bit longer than usual to get back to you. Thanks for your
                  patience!
                </span>
              }
            />
          ) : (
            <NotificationBanner
              title={'Your profile is under review'}
              text={
                <span>
                  Thanks for requesting to join A.Team. We review each builder
                  individually so it can take up to few weeks to hear back from
                  us. No further action is required from you but you're free to
                  modify or add to your profile while you wait.
                </span>
              }
            />
          )}
        </>
      );
    }

    if (!isEmpty(profile.scrubbedMinusOneReasons)) {
      return (
        <Reasons scrubbedMinusOneReasons={profile?.scrubbedMinusOneReasons} />
      );
    }

    if (
      profile.isCurrentUser &&
      auth.currentUser?.status === UserStatus.ActiveWaitlisted
    ) {
      return (
        <NotificationBanner
          title={'Priority Waitlist'}
          className={styles.notificationBanner}
          text={
            <span>
              Our team was impressed with your profile, and you've passed the
              profile review. Due to limited demand for your role, we've added
              your profile to the Priority Waitlist. We'll invite you to an
              evaluation call when more missions become available. No further
              action is needed from you at this time.
            </span>
          }
        />
      );
    }

    return null;
  };

  const renderConfirmationModal = () => {
    if (!profile.showConfirmSubmitEdits) {
      return null;
    }

    return (
      <ConfirmSubmissionModal
        open={profile.showConfirmSubmitEdits}
        onClose={profile.saveProfileEditsSubmitLater}
        onConfirm={profile.saveProfileAndSubmit}
      />
    );
  };

  const handleRoleAdd = () => {
    if (profile.application?.isUserMissingRole) {
      toggleRoleConfirmModalOpen();
      return;
    }

    currentRole &&
      profile.removeSingleAdditionalRole(currentRole?.category.cid);
  };

  const addRole = async (): Promise<void> => {
    if (!currentRole?.category) return;

    toggleRoleConfirmModalOpen();

    setLoading(
      profile
        .queryRoles(currentRole?.category.title, false, true)
        .then((res) => {
          const role = res.find(
            (option) => option.id === currentRole?.category.cid,
          );
          role && profile.addSingleAdditionalRole(role);
          if (role && auth.withSaveRolesRightAwayOnApplication) {
            profile.saveRoles();
          }
        }),
      null,
    );
  };

  const renderRateRange = () => {
    if (!profile.application) {
      return null;
    }

    if (auth.withNewRatesOnApplication) {
      return (
        <MissionRoleRatesV2
          profile={profile}
          currentRole={profile.application.currentRole}
          readonly={profile.application.readonly}
          error={profile?.showErrors && profile?.application.hasHourlyRateError}
        />
      );
    }

    if (auth.withMissionMonthlyRates) {
      return (
        <MissionRoleRates
          profile={profile}
          currentRole={profile.application.currentRole}
          readonly={profile.application.readonly}
          error={profile?.showErrors && profile?.application.hasHourlyRateError}
        />
      );
    }

    return (
      <HourlyRateRangeV2
        type={'large'}
        minRange={profile?.application.minHourlyRate}
        maxRange={profile?.application.maxHourlyRate}
        builderRateMin={currentRole?.builderRateMin}
        builderRateMax={currentRole?.builderRateMax}
        showRateRangeToBuilders={currentRole?.showRateRangeToBuilders}
        onChange={(range) => {
          profile.application?.setMinHourlyRate(range.min || 0);
          profile.application?.setMaxHourlyRate(range.max || 0);
        }}
        error={profile?.showErrors && profile?.application.hasHourlyRateError}
        guidanceMessage={
          profile.isCurrentUser ? (
            auth.withApplicationBuilderScoring ? (
              profile.application.showHourlyRateGuidanceMessage ? (
                <GuidanceMessage
                  text={profile.application.guidanceMessages.hourlyRateInvalid}
                  type={'secondary'}
                  iconType={IconType.StarsPurple}
                />
              ) : undefined
            ) : (
              <GuidanceMessage
                text={profile.application.guidanceMessages.hourlyRate}
                type={'secondary'}
                error
              />
            )
          ) : undefined
        }
        guidanceTooltip={
          !profile.application.readonly ? (
            <GuidanceTooltip label={TooltipContent.rate.label}>
              {TooltipContent.rate.component}
            </GuidanceTooltip>
          ) : undefined
        }
        readOnly={profile?.application.readonly}
        isApplicationEdited={fieldsEdited.rateEdited}
        lastHourlyRateRange={
          profile?.application?.applicationData?.changes?.lastHourlyRateRange
        }
      />
    );
  };

  if (!profile.readonly && loadingCalendar !== false) {
    return <LoadingIndicator loading={loadingCalendar} successMsg="Done" />;
  }

  if (
    !hasCalendarSetup &&
    profile.application &&
    profile.application.mid &&
    !profile.application.readonly &&
    !skipConnectCalendarToApply
  ) {
    return <NoCalendarModal missionId={profile.application.mid} />;
  }

  return (
    <div
      className={`${styles.wrapper}`}
      style={{
        ...props.style,
        pointerEvents:
          profile.application && missions.isViewModeOnly ? 'none' : 'auto',
      }}
    >
      {renderConfirmationModal()}
      <RolesModal
        profile={profile}
        onClose={toggleRolesModalOpen}
        open={rolesModalOpen}
      />
      <ConfirmRoleModal
        role={currentRole?.category.title}
        open={roleConfirmModalOpen}
        onClose={toggleRoleConfirmModalOpen}
        onConfirm={addRole}
      />
      <SkillsModal
        saveRightAway
        profile={profile}
        onClose={() => {
          toggleSkillsModalOpen();
          setSkillsToEdit(undefined);
        }}
        open={skillsModalOpen}
        skills={skillsToEdit}
      />
      <AllLocationsModal
        open={locationsModalOpen}
        role={currentRole}
        userCountry={[
          profile.countryRawObject,
          !!profile.application?.userLocationInRoleReqs,
        ]}
        onClose={toggleLocationsModalOpen}
      />
      <LoadingIndicator loading={loading} />
      {auth.isAdmin &&
        profile.application?.aid &&
        auth.withMissionApplicationAnalysis && (
          <ApplicationAnalysis applicationId={profile.application.aid} />
        )}
      {renderInfoBanner()}
      {fieldsEditedCount > 0 && (
        <NotificationBanner
          text={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                Builder made {fieldsEditedCount} updates to their application
              </div>
              <Button
                size="small"
                className={styles.markAsReadButton}
                onClick={() => onMarkAsReadClick()}
              >
                Mark as read
              </Button>
            </div>
          }
          className={styles.markAsRead}
        />
      )}
      {auth.withProfileCompleteness &&
        profile.isCurrentUser &&
        !profile.application &&
        auth.basicAccess && (
          <ProfileCompleteness
            hasCalendarSetup={hasCalendarSetup}
            profile={profile}
            auth={auth}
          />
        )}
      {showAvailabilityBanner && profile?.availabilityType && (
        <AvailabilityBannerV2
          type={profile?.availabilityType}
          fromDate={profile.availabilityFromDate}
          hours={profile?.availabilityHours}
          lastUpdate={profile?.lastAvailabilityUpdate}
          notes={profile?.availabilityNotes}
          className={styles.availabilityBanner}
        />
      )}
      {profile?.application?.data.starred && (
        <div className={styles.starredBuilderContainer}>
          <StarredBuilderBanner />
        </div>
      )}
      {profile.application && (
        <>
          {auth.withTeamUp && (
            <TeamUp
              application={profile.application}
              guidanceMessage={
                profile?.showErrors &&
                profile.isCurrentUser &&
                profile.application.hasTeamUpRequestResponsesError ? (
                  <GuidanceMessage
                    text={
                      profile.application.guidanceMessages
                        .teamUpRequestResponses
                    }
                    error
                  />
                ) : undefined
              }
              guidanceTooltip={
                !profile.application.readonly ? (
                  <GuidanceTooltip label={TooltipContent.teamUp.label}>
                    {TooltipContent.teamUp.component}
                  </GuidanceTooltip>
                ) : undefined
              }
            />
          )}

          {auth.withResumeOnApplication !== undefined &&
            auth.withResumeOnApplication && <Resume profile={profile} />}

          {profile.application.showRole && (
            <Role
              type={'large'}
              hasRole={!profile.application.isUserMissingRole}
              applicationRole={currentRole?.category.title || ''}
              onAddClick={handleRoleAdd}
              guidanceMessage={
                profile.isCurrentUser ? (
                  <GuidanceMessage
                    text={
                      profile?.application.guidanceMessages[
                        profile.application.isUserMissingRole
                          ? 'roleInvalid'
                          : 'roleValid'
                      ]
                    }
                    error={profile.application.isUserMissingRole}
                  />
                ) : undefined
              }
              withIcon={false}
            />
          )}
          {profile?.showSkillsSection && (
            <RoleSkills
              disabledRequired={
                profile.application?.isUserMissingRole &&
                !!profile.application.missingRequiredSkills.length
              }
              disabledPreferred={
                profile.application?.isUserMissingRole &&
                !!profile.application.missingPreferredSkills.length
              }
              requiredSkills={profile.application?.requiredSkillsEvaluation}
              preferredSkills={profile.application?.preferredSkillsEvaluation}
              onClick={onEditSkills}
              requiredGuidanceMessage={
                profile.application &&
                profile.isCurrentUser &&
                !auth.withApplicationBuilderScoring ? (
                  <GuidanceMessage
                    text={
                      profile.application?.guidanceMessages[
                        profile.application.missingRequiredSkills.length ||
                        profile.application?.isUserMissingRole
                          ? 'requiredSkillsInvalid'
                          : 'requiredSkillsValid'
                      ]
                    }
                    error={
                      !!profile.application?.missingRequiredSkills.length ||
                      profile.application?.isUserMissingRole
                    }
                  />
                ) : undefined
              }
              preferredGuidanceMessage={
                profile.application &&
                profile.isCurrentUser &&
                !auth.withApplicationBuilderScoring ? (
                  <GuidanceMessage
                    text={
                      profile.application?.guidanceMessages[
                        profile.application.missingPreferredSkills.length ||
                        profile.application?.isUserMissingRole
                          ? 'preferredSkillsInvalid'
                          : 'preferredSkillsValid'
                      ]
                    }
                    type={'secondary'}
                    withIcon={
                      profile.application.missingPreferredSkills.length === 0
                    }
                    error={
                      !!profile.application?.missingPreferredSkills.length ||
                      profile.application?.isUserMissingRole
                    }
                  />
                ) : undefined
              }
              guidanceMessage={
                profile.application.showSkillsGuidanceMessage ? (
                  <GuidanceMessage
                    text={profile.application?.guidanceMessages.missingSkills}
                    type={'secondary'}
                    iconType={IconType.StarsPurple}
                  />
                ) : undefined
              }
            />
          )}
          {profile?.showAboutMe && profile.application && (
            <AboutMeV2
              userId={profile.data.uid}
              text={profile.application?.about || profile?.aboutMe}
              readonly={profile.readonly}
              onChange={onAboutChange}
              hideIcon
              type="large"
              description="Introduce yourself and provide a brief professional summary. What you write here will be saved to your profile in the about me section, and may be shared with the company."
              error={
                profile?.showErrors &&
                (profile.hasAboutMeError || profile.hasAboutMeMaxLengthError)
              }
              textClassName={
                profile?.application?.readonly && auth.isAdmin
                  ? 'admin'
                  : undefined
              }
              withGenerateAboutMeSuggestionOnDemand={
                auth.withGenerateAboutMeSuggestionOnApplication
              }
            />
          )}
          <PitchV2
            onChange={profile?.application.setPitch}
            onSuggestionGenerate={
              profile?.application.setDidGeneratePitchSuggestion
            }
            text={profile.application.pitch}
            readonly={profile.application.readonly}
            error={profile?.showErrors && profile.application?.hasPitchError}
            isApplicationEdited={fieldsEdited.pitchEdited}
            withIcon={false}
            title={'Make yourself stand out'}
            description={
              'If proposed, your responses will be shared with the company.'
            }
            placeholder={
              "Highlight relevant skills for this mission, showcasing past successes and the impactful outcomes you've achieved in similar roles or projects"
            }
            label={
              'How are your skills and experience relevant to this mission?'
            }
            type={'large'}
            guidanceTooltip={
              !profile.application.readonly ? (
                <GuidanceTooltip label={TooltipContent.pitch.label}>
                  {TooltipContent.pitch.component}
                </GuidanceTooltip>
              ) : undefined
            }
            guidanceMessage={
              profile.application.showPitchGuidanceMessage ? (
                <GuidanceMessage
                  text={profile.application.guidanceMessages.pitchInvalid}
                  type={'secondary'}
                  iconType={IconType.StarsPurple}
                />
              ) : undefined
            }
            missionId={profile.application.mid ?? ''}
            roleId={profile.application.rid ?? ''}
            missinRequiredSkills={
              !!profile.application.missingRequiredSkills.length
            }
            missingPreferredSkills={
              !!profile.application.missingPreferredSkills.length
            }
            requiredSkills={profile.application.userRequiredSkills}
            preferredSkills={profile.application.userPrefferedSkills}
            selectedProjects={profile.localHiddenAndSelectedProjects.selected}
            questionResponses={profile.application.customQuestionsWithAnswers}
          />
        </>
      )}

      {profile.showProfileRatesViewMode && (
        <ProfileRatesView profile={profile} />
      )}

      {profile?.showAboutMe && !profile?.application && (
        <AboutMeV2
          userId={profile.data.uid}
          text={profile?.aboutMe}
          readonly={profile.readonly}
          onChange={onAboutChange}
          hideIcon
          type="large"
          error={
            profile?.showErrors &&
            (profile.hasAboutMeError || profile.hasAboutMeMaxLengthError)
          }
          textClassName={
            profile?.application?.readonly && auth.isAdmin ? 'admin' : undefined
          }
          withGenerateAboutMeSuggestionOnDemand={
            auth.withGenerateAboutMeSuggestionOnDemand
          }
        />
      )}
      {profile.application?.showCustomRoleQuestions && (
        <ClientQuestions
          companyName={currentMission?.data.companyName || 'Company'}
          answers={profile.application.answers}
          questions={profile.application?.currentRole?.customQuestions || []}
          onChange={profile.application?.setAnswer}
          readOnly={profile?.application.readonly}
          isApplicationEdited={fieldsEdited.customQuestionRepliesEdited || []}
          missionId={currentMission?.mid ?? ''}
          projectsCount={profile.savedProjectIds.length}
          userSkills={[
            ...profile.application.userRequiredSkills,
            ...profile.application.userPrefferedSkills,
          ]}
          questionsWithErrors={
            profile?.showErrors
              ? profile.application.customQuestionsIdsWithErrors
              : []
          }
          roleId={profile.application?.currentRole?.rid ?? ''}
          guidanceMessage={
            profile.application.showClientQuestionGuidanceMessage ? (
              <GuidanceMessage
                text={
                  profile.application.guidanceMessages.clientQuestionInvalid
                }
                type={'secondary'}
                iconType={IconType.StarsPurple}
              />
            ) : undefined
          }
        />
      )}
      <div>
        {renderRateRange()}
        {profile.showLocationSection && (
          <Location
            onChange={profile.setLocation}
            guidanceMessage={
              profile.application ? (
                <GuidanceMessage
                  text={
                    profile.application?.guidanceMessages[
                      profile.application?.userLocationInRoleReqs
                        ? 'locationValid'
                        : profile.location
                        ? 'locationInvalid'
                        : 'locationMissing'
                    ]
                  }
                  error={!profile.application?.userLocationInRoleReqs}
                  withIcon
                  type={profile.location ? 'secondary' : 'regular'}
                />
              ) : undefined
            }
            userLocation={{
              province: profile.province,
              country: profile.country,
              city: profile.city,
            }}
            toggleAllLocationsModalOpen={toggleLocationsModalOpen}
          />
        )}

        {profile?.showAvailabilitySection && (
          <AvailabilityV2
            type={'large'}
            keepNotifications={!missionNotificationsDisabled}
            onKeepNotificationsChange={onKeepNotificationsChange}
            keepNotificationsPausedEndDate={
              notificationPreferencesUser.formatedEndDate
            }
            companyHoursPerWeekRequired={
              currentRole?.availability?.weeklyHoursAvailable
            }
            availability={profile?.availabilityData}
            applicationAvailability={profile?.application?.availability}
            profAvailabilityData={profile?.profileAvailabilityData}
            withStatusSelect={!profile?.application}
            onChange={(availability) => {
              profile?.application?.setAvailability(availability) ||
                profile?.setAvailability(availability);
            }}
            onToastClick={profile.application?.setUpdateAvailabilityOnSubmit}
            onReminderPeriodChange={profile?.setAvailabilityReminderPeriod}
            reminderPeriod={profile?.availabilityReminderPeriod}
            readOnly={
              profile?.application
                ? profile.application.readonly
                : profile?.readonly
            }
            availabilityError={
              profile?.showErrors &&
              (profile.application
                ? profile.application?.hasAvailabilityError
                : profile.hasAvailabilityError)
            }
            availabilityHoursError={
              profile?.showErrors &&
              (profile.application
                ? profile.application?.hasAvailabilityError
                : profile.hasAvailabilityHoursError)
            }
            isApplicationAvailabilityEdited={fieldsEdited.availabilityEdited}
            isApplicationAvailabilityNotesEdited={
              fieldsEdited.noteOnAvailabilityEdited
            }
            guidanceMessage={
              profile.application && profile.isCurrentUser ? (
                auth.withApplicationBuilderScoring ? (
                  profile.application.showAvailabilityGuidanceMessage ? (
                    <GuidanceMessage
                      text={
                        profile.application.guidanceMessages.availabilityLow
                      }
                      type={'secondary'}
                      iconType={IconType.StarsPurple}
                    />
                  ) : undefined
                ) : (
                  <GuidanceMessage
                    text={
                      profile.application.guidanceMessages[
                        profile.application.availabilityNotMatchingRole
                          ? 'availabilityInvalid'
                          : 'availabilityValid'
                      ]
                    }
                    error={profile.application.availabilityNotMatchingRole}
                  />
                )
              ) : undefined
            }
          />
        )}
      </div>
      {profile.showProfileRates && <ProfileRates profile={profile} />}

      {profile?.application && withWorkingHours && (
        <WorkingHoursOverlap
          title={'Working hours overlap'}
          type={'large'}
          location={profile.location?.city}
          roleWorkingHours={currentRole?.workingHours}
          userWorkingHours={
            profile?.application?.workingHours ?? profile?.workingHours
          }
          profileTimezone={profile?.timezone}
          isApplicationWorkingHoursEdited={fieldsEdited.workingHoursEdited}
          readOnly={
            profile?.application
              ? profile.application.readonly
              : profile?.readonly
          }
          onWorkingHoursChange={(workingHours: WorkingHoursSchema) => {
            profile?.application?.setWorkingHours(workingHours) ||
              profile?.setWorkingHours(workingHours);
          }}
          onToastClick={profile.application?.setUpdateWorkingHoursOnSubmit}
          guidanceMessage={
            profile.isCurrentUser &&
            !!profile.application &&
            currentRole?.workingHours ? (
              auth.withApplicationBuilderScoring ? (
                profile.application.showWorkingHoursOverlapGuidanceMessage ? (
                  <GuidanceMessage
                    text={
                      profile.application.guidanceMessages
                        .workingHoursOverlapLow
                    }
                    type={'secondary'}
                    iconType={IconType.StarsPurple}
                  />
                ) : undefined
              ) : (
                <GuidanceMessage
                  text={
                    profile.application.guidanceMessages[
                      profile.application.workingHoursOverlapTooLow
                        ? 'workingHoursOverlapInvalid'
                        : 'workingHoursOverlapValid'
                    ]
                  }
                  error={profile.application.workingHoursOverlapTooLow}
                />
              )
            ) : undefined
          }
        />
      )}
      {profile?.showProjects && (
        <Projects
          type={'large'}
          withIcon={false}
          profileMode={profile.mode}
          username={profile?.data.username}
          updateProject={profile.updateProject}
          createProject={profile.createProject}
          allProfileSkills={profile.allSkills}
          hiddenProjects={profile.localHiddenAndSelectedProjects.hidden}
          selectedProjects={profile.localHiddenAndSelectedProjects.selected}
          projects={projects}
          recommendedProjects={
            auth.withApplicationBuilderScoring
              ? profile.application?.data.recommendedProjects
              : undefined
          }
          onChange={profile?.setProjects}
          onSaveProject={profile?.updateProject}
          onVisibilityChange={profile.application?.setHiddenProject}
          onAdd={profile?.addEmptyProject}
          onDelete={profile.deleteProject}
          onQueryRoles={() => profile.queryRoles('')}
          onQueryIndustries={() => profile?.queryIndustries('')}
          getEmptyProject={profile.getEmptyProject}
          canHide={profile.application && !profile.application.readonly}
          readonly={profile?.readonly}
          error={profile?.showErrors && profile.hasProjectsError}
          currentUserId={profile?.data.uid}
          guidanceMessage={
            profile.isCurrentUser &&
            profile.application?.showProjectsGuidanceMessage ? (
              <GuidanceMessage
                className={styles.projectsGuidance}
                type={'secondary'}
                iconType={IconType.StarsPurple}
                text={profile.application.guidanceMessages.projectsNotRelevant}
              />
            ) : undefined
          }
          isApplicationView={!!profile?.application}
        />
      )}

      {auth.withNewJobs && auth.isAdmin && (
        <Jobs auth={auth} profile={profile} />
      )}

      {profile.application?.showQualityGuidance && (
        <QualityGuidance
          title={
            profile.application.qualityGuidanceData[
              profile.application.qualityGuidanceStep
            ].title
          }
          description={
            profile.application.qualityGuidanceData[
              profile.application.qualityGuidanceStep
            ].description
          }
          progress={
            profile.application.qualityGuidanceData[
              profile.application.qualityGuidanceStep
            ].progress
          }
        />
      )}
    </div>
  );
};

export default observer(MainApplication);
