import { Icon, IconType, TextColors } from '@ateams/components';
import React, { useEffect, useMemo } from 'react';

import FileDropArea from '@src/components/FileDropArea';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { useResumeUpload } from '@src/hooks/useResumeUpload';
import { UserCV } from '@src/stores/Profile/Profile';
import cx from 'classnames';
import { isNil } from 'lodash';
import { createUseStyles } from 'react-jss';
import { SidebarSection } from '../SidebarSection';
import plus from './plus.svg';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginTop: 16,
  },
  textButton: {
    fontSize: '15px',
    fontWeight: 400,
    height: '24px',
    color: TextColors.primaryLight,
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.9,
    },
    '& + div': {
      width: 'auto',
    },
  },
  removeIcon: {
    padding: 0,
  },
  uploader: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  plus: {
    width: 32,
    height: 32,
    borderRadius: 100,
    overflow: 'hidden',
    backgroundImage: `url(${plus})`,
    color: 'white',
    fontSize: 18,
    textAlign: 'center',
    lineHeight: '28px',
  },
  prompt: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 500,
    color: '#222',

    '& span': {
      color: '#62646A',
      fontSize: '14px',
      fontWeight: 400,
    },
  },
});

interface CVUploaderProps {
  canView: boolean;
  canUpload: boolean;
  cv: UserCV;
  onRemove: () => void;
  onUpload: (cvUrl: string) => void;
}

function CVUploader(props: CVUploaderProps) {
  const styles = useStyles();
  const { uiStore } = useStores();

  const cv = uiStore.userCv;

  useEffect(() => {
    uiStore.setUserCv(props.cv);
  }, [props.cv]);

  const { uploading, handleFileSelected } = useResumeUpload({
    onUpload: (downloadUrl) => {
      props.onUpload(downloadUrl);

      const newCv: UserCV = {
        ...cv,
        label: cv?.label || '',
        downloadUrl,
      };

      uiStore.setUserCv(newCv);
    },
  });

  const readOnly = useMemo(() => {
    return props.canView && !props.canUpload;
  }, [props.canUpload, props.canView]);

  const handleRemove = () => {
    uiStore.setUserCv({
      ...cv,
      label: cv?.label || '',
      downloadUrl: undefined,
    });
    props.onRemove();
  };

  if (readOnly && !cv?.downloadUrl) {
    return null;
  }

  if (!props.canUpload && !readOnly) {
    return null;
  }

  return (
    <SidebarSection title={'Resume'} readonly>
      <div className={styles.wrapper}>
        {cv?.downloadUrl && (
          <a
            href={cv.downloadUrl}
            target="_blank"
            className={styles.textButton}
            rel="noopener noreferrer"
          >
            {cv.label}
          </a>
        )}
        {cv?.downloadUrl && !readOnly && (
          <Icon
            className={styles.removeIcon}
            type={IconType.Close}
            size={'xsmall'}
            onClick={handleRemove}
          />
        )}
        {!cv?.downloadUrl && !readOnly && (
          <>
            <FileDropArea
              disabled={!isNil(uploading)}
              accept={['application/pdf']}
              onFileDrop={handleFileSelected}
            >
              {({ openDialog }) => (
                <div
                  className={cx(styles.uploader, {
                    isLoading: !isNil(uploading),
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    openDialog();
                  }}
                >
                  <div className={styles.plus}>
                    <span>+</span>
                  </div>
                  <div className={styles.prompt}>
                    Upload your Resume
                    <span>PDF file format</span>
                  </div>
                </div>
              )}
            </FileDropArea>
            <LoadingIndicator
              successMsg={'Done'}
              loadingMsg={'Uploading Resume'}
              loading={uploading}
            />
          </>
        )}
      </div>
    </SidebarSection>
  );
}

export default observer(CVUploader);
