import React, { ReactElement, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { InterviewAdminNote } from '@a_team/models/dist/AdminNotesObject';
import { format } from 'date-fns';
import { Icon, IconType, Card, Colors } from '@ateams/components';
import UserAvatar from '@src/components/UserAvatar';
import { ProfileLocation } from '@src/locations';
import Autolinker from 'autolinker';
import LoadingIndicator from '@src/components/LoadingIndicator';
import useLoadingState from '@src/hooks/useLoadingState';

interface Props {
  note: InterviewAdminNote;
  username: string;
  highlight?: boolean;
}

const useStyles = createUseStyles({
  noteCard: (props: Props) => ({
    display: 'flex',
    flexDirection: 'column',
    background: props.highlight ? Colors.primaryLight : '#fff',
  }),
  noteContent: {
    fontFamily: ['Inter', 'sans-serif'],
    whiteSpace: 'break-spaces',
    lineBreak: 'auto',
    '& code': {
      whiteSpace: 'break-spaces',
    },
    marginTop: 8,
  },
  copy: {
    margin: `0 0 16px 0`,
  },
});

export const InterviewFeedbackCard = (props: Props): ReactElement => {
  const styles = useStyles(props);
  const { note, username } = props;
  const interviewDate = useMemo(
    () => format(new Date(note.interviewDate), "MMMM d, yyyy 'at' h:mm a"),
    [note.interviewDate],
  );
  const interviewedOn = useMemo(
    () => format(new Date(note.interviewDate), 'MMMM d, yyyy'),
    [note.interviewDate],
  );

  const [loading, setLoading] = useLoadingState();

  const copyNoteLink = (nid: string): void => {
    const link = `${window.location.origin}/${username}?note=${nid}`;
    navigator.clipboard.writeText(link);
    setLoading(Promise.resolve(), 'Link copied to clipboard');
  };

  return (
    <div id={note.nid}>
      <Card className={styles.noteCard}>
        {note.author && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link to={ProfileLocation(note.author.username || '')}>
                <UserAvatar src={note.author.profilePictureURL} size={24} />
              </Link>
              <span
                style={{
                  fontSize: 14,
                  color: '#62646A',
                  margin: 'auto 8px',
                }}
              >
                {`${note.author.fullName + ' '} added on ${interviewDate}`}
              </span>
            </div>
            <Icon
              style={{ cursor: 'pointer', filter: 'grayscale(1)' }}
              title={'Copy link'}
              type={IconType.Link}
              onClick={() => copyNoteLink(note.nid)}
            />
          </div>
        )}
        <h4>Client Interview Feedback</h4>
        <p className={styles.copy}>
          <strong>Interview Date: </strong>
          {interviewedOn}
        </p>
        <p className={styles.copy}>
          <strong>Feedback: </strong>
          {note.feedback}
        </p>
        <p className={styles.copy}>
          <strong>Proposal interview feedback from: </strong>
          {note.companyName}
        </p>
        <p className={styles.copy}>
          <strong>Mission: </strong>
          <a href={note.missionURL} target="_blank" rel="noreferrer">
            {note.missionTitle}
          </a>
        </p>
        <p className={styles.copy}>
          <strong>Client: </strong>
          {note.author.fullName}
          {`<${note.clientEmail}>`}
        </p>
        <p className={styles.copy}>
          <strong>Note:</strong>
          <pre
            className={styles.noteContent}
            dangerouslySetInnerHTML={{ __html: Autolinker.link(note.text) }}
          ></pre>
        </p>
      </Card>
      <LoadingIndicator loading={loading} />
    </div>
  );
};
