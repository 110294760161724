/* eslint-disable @typescript-eslint/no-explicit-any */
import { action, computed, observable } from 'mobx';
import { Stores } from '@src/stores';
import {
  MissionCardObject,
  MissionId,
  RecommendedMission,
} from '@a_team/models/dist/MissionObject';

import { apiMissions, apiUser } from '@src/logic/services/endpoints';
import { QueryNextToken, QueryResult } from '@a_team/models/dist/misc';
import {
  MissionRoleSkill,
  MissionRoleStatus,
} from '@a_team/models/dist/MissionRole';
import _ from 'lodash';
import {
  TalentIndustryId,
  TalentIndustrySummary,
} from '@a_team/models/dist/TalentIndustry';
import { RoleCategoryId } from '@a_team/models/dist/RoleCategory';
import { TalentSkillId } from '@a_team/models/dist/TalentCategories';
import {
  AppliedMissionQueryParameters,
  AppliedMissionsResult,
  UserMissionNotInterestedData,
} from '@ateams/api/dist/endpoints/User';
import { AppliedMissionCardObject } from '@a_team/models/src/MissionApplicationObject';
import { SetMissionApplicationsUnavailable } from '@ateams/api/dist/endpoints/Missions';
import { isBefore, isSameDay } from 'date-fns';
import UserObject, {
  BasicUserObject,
  UserId,
  UserStatus,
  UserType,
} from '@a_team/models/dist/UserObject';
import {
  CurrentUserMissionApplicationObject,
  MissionApplicationId,
  MissionApplicationLifecycleStage,
  MissionApplicationLifecycleStageLabels,
} from '@a_team/models/dist/MissionApplicationObject';
import { SelectOption } from '@ateams/components';

export type FilterValue = RoleCategoryId | TalentSkillId | TalentIndustryId;
export type AppliedMissionsFilterValues = MissionApplicationLifecycleStage;

export type HasOpenRoles = { id: string; label: string };

export type HasAvailability = { id: string; label: string };

export type HasLocation = { id: string; label: string };
export interface FilterValues {
  roles: RoleCategoryId[];
  skills: TalentSkillId[];
  industries: TalentIndustryId[];
  owners: BasicUserObject['uid'][];
  hasOpenRoles: HasOpenRoles['id'][];
  hasAvailability: HasAvailability['id'][];
  hasLocation: HasLocation['id'][];
}

interface FilterOptions {
  roles: { label: string; id: RoleCategoryId }[];
  skills: { label: string; id: TalentSkillId }[];
  industries: { label: string; id: TalentIndustryId }[];
  owners: { label: string; id: BasicUserObject['uid'] }[];
  hasOpenRoles: { label: string; id: string }[];
  hasAvailability: { label: string; id: string }[];
  hasLocation: { label: string; id: string }[];
}
export interface MissionControlStoreData {
  loading: boolean;
  error: boolean;
  loadingRecommended: boolean | undefined;
  message: string;
  allMissions?: QueryResult<MissionCardObject>;
  recommended?: QueryResult<MissionCardObject>;
  applied?: AppliedMissionsResult;
  appliedByLifecycleStage?: Partial<
    Record<
      MissionApplicationLifecycleStage,
      QueryResult<AppliedMissionCardObject>
    >
  >;
  appliedMap?: Map<MissionId, boolean>;
  notInterested?: QueryResult<MissionCardObject>;
  notInterestedMap?: Map<MissionId, boolean>;
  pending?: QueryResult<AppliedMissionCardObject>;
  completed?: QueryResult<MissionCardObject>;
  filterValues: FilterValues;
  appliedUserFilters: boolean;
  appliedMissionsFilters: AppliedMissionQueryParameters;
  queryFilter?: string;
}

const initialFilterValues = {
  roles: [],
  skills: [],
  industries: [],
  owners: [],
  hasOpenRoles: [],
  hasAvailability: [],
  hasLocation: [],
};

const initialAppliedMissionsFilterValues = {
  filterByLifecycleStage: [],
};

export default class MissionControlStore implements MissionControlStoreData {
  @observable loading: MissionControlStoreData['loading'] = false;
  @observable error: MissionControlStoreData['error'] = false;
  @observable
  loadingRecommended: MissionControlStoreData['loadingRecommended'] = undefined;
  @observable message: MissionControlStoreData['message'] = '';

  @observable allMissions: MissionControlStoreData['allMissions'];
  @observable recommended: MissionControlStoreData['recommended'];
  @observable applied: MissionControlStoreData['applied'];
  @observable
  appliedByLifecycleStage: MissionControlStoreData['appliedByLifecycleStage'];
  @observable appliedMap: MissionControlStoreData['appliedMap'] = new Map();
  @observable notInterested: MissionControlStoreData['notInterested'];
  @observable
  notInterestedMap: MissionControlStoreData['notInterestedMap'] = new Map();
  @observable pending: MissionControlStoreData['pending'];
  @observable completed: MissionControlStoreData['completed'];
  @observable
  filterValues: MissionControlStoreData['filterValues'] = initialFilterValues;
  @observable
  appliedUserFilters: MissionControlStoreData['appliedUserFilters'] = false;
  @observable queryFilter: MissionControlStoreData['queryFilter'] = undefined;
  @observable
  appliedMissionsFilters: MissionControlStoreData['appliedMissionsFilters'] = initialAppliedMissionsFilterValues;

  private readonly rootStore: Stores;
  private authStore: Stores['auth'];
  // private readonly analytics: PlatformServiceAnalytics;

  public constructor(
    rootStore: Stores,
    auth: Stores['auth'],
    initialState: MissionControlStoreData,
  ) {
    this.rootStore = rootStore;
    this.authStore = auth;
    if (initialState) {
      this.allMissions = initialState.allMissions;
      this.recommended = initialState.recommended;
      this.notInterested = initialState.notInterested;
      this.notInterestedMap = new Map<MissionId, boolean>(
        this.notInterested?.items.map(({ mid }) => [mid, true]),
      );

      this.pending = initialState.pending;

      this.applied = initialState.applied;
      this.appliedMap = new Map<MissionId, boolean>(
        this.applied?.items.map(({ mission }) => [mission.mid, true]),
      );
      this.completed = initialState.completed;
      this.filterValues = initialState.filterValues;
      this.appliedUserFilters = initialState.appliedUserFilters;
      this.appliedMissionsFilters = initialState.appliedMissionsFilters;
      this.appliedByLifecycleStage = initialState.appliedByLifecycleStage;
    }
  }

  public serialize = (): MissionControlStoreData => {
    return {
      loadingRecommended: this.loadingRecommended,
      loading: this.loading,
      error: this.error,
      message: this.message,
      allMissions: this.allMissions,
      recommended: this.recommended,
      applied: this.applied,
      appliedMap: this.appliedMap,
      notInterested: this.notInterested,
      notInterestedMap: this.notInterestedMap,
      pending: this.pending,
      filterValues: this.filterValues,
      appliedUserFilters: this.appliedUserFilters,
      queryFilter: this.queryFilter,
      appliedMissionsFilters: this.appliedMissionsFilters,
      appliedByLifecycleStage: this.appliedByLifecycleStage,
    };
  };

  @action setLoading = (loading = true): void => {
    this.loading = loading;
    loading && this.setError();
  };

  @action setLoadingRecommended = (loading = true): void => {
    this.loadingRecommended = loading;
    loading && this.setError();
  };

  @action setError = (message?: string, err?: Error) => {
    this.error = !!message;
    this.message = message || '';
    err && console.error(err);
  };

  public async loadAllMissions(): Promise<QueryResult<MissionCardObject> | void> {
    // users with limited access do not have access to all missions
    if (this.authStore.limitedAccess) {
      return;
    }

    return apiMissions
      .queryMissionsCards(this.rootStore.auth, {
        filterByOpenRoles: true,
        filterByRoleCategories: [],
      })
      .then((missionCards: QueryResult<MissionCardObject>) => {
        this.updateAllMissions(missionCards);
      })
      .catch((err) => {
        this.setError('Failed to load all missions', err);
      });
  }

  public async loadRecommendedMissions(): Promise<QueryResult<RecommendedMission> | void> {
    const limit = 10;

    this.setLoadingRecommended(true);

    try {
      if (
        this.authStore.limitedAccess ||
        (this.authStore.withOnboardingV2 && !this.authStore.onboardingCompleted)
      ) {
        return;
      }
      const recommendedMissions: QueryResult<RecommendedMission> =
        await apiMissions.getRecommendedMissions(this.authStore, limit);
      this.updateRecommendedMissions(recommendedMissions);
    } catch (err) {
      if (err instanceof Error) {
        this.setError('Failed to load recommended missions', err);
      }
    } finally {
      this.setLoadingRecommended(false);
    }
  }

  public async loadAppliedToMissions(
    loadMore = true,
  ): Promise<AppliedMissionsResult | void> {
    return apiUser
      .getAppliedMissions(this.rootStore.auth, this.appliedMissionsFilters)
      .then((appliedMissions: AppliedMissionsResult) => {
        this.updateAppliedMissions(appliedMissions, loadMore);
      })
      .catch((err) => {
        this.setError('Failed to load Applied to missions', err);
      });
  }

  public async loadNotInterestedMissions(): Promise<QueryResult<MissionCardObject> | void> {
    return apiUser
      .getNotInterestedMissions(this.authStore)
      .then((notInterestedMissions: QueryResult<MissionCardObject>) => {
        this.updateNotInterestedMissionsWithMoreResults(notInterestedMissions);
      })
      .catch((err) => {
        this.setError('Failed to load not interested missions', err);
      });
  }

  public async loadCompletedMissions(): Promise<QueryResult<MissionCardObject> | void> {
    try {
      const completedMissions = await apiUser.getCompletedMissions(
        this.authStore,
      );
      this.updateCompletedMissionsWithMoreResults(completedMissions);
      return completedMissions;
    } catch (err) {
      this.setError('Failed to load completed missions', err as Error);
    }
  }

  public loadPendingMissionApplications = async (): Promise<
    QueryResult<AppliedMissionCardObject>
  > => {
    const pendingMissionApplications =
      await apiUser.getPendingMissionApplications(
        this.authStore,
        this.pending?.next || undefined,
      );

    this.updatePendingMissionApplications(pendingMissionApplications);

    return pendingMissionApplications;
  };

  public async setNotInterested(mid: MissionId): Promise<void> {
    this.setLoading();

    if (this.allMissions) {
      const notInterestedMission = this.allMissions?.items.find(
        (mission) => mission.mid === mid,
      );

      if (notInterestedMission) {
        this.updateNotInterestedMissionsWithMoreResults({
          items: [notInterestedMission],
          next: this.notInterested?.next as QueryNextToken,
        });
      }
    }

    const notInterestedData: UserMissionNotInterestedData = { missionId: mid };
    await apiUser.updateCurrentUserNotInterestedMissions(
      this.rootStore.auth,
      notInterestedData,
    );

    if (this.rootStore.missions.currentMission) {
      this.rootStore.missions.currentMission.isNotInterested = true;
    }

    this.setLoading(false);
  }

  public async restoreNotInterested(mid: MissionId): Promise<void> {
    this.setLoading();
    this.restoreNotInterestedMission(mid);
    const notInterestedData: UserMissionNotInterestedData = { missionId: mid };

    try {
      await apiUser.restoreNotInterestedMission(
        this.rootStore.auth,
        notInterestedData,
      );

      if (this.rootStore.missions.currentMission) {
        this.rootStore.missions.currentMission.isNotInterested = false;
      }
    } catch (err) {
      this.setError('Something went wrong', err as Error);
    } finally {
      this.setLoading(false);
    }
  }

  public async setMissionApplicationsUnavailable(
    data: SetMissionApplicationsUnavailable,
  ): Promise<void> {
    await apiMissions.setMissionApplicationsUnavailable(this.authStore, data);

    if (this.pending) {
      const items = this.pending.items.filter(
        ({ mission: { mid } }) => !data.applicationIds.includes(mid),
      );
      this.updatePendingMissionApplications({ items, next: this.pending.next });
    }
  }

  public hasUserAppliedToMissions = (uid: UserId): Promise<boolean> => {
    return apiMissions.hasUserAppliedToMissions(this.authStore, uid);
  };

  public loadMissionControl = async (): Promise<void> => {
    const loadedMain =
      this.allMissions && this.applied && this.notInterested && this.completed;

    // Prevent constant reload if recommended missions cannot be fetched
    if (this.loading || loadedMain) {
      return;
    }

    this.setLoading();
    const promises: Promise<unknown>[] = [];

    await Promise.all(promises).catch((err) => {
      this.setError("Couldn't load all mission, please try again later", err);
    });
    this.setLoading(false);
  };

  public async reloadMissionControl(profileData: UserObject): Promise<void> {
    if (this.loading) {
      return;
    }
    this.setLoading();
    this.clearFilters();
    this.setAppliedMissionsFilters(initialAppliedMissionsFilterValues);
    this.allMissions = undefined;
    this.recommended = undefined;
    this.applied = undefined;
    this.appliedByLifecycleStage = undefined;
    this.appliedMap = new Map();
    this.notInterested = undefined;
    this.notInterestedMap = new Map();
  }

  public async withdrawApplication(
    mid: string,
    application: CurrentUserMissionApplicationObject,
  ): Promise<void> {
    await apiMissions.updateMissionApplication(
      this.authStore,
      mid,
      application.aid,
      {
        ..._.pick(
          application,
          'pitch',
          'about',
          'hourlyRateRange',
          'hiddenProjects',
          'availability',
        ),
        withdrawn: true,
      },
    );

    this.removeAppliedMission(application.aid);
    await this.rootStore.missions.reloadCurrentMission(
      mid,
      this.authStore.isAdmin,
    );
  }

  clearAppliedMissionsFilters = async (): Promise<void> => {
    if (this.appliedMissionsFilters.filterByLifecycleStage?.length === 0)
      return;

    this.setAppliedMissionsFilters(initialAppliedMissionsFilterValues);
  };

  setLifecycleStageFilter = async (
    stage: MissionApplicationLifecycleStage,
  ): Promise<void> => {
    this.setLoading();
    this.setAppliedMissionsFilter('filterByLifecycleStage', [stage]);
    if (!this.appliedByLifecycleStage?.[stage]) {
      await this.loadAppliedToMissions(false);
    }
    this.setLoading(false);
  };

  @computed get hasQueryFilter(): boolean | undefined {
    return !!this.queryFilter || undefined;
  }

  @action updateAllMissions = (
    missionCards: QueryResult<MissionCardObject>,
  ): void => {
    this.allMissions = {
      items: [...(this.allMissions?.items ?? []), ...missionCards.items],
      next: missionCards.next,
    };
  };

  @action setAllMissions = (
    missionCards: QueryResult<MissionCardObject>,
  ): void => {
    this.allMissions = {
      items: missionCards.items,
      next: missionCards.next,
    };
  };

  @action updateRecommendedMissions = (
    missionCards: QueryResult<MissionCardObject>,
  ): void => {
    this.recommended = {
      items: [...(this.recommended?.items ?? []), ...missionCards.items],
      next: missionCards.next,
    };
  };

  @action setRecommendedMissions = (
    missionCards: QueryResult<MissionCardObject>,
  ): void => {
    this.recommended = {
      items: missionCards.items,
      next: missionCards.next,
    };
  };

  @action updateAppliedMissions = (
    missionCards: AppliedMissionsResult,
    loadMore = true,
  ): void => {
    const { items, next, totals } = missionCards;
    this.applied = {
      items: loadMore ? [...(this.applied?.items ?? []), ...items] : items,
      next,
      totals,
    };

    if (this.selectedLifecycleStage) {
      this.setAppliedByLifecycleStage(this.selectedLifecycleStage, {
        items,
        next,
      });
    }

    this.appliedMap = new Map<MissionId, boolean>(
      this.applied.items.map(({ mission }) => [mission.mid, true]),
    );
  };

  @action setAppliedByLifecycleStage = (
    stage: MissionApplicationLifecycleStage,
    data: QueryResult<AppliedMissionCardObject>,
  ): void => {
    this.appliedByLifecycleStage = {
      ...this.appliedByLifecycleStage,
      [stage]: data,
    };
  };

  @action updateTotalApplied = (total: number): void => {
    if (!this.applied) return;
    this.applied.totals.total = total;
  };

  @action removeAppliedMissionFromLifecycle = (
    aid: MissionApplicationId,
  ): void => {
    if (!this.selectedLifecycleStage) return;

    this.setAppliedByLifecycleStage(this.selectedLifecycleStage, {
      items: this.selectedLifecycleStageMissions.filter((card) => {
        return card.application.aid !== aid;
      }),
      next:
        this.appliedByLifecycleStage?.[this.selectedLifecycleStage]?.next ||
        null,
    });

    if (this.applied) {
      this.applied.totals[this.selectedLifecycleStage] = -1;
    }
  };

  @action removeAppliedMission = (aid: MissionApplicationId): void => {
    let mid: string | null = null;
    if (this.applied?.items) {
      this.applied.items = this.allApplied.filter((mission) => {
        if (mission.application.aid === aid) {
          mid = mission.mission.mid;
          return false;
        }

        return true;
      });

      this.updateTotalApplied(this.applied.totals.total - 1);
      this.removeAppliedMissionFromLifecycle(aid);

      if (mid && this.appliedMap?.get(mid)) {
        this.appliedMap?.delete(mid);
      }
    }
  };

  @action updateNotInterestedMissionsWithMoreResults = (
    missionCards: QueryResult<MissionCardObject>,
    append = true,
  ): void => {
    this.notInterested = {
      items: append
        ? [...(this.notInterested?.items ?? []), ...missionCards.items]
        : missionCards.items,
      next: missionCards.next,
    };

    this.notInterestedMap = new Map<MissionId, boolean>(
      this.notInterested.items.map(({ mid }) => [mid, true]),
    );

    const currentMissionId = this.rootStore.missions.currentMission?.mid;
    if (currentMissionId) {
      const notInterestedMission = this.notInterested?.items.some(
        (mission) => mission.mid === currentMissionId,
      );

      this.rootStore.missions.updateNotInterested(notInterestedMission);
    }
  };

  @action updatePendingMissionApplications = (
    pendingMissionApplications: QueryResult<AppliedMissionCardObject>,
  ): void => {
    this.pending = {
      items: [
        ...(this.pending?.items ?? []),
        ...pendingMissionApplications.items,
      ],
      next: pendingMissionApplications.next,
    };
  };

  @action updateCompletedMissionsWithMoreResults = (
    missionCards: QueryResult<MissionCardObject>,
  ): void => {
    this.completed = {
      items: [...(this.completed?.items ?? []), ...missionCards.items],
      next: missionCards.next,
    };
  };

  @action setCompletedMissions = (
    missionCards: QueryResult<MissionCardObject>,
  ): void => {
    this.completed = {
      items: missionCards.items,
      next: missionCards.next,
    };
  };

  @computed get getCompleted(): MissionCardObject[] {
    return this.filterByQuery(
      this.completed?.items || [],
      (item) => item,
      this.queryFilter || '',
    );
  }

  @action restoreNotInterestedMission = (missionId: MissionId): void => {
    if (!this.notInterested) return;

    const items: MissionCardObject[] =
      this.notInterested.items.filter(({ mid }) => mid !== missionId) ?? [];

    this.notInterested = { items, next: this.notInterested.next };
    this.notInterestedMap = new Map<MissionId, boolean>(
      items.map(({ mid }) => [mid, true]),
    );
  };

  @computed get getAllMissions(): MissionCardObject[] {
    return (
      this.allMissions?.items.filter(
        ({ mid }) => !this.notInterestedMap?.get(mid),
      ) ?? []
    );
  }

  @computed get getRecommended(): RecommendedMission[] {
    return this.filterByQuery(
      this.allRecommended,
      (item) => item,
      this.queryFilter || '',
    );
  }

  @computed get allRecommended(): RecommendedMission[] {
    return (
      this.recommended?.items.filter(
        ({ mid }) =>
          !(this.notInterestedMap?.get(mid) || this.appliedMap?.get(mid)),
      ) ?? []
    );
  }

  @computed get getRecommendedMissionIndices(): Map<MissionId, number> {
    return new Map<MissionId, number>(
      this.recommended?.items.map(({ mid }, index) => [mid, index]),
    );
  }

  @computed get getApplied(): AppliedMissionCardObject[] {
    return this.filterByQuery(
      this.filteredApplied,
      (item) => item.mission,
      this.queryFilter || '',
    );
  }

  @computed get filteredApplied(): AppliedMissionCardObject[] {
    if (this.selectedLifecycleStage) {
      const stageMissions =
        this.appliedByLifecycleStage?.[this.selectedLifecycleStage];
      return stageMissions?.items || [];
    }
    return this.allApplied;
  }

  @computed get allApplied(): AppliedMissionCardObject[] {
    return this.applied?.items ?? [];
  }

  @computed get getNotInterested(): (MissionCardObject | RecommendedMission)[] {
    return this.filterByQuery(
      this.allNotInterested,
      (item) => item,
      this.queryFilter || '',
    ).map((item) => ({
      ...item,
      roles: item.roles.filter(
        ({ status }) => status === MissionRoleStatus.Open,
      ),
    }));
  }

  @computed get allNotInterested(): (MissionCardObject | RecommendedMission)[] {
    const unfilteredRecommendations = this.recommended?.items ?? [];
    const recommendationIndices = this.getRecommendedMissionIndices;

    return (this.notInterested?.items ?? []).map((mission) => {
      const recommendedIndex = recommendationIndices.get(mission.mid);
      return recommendedIndex === undefined
        ? mission
        : unfilteredRecommendations[recommendedIndex];
    });
  }

  @computed get missionsAmount(): { [key: string]: number } {
    return {
      allMissions: this.getAllMissions.length,
      filteredMissions: this.filteredMissions.length,
      recommended: this.getRecommended.length,
      allRecommended: this.allRecommended.length,
      applied: this.getApplied.length,
      allApplied: this.applied?.totals.total ?? 0,
      notInterested: this.getNotInterested.length,
      allNotInterested: this.allNotInterested.length,
      completed: this.getCompleted.length,
    };
  }

  @computed get filterOptions(): FilterOptions {
    const roles: { cid: RoleCategoryId; title: string }[] = [];
    const skills: MissionRoleSkill[] = [];
    const industries: TalentIndustrySummary[] = [];
    const owners: BasicUserObject[] = [];
    // static options for the checkbox
    const hasOpenRoles: HasOpenRoles[] = [
      { id: 'yes', label: 'Yes' },
      { id: 'no', label: 'No' },
    ];
    const hasAvailability: HasAvailability[] = [{ id: 'yes', label: 'Yes' }];
    const hasLocation: HasLocation[] = [{ id: 'yes', label: 'Yes' }];
    const allMissions = this.getAllMissions;

    allMissions.forEach((mission: MissionCardObject) => {
      mission.roles.forEach((role) => {
        if (!role.user) {
          roles.push({
            cid: role.category.cid,
            title: role.category.title,
          });
          role.preferredSkills && skills.push(...role.preferredSkills);
          role.requiredSkills && skills.push(...role.requiredSkills);
        }
      });

      if (mission.owner) {
        owners.push(mission.owner);
      } else {
        owners.push({
          badges: [],
          firstName: 'No',
          fullName: 'No Owner',
          lastName: 'Owner',
          profilePictureURL: '',
          profileURL: '',
          status: UserStatus.Active,
          title: '',
          type: UserType.User,
          username: '',
          verified: true,
          uid: 'No Owner',
        });
      }
      industries.push(...mission.industries);
    });

    if (this.authStore.currentUser) {
      const user = this.authStore.currentUser;

      const specs = user.talentProfile?.talentSpecializations;
      if (specs) {
        specs.mainTalentSpecialization &&
          roles.push({
            cid: specs.mainTalentSpecialization.id,
            title: specs.mainTalentSpecialization.name,
          });
        specs.additionalTalentSpecializations &&
          specs.additionalTalentSpecializations.forEach((spec) => {
            roles.push({
              cid: spec.id,
              title: spec.name,
            });
          });
      }

      skills.push(...(user.talentProfile?.talentSkills.mainTalentSkills || []));
      skills.push(
        ...(user.talentProfile?.talentSkills.additionalTalentSkills || []),
      );

      user.talentProfile?.talentIndustries?.experiences.forEach((experience) =>
        industries.push({
          id: experience.talentIndustryId,
          name: experience.talentIndustryName,
        }),
      );
    }

    const filterOptions = {
      roles: this.formatFilterOptions(roles, 'cid', 'title'),
      skills: this.formatFilterOptions(
        skills,
        'talentSkillId',
        'talentSkillName',
      ),
      industries: this.formatFilterOptions(industries, 'id', 'name'),
      owners: this.formatFilterOptions(owners, 'uid', 'fullName'),
      hasOpenRoles: this.formatFilterOptions(hasOpenRoles, 'id', 'label'),
      hasAvailability: this.formatFilterOptions(hasAvailability, 'id', 'label'),
      hasLocation: this.formatFilterOptions(hasLocation, 'id', 'label'),
    };

    return filterOptions as FilterOptions;
  }

  // Convert filter options into a format consumable by dropdown components
  private formatFilterOptions(
    arr: any[],
    valueId: FilterValue | undefined,
    valueName: string | undefined,
  ): Array<{ label: keyof FilterValues; id: FilterValue }> {
    let unordered = [],
      ordered = [],
      formatted = [];

    if (!valueId || !valueName) {
      unordered = _.uniq(arr);
      ordered = _.sortBy(unordered);
      formatted = ordered.map((value) => {
        return { label: value, id: value };
      });
    } else {
      unordered = _.uniqBy(arr, valueId);
      ordered = _.sortBy(unordered, valueName);
      formatted = ordered.map((value) => {
        return { label: value[valueName], id: value[valueId] };
      });
    }
    return formatted;
  }

  @computed get selectedLifecycleStage():
    | MissionApplicationLifecycleStage
    | undefined {
    return this.appliedMissionsFilters.filterByLifecycleStage &&
      this.appliedMissionsFilters.filterByLifecycleStage.length
      ? this.appliedMissionsFilters.filterByLifecycleStage[0]
      : undefined;
  }

  @computed get selectedLifecycleStageMissions(): AppliedMissionCardObject[] {
    if (!this.selectedLifecycleStage) return [];
    const stageMissions =
      this.appliedByLifecycleStage?.[this.selectedLifecycleStage];
    return stageMissions?.items || [];
  }

  @computed get selectedLifecycleFilterOption(): SelectOption | null {
    if (!this.selectedLifecycleStage) return null;
    return {
      value: this.selectedLifecycleStage,
      label: `${
        MissionApplicationLifecycleStageLabels[this.selectedLifecycleStage]
      }${
        this.getApplied.length > 0 && !this.loading
          ? ` (${this.getApplied.length})`
          : ''
      }`,
    };
  }

  @action setFilterValues = (
    name: keyof FilterValues,
    value: FilterValue[],
  ): void => {
    this.filterValues = { ...this.filterValues, [name]: value };
  };

  @action clearFilters = (): void => {
    this.filterValues = initialFilterValues;
  };

  @action setQueryFilter = (query: string | null): void => {
    this.queryFilter = query || undefined;
  };

  @action setAppliedUserFilters = (applied: boolean): void => {
    this.appliedUserFilters = applied;
  };

  @action setAppliedMissionsFilter = (
    name: keyof AppliedMissionQueryParameters,
    value: AppliedMissionsFilterValues[],
  ): void => {
    this.appliedMissionsFilters = {
      ...this.appliedMissionsFilters,
      [name]: value,
    };
  };

  @action setAppliedMissionsFilters = (
    filters: AppliedMissionQueryParameters,
  ): void => {
    this.appliedMissionsFilters = filters;
  };

  // See if any filters are applied currently (shortcut for UI)
  @computed get filtersOn(): boolean {
    return Object.values(this.filterValues).some((value) => value.length > 0);
  }

  @computed get filteredMissions(): (MissionCardObject | RecommendedMission)[] {
    const unfiltered = this.getAllMissions;
    const unfilteredRecommendations = this.recommended?.items ?? [];
    const recommendationIndices = this.getRecommendedMissionIndices;

    if (!this.filtersOn && !this.queryFilter) {
      return unfiltered.map((mission) => {
        const recommendedIndex = recommendationIndices.get(mission.mid);
        return recommendedIndex === undefined
          ? mission
          : unfilteredRecommendations[recommendedIndex];
      });
    }

    const filterMissionsByRoles = (
      missions: MissionCardObject[],
      roleFilters: string[],
    ) => {
      if (!roleFilters.length) return missions;

      const filteredMissions = missions.filter((mission) => {
        return mission.roles.some(
          (role) => !role.user && roleFilters.includes(role.category.cid),
        );
      });
      return filteredMissions;
    };

    const filterBySkills = (
      missions: MissionCardObject[],
      skillFilters: string[],
    ) => {
      if (!skillFilters.length) return missions;

      const filtered = missions.filter((mission) => {
        const missionSkills: string[] = [];

        mission.roles.forEach((role) => {
          if (!role.user) {
            role.preferredSkills?.forEach((skill) =>
              missionSkills.push(skill.talentSkillId as string),
            );
            role.requiredSkills?.forEach((skill) =>
              missionSkills.push(skill.talentSkillId as string),
            );
          }
        });
        return missionSkills.some((skillId) => skillFilters.includes(skillId));
      });
      return filtered;
    };

    const filterByIndustry = (
      missions: MissionCardObject[],
      industryFilters: string[],
    ) => {
      if (!industryFilters.length) return missions;

      const filteredMissions = missions.filter((mission) => {
        return mission.industries.some((industry) =>
          industryFilters.includes(industry.id),
        );
      });
      return filteredMissions;
    };

    const filterByOwner = (
      missions: MissionCardObject[],
      ownerFilters: string[],
    ) => {
      if (!ownerFilters.length) return missions;

      return missions.filter((mission) => {
        return ownerFilters.includes(mission.owner?.uid ?? 'No Owner');
      });
    };
    const filterByHasOpenRole = (
      missions: MissionCardObject[],
      hasOpenRoleFilters: string[],
    ) => {
      if (!hasOpenRoleFilters.length) {
        return missions;
      }
      return missions.filter((mission) => {
        const hasOpenRole = mission.roles
          .filter((role) => {
            if (!this.filterValues.roles?.length) return true;
            return this.filterValues.roles.includes(role.category.cid);
          })
          .some(({ lookingForApplications }) => lookingForApplications);
        return hasOpenRoleFilters.includes(hasOpenRole ? 'yes' : 'no');
      });
    };

    const filterByHasAvailability = (
      missions: MissionCardObject[],
      hasAvailabilityFilters: string[],
    ) => {
      const { user } = this.authStore;

      if (!hasAvailabilityFilters.length || !user) return missions;

      return missions.filter((mission) => {
        return mission.roles.some((role) => {
          if (!role.user) {
            const hasAvailableDate =
              !role.availability?.date ||
              !user.availability?.availableFrom ||
              isSameDay(
                new Date(user.availability.availableFrom),
                new Date(role.availability.date),
              ) ||
              isBefore(
                new Date(user.availability.availableFrom),
                new Date(role.availability.date),
              );

            const hasAvailableHours =
              !role.availability?.weeklyHoursAvailable ||
              !user.availability?.weeklyHoursAvailable ||
              user.availability.weeklyHoursAvailable >=
                role.availability.weeklyHoursAvailable;

            return hasAvailableDate && hasAvailableHours;
          }

          return false;
        });
      });
    };

    const filterByHasLocation = (
      missions: MissionCardObject[],
      locationFilters: string[],
    ) => {
      const { user } = this.authStore;
      const userLocation = user?.location?.countryShortName;

      if (!locationFilters.length || !userLocation) return missions;

      return missions.filter((mission) => {
        return mission.roles.some((role) => {
          if (
            !role.user &&
            (!role.locations?.length || role.locations.includes(userLocation))
          ) {
            return true;
          }

          return false;
        });
      });
    };

    const queryFilteredMissions = this.filterByQuery(
      unfiltered,
      (item) => item,
      this.queryFilter || '',
    );
    const roleFilteredMissions = filterMissionsByRoles(
      queryFilteredMissions,
      this.filterValues.roles,
    );
    const skillsFilteredMissions = filterBySkills(
      roleFilteredMissions,
      this.filterValues.skills,
    );
    const ownerFilteredMissions = filterByOwner(
      skillsFilteredMissions,
      this.filterValues.owners,
    );
    const industryFilteredMissions = filterByIndustry(
      ownerFilteredMissions,
      this.filterValues.industries,
    );
    const hasAvailabilityFilteredMissions = filterByHasAvailability(
      industryFilteredMissions,
      this.filterValues.hasAvailability,
    );
    const hasLocationFilteredMissions = filterByHasLocation(
      hasAvailabilityFilteredMissions,
      this.filterValues.hasLocation,
    );

    const hasOpenRoleFilteredMissions = filterByHasOpenRole(
      hasLocationFilteredMissions,
      this.filterValues.hasOpenRoles,
    );

    return hasOpenRoleFilteredMissions.map((mission) => {
      const recommendedIndex = recommendationIndices.get(mission.mid);
      return recommendedIndex === undefined
        ? mission
        : unfilteredRecommendations[recommendedIndex];
    });
  }

  public toQueryFilters = (query: string) => {
    return query
      .trim()
      .split(/\W+/g)
      .filter(Boolean)
      .map((word) => new RegExp(`\\b${word}`, 'i'));
  };

  // TODO: Move to utils
  private filterByQuery<T>(
    items: T[],
    transform: (item: T) => MissionCardObject,
    query: string,
  ) {
    const words = this.toQueryFilters(query);

    if (!words.length) {
      return items;
    }

    return items.filter((item) => {
      const mission = transform(item);

      const keywords = [
        mission.title,
        mission.description,
        mission.companyName,
        ...mission.roles.map((role) =>
          [role.headline, role.category.title].join(' '),
        ),
      ].join(' ');

      return words.every((word) => word.test(keywords));
    });
  }
}
