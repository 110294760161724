import { OnboardingStage } from '@a_team/models/dist/UserObject';
import { Button } from '@a_team/ui-components';
import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import Step, { SetupProps, StepStatus } from '../common/Step';
import { Link } from 'react-router-dom';
import { MissionControlAppliedLocation } from '@src/locations';
import CompanyLogo from '@src/components/CompanyLogo';
import NotInterestedModal from './NotInterestedModal';

interface EvaluationCallStepProps {
  onboardingStage: OnboardingStage;
  hasApplied: boolean;
  onApply: () => void;
  companyLogo?: string;
  missionName: string;
  missionId: string;
  clientCompanyName?: string;
  withNotInterestedOnOnboarding?: boolean;
}

const ApplyNowStep = ({
  hasApplied,
  onApply,
  companyLogo,
  missionName,
  missionId,
  clientCompanyName,
  withNotInterestedOnOnboarding,
}: EvaluationCallStepProps) => {
  const missionTitle = useMemo(() => {
    if (!missionName) return '';
    const parts = missionName.split(':');
    return parts.length > 1 ? parts.slice(1).join(':').trim() : missionName;
  }, [missionName]);

  const [isNotInterestedModalOpen, setIsNotInterestedModalOpen] =
    useState(false);

  const INCOMPLETE_DATA: SetupProps = {
    stepIcon: <CompanyLogo logoUrl={companyLogo} size={40} />,
    title: `You’re invited to apply to the ${missionTitle} mission`,

    description:
      'To be considered, fill out the application and then schedule an evaluation call with our team.',
    completionTime: 'Estimated completion time: 15 to 30 minutes',
    cta: (
      <>
        <Button
          size="md"
          onClick={onApply}
          style={{
            marginLeft: 24,
          }}
        >
          Apply Now
        </Button>
        {withNotInterestedOnOnboarding && (
          <Button
            onClick={() => setIsNotInterestedModalOpen(true)}
            size="md"
            variant="secondary"
          >
            Not interested
          </Button>
        )}
      </>
    ),
    videoUrl: 'https://www.loom.com/share/1bff5cf171834bc0be773c15f57a429d',
    stepStatus: StepStatus.IN_PROGRESS,
  };

  const COMPLETED_DATA: SetupProps = {
    stepIcon: <CompanyLogo logoUrl={companyLogo} size={40} />,
    title: `You’ve applied to the ${missionName} mission`,
    description: 'Track your progress to see if you’ve been selected.',
    cta: (
      <Link
        style={{
          color: '#7000E3',
          marginLeft: 24,
        }}
        to={MissionControlAppliedLocation}
      >
        Track application
      </Link>
    ),
    stepStatus: StepStatus.COMPLETED,
  };

  const getPayload = () => {
    if (!hasApplied) {
      return INCOMPLETE_DATA;
    }

    return COMPLETED_DATA;
  };

  const stepPayload = getPayload();

  return (
    <>
      <Step {...stepPayload} />
      <NotInterestedModal
        missionId={missionId}
        open={isNotInterestedModalOpen}
        onClose={() => setIsNotInterestedModalOpen(false)}
        clientName={clientCompanyName || missionTitle}
      />
    </>
  );
};

export default observer(ApplyNowStep);
