import React, { ReactElement, useMemo } from 'react';
import { SidebarSection } from '@src/views/Profile/Sidebar/SidebarSection';
import {
  Colors,
  Icon,
  IconType,
  Tag,
  TagList,
  TextColors,
} from '@ateams/components';
import { Expertise } from '@src/stores/Profile/models';
import { ShowMoreButton } from '@src/views/VettingFeedbackForm/components/show-more';
import { createUseStyles } from 'react-jss';
import { getNumberFromSeed } from '@src/stores/utils';

interface Props {
  title?: string;
  skills: Expertise[];
  onChange?(): void;
  readonly?: boolean;
  isAdmin?: boolean;
  error?: boolean;
  disabled?: boolean;
  showColorByRating?: boolean;
  fontSize?: number;
  onRemove?: (skills: Expertise[]) => void;
  forceShowAll?: boolean;
  numberOfSkillsToShowByDefault?: number;
}

const useStyles = createUseStyles({
  tags: {
    marginTop: 16,
    flexWrap: 'wrap',
  },
  tag: {
    padding: 10,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
  },
  tagIcon: {
    marginRight: '0.5em',
    filter: 'grayscale(100%)',
  },
  divider: {
    color: '#D2D6DD',
    marginLeft: 8,
    marginRight: 8,
  },
});

const DEFAULT_NUMBER_OF_SKILLS_TO_SHOW = 4;

export const Skills = (props: Props): ReactElement => {
  const {
    title,
    readonly,
    skills,
    isAdmin,
    onChange,
    disabled,
    error,
    showColorByRating,
    fontSize,
    onRemove,
    forceShowAll,
    numberOfSkillsToShowByDefault,
  } = props;

  const styles = useStyles();
  const skillsSorted = useMemo(() => {
    return skills.sort((a, b) =>
      b.rating !== a.rating
        ? (b.rating ?? 0) - (a.rating ?? 0)
        : getNumberFromSeed(a.id) - getNumberFromSeed(b.id),
    );
  }, [skills]);

  const handleRemove = React.useCallback(
    (skill: Expertise) => () => {
      onRemove?.(skills.filter((item) => item.id !== skill.id));
    },
    [skills],
  );

  const getTagColor = (skill: Expertise) => {
    if (showColorByRating && !skill.confidence) {
      if ((skill?.rating || 0) >= 4) {
        return '#559C2B';
      } else if (skill.rating === 3) {
        return '#FE630C';
      } else {
        return '#F63131';
      }
    } else {
      return TextColors['tag'];
    }
  };

  const getTagBackgroundColor = (skill: Expertise) => {
    if (showColorByRating && !skill.confidence) {
      if ((skill?.rating || 0) >= 4) {
        return '#F6FCF3';
      } else if (skill.rating === 3) {
        return '#FFFAF7';
      } else {
        return '#FFF8F8';
      }
    } else {
      return Colors['backgroundLight'];
    }
  };

  const defaultVisibleSkillsCount =
    numberOfSkillsToShowByDefault || DEFAULT_NUMBER_OF_SKILLS_TO_SHOW;

  const showAll =
    forceShowAll ||
    (!!readonly && skillsSorted.length > defaultVisibleSkillsCount);
  const [numberOfSkillsToShow, setNumberOfSkillsToShow] = React.useState(
    forceShowAll ? Infinity : defaultVisibleSkillsCount,
  );

  const skillTags = useMemo(() => {
    let skillsToDisplay: Expertise[] = [];
    if (readonly) {
      skillsToDisplay = skillsSorted.slice(0, numberOfSkillsToShow);
    } else {
      skillsToDisplay = [...skillsSorted];
    }

    return skillsToDisplay.map((skill) => {
      return (
        <Tag
          key={skill.id}
          data-testing-id={'skill-tag'}
          className={styles.tag}
          style={{
            fontSize: fontSize || 12,
            color: getTagColor(skill),
            backgroundColor: getTagBackgroundColor(skill),
          }}
          onRemove={!readonly ? handleRemove(skill) : undefined}
          color="backgroundLight"
        >
          {skill.verifiedSkill && (
            <Icon
              data-testing-id={'skill-verified-icon'}
              type={IconType.ATeamGray}
              size={'exact'}
              style={{ marginRight: '0.5em' }}
            />
          )}
          {skill.confidence && (
            <Icon
              data-testing-id={'skill-verified-icon'}
              type={IconType.StarsPurple}
              size={'exact'}
              className={styles.tagIcon}
            />
          )}
          {showColorByRating &&
          !skill.confidence &&
          (skill?.rating || 0) <= 3 ? (
            <Icon
              type={IconType.Low}
              size="exact"
              style={{
                color: getTagColor(skill),
                marginRight: 8,
              }}
            />
          ) : null}
          {skill.name}
          {skill.rating !== undefined && (
            <>
              <span className={styles.divider}>|</span>
              {skill.rating}
            </>
          )}
          {isAdmin && skill.confidence !== undefined && (
            <>
              <span className={styles.divider}>|</span>
              {(skill.confidence * 100).toFixed(0)}%
            </>
          )}
        </Tag>
      );
    });
  }, [skillsSorted, numberOfSkillsToShow, readonly]);

  const isShowingMore = showAll && skillTags.length === skillsSorted.length;

  const handleShowAll = () => {
    if (forceShowAll) {
      return;
    }
    if (isShowingMore) {
      setNumberOfSkillsToShow(defaultVisibleSkillsCount);
    } else {
      setNumberOfSkillsToShow(skills.length);
    }
  };

  return (
    <>
      {title ? (
        <SidebarSection
          title={title}
          onAddClick={onChange}
          readonly={readonly}
          disabled={disabled}
          error={error}
          id="skills-section-profile"
        >
          <TagList className={styles.tags}>{skillTags}</TagList>
          {showAll && (
            <ShowMoreButton
              showMoreLabel="Show all"
              showLessLabel="Show less"
              isShowingMore={isShowingMore}
              onClick={handleShowAll}
              style={{
                marginTop: 16,
                ...(forceShowAll ? { color: Colors.regular } : {}),
              }}
            />
          )}
        </SidebarSection>
      ) : (
        <>
          <TagList style={{ marginTop: -5, flexWrap: 'wrap' }}>
            {skillTags}
          </TagList>
          {showAll && (
            <ShowMoreButton
              showMoreLabel="Show all"
              showLessLabel="Show less"
              isShowingMore={isShowingMore}
              onClick={handleShowAll}
              style={{
                marginTop: 16,
                ...(forceShowAll ? { color: Colors.regular } : {}),
              }}
            />
          )}
        </>
      )}
    </>
  );
};
