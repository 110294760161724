import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import {
  AdminNote,
  AdminNoteCategory,
  AdminNoteId,
  InterviewAdminNote,
} from '@a_team/models/dist/AdminNotesObject';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import SectionHeading from '@src/components/SectionHeading';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import Profile from '@src/stores/Profile/Profile';
import { createUseStyles } from 'react-jss';
import { AddNoteCard } from './AddNoteCard';
import { AdminNoteCard } from './AdminNoteCard';
import { AdminPitch } from './AdminPitch';
import { TabItem, Tabs } from '@ateams/components';
import { groupBy } from 'lodash';
import { BuilderRatings } from './BuilderRatings/BuilderRatings';
import { scrollToElementById } from '@src/logic/utils';
import { InterviewFeedbackCard } from './InterviewFeedbackCard';

interface Props {
  profile: Profile;
  authUsername: string | null;
}

const useStyles = createUseStyles({
  headingText: {
    fontSize: 20,
    fontWeight: 600,
    color: '#000',
    display: 'inline-block',
  },
  notesListHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 1.34em 1.34em 0',
  },
  filterTabsContainer: {
    fontSize: 12,
  },
  reason: {
    marginBottom: 16,
  },
});

const baseTabs: TabItem[] = [
  {
    content: `Admin`,
    id: AdminNoteCategory.Admin,
  },
  {
    content: `Migration`,
    id: AdminNoteCategory.Migration,
  },
  {
    content: `TeamPulse`,
    id: AdminNoteCategory.TeamPulse,
  },
  {
    content: `Interview Feedback`,
    id: AdminNoteCategory.InterviewFeedback,
  },
  {
    content: `Mission Feedback`,
    id: AdminNoteCategory.MissionFeedback,
  },
  {
    content: `Application Review`,
    id: AdminNoteCategory.ApplicationReview,
  },
  {
    content: `Evaluation`,
    id: AdminNoteCategory.Vetting,
  },
  {
    content: `Proposal Blurb`,
    id: AdminNoteCategory.ProposalBlurb,
  },
];

const baseFilterTabs = [
  {
    content: 'All',
    id: 'all',
  },
  ...baseTabs,
];

function NotesTab({ profile, authUsername }: Props): ReactElement {
  const styles = useStyles();
  const [removalNote, setRemovalNote] = useState<AdminNoteId | null>(null);
  const [loading, setLoading] = useLoadingState();
  const [filterTabs, setFilterTabs] = useState<TabItem[]>(baseFilterTabs);
  const [activeFilterTab, setActiveFilterTab] = useState<TabItem>(
    baseFilterTabs[0],
  );

  const sortedNotes = useMemo(() => {
    return (
      profile.adminNotes?.data.notes
        .slice()
        .sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ) ?? []
    );
  }, [profile.adminNotes]);

  const groupedNotes = useMemo(
    () => groupBy(sortedNotes, 'category'),
    [profile.adminNotes],
  );

  useEffect(() => {
    if (groupedNotes && profile.highlightedAdminNoteId) {
      scrollToElementById(profile.highlightedAdminNoteId);
    }
    return () => {
      profile.setHighlightedAdminNoteId(undefined);
    };
  }, [profile.highlightedAdminNoteId, groupedNotes]);

  useEffect(() => {
    setFilterTabs((currentFilterTabs) => {
      return currentFilterTabs.map((tab, idx) => ({
        ...tab,
        content: `${baseFilterTabs[idx].content} ${
          tab.id === 'all'
            ? sortedNotes.length
            : groupedNotes[tab.id]?.length || 0
        }`,
      }));
    });
  }, [groupedNotes]);

  const notesForSelectedCategory: AdminNote[] =
    (activeFilterTab.id !== 'all'
      ? groupedNotes[activeFilterTab.id]
      : sortedNotes) || [];

  const updateAdminPitch = (pitch: string): void => {
    if (profile) {
      setLoading(
        profile.updateAdminNotes({ pitch }),
        'Admin pitch was updated.',
      );
    }
  };

  const updateNotes = (
    note: string,
    noteId?: AdminNoteId,
    noteCategory?: AdminNoteCategory,
  ): Promise<void> => {
    return new Promise((res, rej) => {
      if (profile) {
        setLoading(
          profile
            .updateAdminNotes({ note, noteId, noteCategory })
            .catch(() => {
              rej();
              throw new Error('Failed to update notes.');
            })
            .then(() => res()),
          noteId ? 'Selected note was updated.' : '',
        );
      } else {
        rej();
      }
    });
  };

  const handleDelete = (): void => {
    if (removalNote && profile) {
      setLoading(
        profile.removeAdminNote(removalNote).then(() => setRemovalNote(null)),
        'Selected note was deleted.',
      );
    }
  };

  return (
    <>
      <ConfirmModal
        open={removalNote !== null}
        onClose={() => setRemovalNote(null)}
        title={'Remove Note'}
        description={
          'Removing this note will permanently delete it from the list of notes.'
        }
        actionColor="danger"
        actionLabel="Remove"
        onConfirm={handleDelete}
      />

      <BuilderRatings userId={profile.data.uid} />

      <AdminPitch
        fullName={profile.data.fullName}
        pitch={profile.adminNotes?.data.pitch}
        onSave={updateAdminPitch}
      />

      <div>
        <SectionHeading isFirst className={styles.headingText}>
          Add New Note
        </SectionHeading>
        <AddNoteCard
          tabs={baseTabs}
          onSave={(text, category) => updateNotes(text, undefined, category)}
        />
      </div>
      <LoadingIndicator loading={loading} />
      {(profile.adminNotes?.data.scrubbedMinusOneReasons ?? []).length > 0 && (
        <div>
          <SectionHeading className={styles.headingText}>
            Scrubbed as -1 reasons
          </SectionHeading>
          <ul>
            {profile.adminNotes?.data.scrubbedMinusOneReasons?.map((reason) => (
              <li key={reason.text} className={styles.reason}>
                {reason.text}
              </li>
            ))}
          </ul>
        </div>
      )}
      {sortedNotes.length > 0 && (
        <div style={{ paddingTop: '40px' }}>
          <div className={styles.notesListHeader}>
            <div>
              <SectionHeading isFirst className={styles.headingText}>
                {`${notesForSelectedCategory.length} Note${
                  notesForSelectedCategory.length === 1 ? '' : 's'
                }`}
              </SectionHeading>
            </div>
            <div className={styles.filterTabsContainer}>
              <Tabs
                items={filterTabs}
                onClickOnItem={(item) => setActiveFilterTab(item)}
                selectedItem={activeFilterTab}
              ></Tabs>
            </div>
          </div>
          {notesForSelectedCategory.map((note) =>
            note.category === AdminNoteCategory.InterviewFeedback ? (
              <InterviewFeedbackCard
                key={note.nid}
                note={note as InterviewAdminNote}
                highlight={note.nid === profile.highlightedAdminNoteId}
                username={profile.data.username}
              />
            ) : (
              <AdminNoteCard
                username={profile.data.username}
                highlight={note.nid === profile.highlightedAdminNoteId}
                note={note}
                key={note.nid}
                editable={note.author.username === authUsername}
                onSave={updateNotes}
                onDelete={() => setRemovalNote(note.nid)}
              />
            ),
          )}
        </div>
      )}
    </>
  );
}

export default observer(NotesTab);
