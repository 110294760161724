import { AsyncSegmentBrowserAnalytics } from '../common/AsyncSegmentBrowserAnalytics';
import { PlatformServiceAnalytics } from './PlatformServiceAnalytics';

/** Creates a fully configured PlatformServiceAnalytics instance */
export const createPlatformServiceBrowserAnalytics = (
  analytics: () => SegmentAnalytics.AnalyticsJS = () => window.analytics,
  appName: string,
): PlatformServiceAnalytics =>
  new PlatformServiceAnalytics(
    new AsyncSegmentBrowserAnalytics(analytics),
    appName,
  );
