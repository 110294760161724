import { AccountId } from '@a_team/models/dist/Account';
import { FeatureFlagNames } from '@a_team/models/dist/FeatureFlag';
import { UserId } from '@a_team/models/dist/UserObject';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const usersKeys = createQueryKeys('users', {
  usersByIds: (uids: UserId[]) => ({
    uids,
  }),
  evaluateFeatureFlag: (uid: string, featureFlag: FeatureFlagNames) => ({
    uid,
    featureFlag,
  }),
  evaluateFeatureFlagForAccount: (
    accountId: AccountId,
    featureFlag: FeatureFlagNames,
    uid: string,
  ) => ({
    accountId,
    featureFlag,
    uid,
  }),
  userRolesWithStatus: (uid: string) => ({
    uid,
  }),
  evaluationCallDetails: (uid: string) => ({
    uid,
  }),
  profileCompletion: (uid: string) => ({
    uid,
  }),
  userPreferences: (uid: string) => ({
    uid,
  }),
  lastResumeParsed: (uid: string) => ({
    uid,
  }),
});
