import { Icon } from '@a_team/ui-components';
import { Colors, Icon as IconPlatform, IconType } from '@ateams/components';
import { Breakpoints } from '@ateams/components';
import { LoadingState } from '@src/hooks/useLoadingState';
import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import resumeIcon from './resume-icon.svg';

const useStyles = createUseStyles<{
  error: boolean;
  showRemoveButton: boolean;
}>({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '8px',
    padding: '18px 16px',
    border: ({ error }) => `1px solid ${error ? '#FE630C' : '#DADADC'}`,
    borderRadius: '8px',
    justifyContent: 'space-between',
    zIndex: 1,
    position: 'relative',
    background: 'white',
    flexDirection: ({ showRemoveButton }) =>
      showRemoveButton ? 'row' : 'column',
  },
  resumeLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  actionButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: '#7000E3',
  },
  resumeLabel: {
    color: '#7000E3',
  },
  removeButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',

    '& svg': {
      cursor: 'pointer !important',
    },
  },
  uploading: {
    color: '#B5B8BF',
  },
  successMessage: {
    background: '#F6FCF3',
    fontSize: '12px',
    color: '#559C2B',
    padding: '24px 16px 16px 16px',
    borderRadius: '8px',
    marginTop: '-8px',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '8px',
  },
  infoMessage: {
    background: '#FCFAFF',
    fontSize: '12px',
    color: Colors.secondaryDark,
    padding: '24px 16px 16px 16px',
    borderRadius: '8px',
    marginTop: '-8px',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '8px',
  },
  '@keyframes spin': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  loadingIcon: {
    animation: '$spin 1s linear infinite',
    display: 'inline-block',
  },
  divider: {
    opacity: 0.5,
    color: '#A54CFF',
  },
  resumeIcon: {
    width: '16px',
    height: '16px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      flexDirection: 'row !important',
      alignItems: 'center',
    },
  },
});

interface ResumeItemProps {
  resumeLabel: string;
  resumeDownloadUrl: string;
  showSuccessMessage: boolean;
  isResumeOlderThanThreeMonths: boolean;
  uploading: LoadingState;
  mostRecentExperience?: string;
  onRemoveResume: () => void;
  onUpdateResume: () => void;
  onUpdateUploadedAt: () => void;
  isUpToDateConfirmation?: boolean;
}

const ResumeItem = ({
  resumeLabel,
  resumeDownloadUrl,
  showSuccessMessage,
  isResumeOlderThanThreeMonths,
  uploading,
  mostRecentExperience,
  onUpdateResume,
  onRemoveResume,
  onUpdateUploadedAt,
  isUpToDateConfirmation = false,
}: ResumeItemProps): ReactElement => {
  const styles = useStyles({
    error: isResumeOlderThanThreeMonths && !uploading,
    showRemoveButton: !uploading && !isResumeOlderThanThreeMonths,
  });

  return (
    <>
      <div className={styles.container}>
        <div className={styles.resumeLabelContainer}>
          {uploading ? (
            <Icon name="loading" size="md" className={styles.loadingIcon} />
          ) : (
            <img src={resumeIcon} alt="Resume" className={styles.resumeIcon} />
          )}
          <a
            className={styles.resumeLabel}
            href={resumeDownloadUrl}
            target="_blank"
            rel="noreferrer"
          >
            {resumeLabel}
          </a>
        </div>
        {uploading ? (
          <div className={styles.uploading}>Uploading...</div>
        ) : isResumeOlderThanThreeMonths ? (
          <div>
            <button
              className={styles.actionButton}
              onClick={onUpdateUploadedAt}
            >
              Yes, it's up to date
            </button>
            <span className={styles.divider}>|</span>
            <button className={styles.actionButton} onClick={onUpdateResume}>
              No, upload a new version
            </button>
          </div>
        ) : (
          <button
            className={styles.removeButton}
            onClick={onRemoveResume}
            aria-label="Remove resume"
          >
            <Icon name="delete" size="md" color="Red@600" />
          </button>
        )}
      </div>
      {showSuccessMessage ? (
        <div className={styles.successMessage}>
          <Icon color="Green@800" name="statusPositiveNoBorder" size="sm" />
          {isUpToDateConfirmation
            ? 'Your resume is up to date.'
            : 'Your resume has been uploaded successfully.'}
        </div>
      ) : mostRecentExperience && isResumeOlderThanThreeMonths ? (
        <div className={styles.infoMessage}>
          <IconPlatform size="smaller" type={IconType.SuggestedTeams} />
          The most recent experience on your resume: {mostRecentExperience}
        </div>
      ) : null}
    </>
  );
};

export default observer(ResumeItem);
