import { PendingPaymentTerm } from '@a_team/models/dist/MissionRole';
import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  BorderRadius,
  Colors,
  Spacing,
  ToggleSwitch,
} from '@ateams/components';
import TooltipWrapped from '@src/components/TooltipWrapped';
import { format } from 'date-fns';

const useStyles = createUseStyles({
  row: {
    display: 'flex',

    '& > *': {
      width: '50%',
      margin: 0,
    },
    '& > * + *': {
      marginLeft: '16px',
    },
  },
  bgWrapper: {
    backgroundColor: Colors.backgroundLight,
    padding: Spacing.medium,
    borderRadius: BorderRadius.medium,
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  rateChangeCopy: {
    marginTop: 8,
  },
  inlineRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    paddingTop: 16,
  },
});

interface PaymentTermProps {
  pendingPaymentTerm?: PendingPaymentTerm;
  isFullTimeRetainer?: boolean;
  disabled?: boolean;
  onChange(checked: boolean): void;
}

export const PaymentTerm: React.FC<PaymentTermProps> = (
  props: PaymentTermProps,
) => {
  const styles = useStyles();
  const { pendingPaymentTerm, onChange, disabled, isFullTimeRetainer } = props;

  return (
    <div className={styles.row} style={{ marginBottom: '16px' }}>
      <div className={styles.fullWidth}>
        <div
          className={styles.bgWrapper}
          style={{
            borderBottomLeftRadius: pendingPaymentTerm ? 0 : 8,
            borderBottomRightRadius: pendingPaymentTerm ? 0 : 8,
          }}
        >
          <span className={styles.inlineRow} style={{ paddingTop: 0 }}>
            <TooltipWrapped
              arrow
              position="top"
              title={
                pendingPaymentTerm
                  ? `The role will stay pending until all
                        participants sign the new agreement,
                        then it will update at the next billing cycle.`
                  : ''
              }
              style={{ width: 50, display: 'block' }}
            >
              <ToggleSwitch
                size={'small'}
                checkedBackgroundColor="secondary"
                onChange={onChange}
                checked={isFullTimeRetainer ?? false}
                disabled={disabled}
              />
            </TooltipWrapped>
            This role is being billed as a full time retainer
          </span>
          {pendingPaymentTerm && (
            <>
              <div className={styles.rateChangeCopy}>
                This role is changing from{' '}
                <strong>{isFullTimeRetainer ? 'Monthly' : 'Hourly'}</strong> to{' '}
                <strong>
                  {pendingPaymentTerm.type === 'monthly' ? 'Monthly' : 'Hourly'}
                </strong>
                , and will change on{' '}
                {pendingPaymentTerm.effectiveFrom && (
                  <strong>
                    {format(
                      new Date(pendingPaymentTerm.effectiveFrom),
                      'MMM dd, yyyy',
                    )}
                  </strong>
                )}
                , pending both parties sign the contract before that date.
                <span className={styles.inlineRow}>
                  <strong>New builder rate:</strong>$
                  {pendingPaymentTerm?.builderRate.toLocaleString()}/
                  {pendingPaymentTerm?.type === 'monthly' ? 'm' : 'h'}
                </span>
              </div>
            </>
          )}
        </div>
        {pendingPaymentTerm && (
          <div className={styles.row}>
            <div className={styles.fullWidth}>
              <div
                className={styles.bgWrapper}
                style={{
                  marginTop: 3,
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
              >
                <span className={styles.inlineRow} style={{ paddingTop: 8 }}>
                  <strong>Client document:</strong>
                  Not signed
                </span>
                <span className={styles.inlineRow} style={{ paddingTop: 8 }}>
                  <strong>Builder document:</strong>
                  Not signed
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
