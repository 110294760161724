import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { SidebarSection } from '@src/views/Profile/Sidebar/SidebarSection';
import { createUseStyles } from 'react-jss';
import {
  getLinkedinUsernameFromURL,
  isValidLinkedInUrl,
  withHttps,
} from '@src/helpers/urls';
import TextInput from '@src/components/Inputs/TextInput/TextInput';
import { Checkbox, Colors, TextColors } from '@ateams/components';
import useToggle from '@src/hooks/useToggle';
import cx from 'classnames';

interface Props {
  existingUrl?: string;
  isValid?: boolean;
  withLinkedinValidation?: boolean;
  onChange: (url?: string) => void;
}

enum Errors {
  Empty = `This field can't be empty`,
  Invalid = `This url looks invalid`,
}

const useStyles = createUseStyles({
  hint: {
    marginTop: 4,
    color: TextColors.regularLight,
    fontSize: 12,
  },
  input: {
    fontSize: '17px',
    color: TextColors.regular,
    fontWeight: '400',
    padding: '5px 0',
    marginTop: '5px',
    '&::placeholder': {
      color: Colors.regular,
    },
  },
  error: {
    marginTop: 4,
    color: TextColors.primaryLight,
    fontSize: 12,
  },
  container: {
    marginBottom: 0,
    position: 'relative',
    background: 'white',
  },
  containerError: {
    border: '1px solid #F69DA5',
    borderRadius: 8,
    padding: '16px',
  },
  linkInvalidHint: {
    background: '#FFF6F8',
    color: '#F63041',
    borderRadius: '0px 0px 8px 8px',
    padding: '24px 16px 16px 16px',
    marginTop: '-8px',
  },
  checkbox: {
    '& span': {
      color: TextColors.regular,
    },
  },
  wrapper: {
    marginBottom: '40px',
  },
});

export const LinkedInUrl = ({
  existingUrl,
  isValid,
  onChange,
  withLinkedinValidation,
}: Props): ReactElement => {
  const styles = useStyles();

  const [error, setError] = useState<string | undefined>(undefined);
  const [url, setUrl] = useState<string | undefined>(existingUrl);

  const hasLinkedInProfile = useMemo(
    () => isValidLinkedInUrl(existingUrl),
    [existingUrl],
  );
  const [disabled, setDisabled] = useToggle(!hasLinkedInProfile);

  useEffect(() => {
    // this effect is tied to a user who checked `I don't have LinkedIn` during onboarding.
    // it a user has checked `I don't have LinkedIn` we reset state to undefined otherwise the user can't submit profile without entering the URL
    if (disabled) {
      setError(undefined);
      onChange(undefined);
    } else if (!disabled && !hasLinkedInProfile) {
      setError(Errors.Empty);
      onChange('');
    }
  }, [disabled, hasLinkedInProfile]);

  const handleBlur = () => {
    if (url) {
      const updatedUrl = withHttps(url);
      handleChange(updatedUrl);
    }
  };

  const handleChange = (newUrl: string) => {
    setUrl(newUrl);
    const isValid = isValidLinkedInUrl(newUrl);
    let errorMessage: string | undefined;

    if (newUrl === '') {
      errorMessage = Errors.Empty;
    } else if (!isValid) {
      errorMessage = Errors.Invalid;
    }

    setError(errorMessage);

    if (isValid) {
      onChange(getLinkedinUsernameFromURL(newUrl));
    } else {
      onChange('');
    }
  };

  return (
    <div className={styles.wrapper}>
      <SidebarSection
        className={cx(styles.container, {
          [styles.containerError]: isValid === false && withLinkedinValidation,
        })}
        id="linkedin-section"
        title={'LinkedIn'}
        readonly
      >
        <p className={styles.hint}>
          Including your LinkedIn helps you to get relevant mission
          recommendations.
        </p>
        <TextInput
          key={`input-${disabled}`} /* ensures autofocus on checkbox state */
          variant="dashed"
          defaultValue={existingUrl}
          value={url}
          onInput={(e) => handleChange(e.currentTarget.value)}
          onBlur={handleBlur}
          autoFocus
          className={styles.input}
          disabled={disabled}
        />
        {error && <p className={styles.error}>{error}</p>}
        {!hasLinkedInProfile && (
          <Checkbox
            checked={disabled}
            className={styles.checkbox}
            onChange={setDisabled}
            color={Colors.secondaryDark}
            label="I don't have a LinkedIn"
          />
        )}
      </SidebarSection>
      {isValid === false && withLinkedinValidation && (
        <div className={styles.linkInvalidHint}>
          Your current LinkedIn URL appears to be invalid. Please update it to
          ensure your profile remains accurate.
        </div>
      )}
    </div>
  );
};
