import React, { ReactElement } from 'react';
import ContractObject, {
  ContractId,
  ContractPartyType,
} from '@a_team/models/dist/ContractObject';
import { ModalBasicProps } from '@src/components/Modal';
import MissionRole, {
  MissionRoleStatus,
} from '@a_team/models/dist/MissionRole';
import { ContractModal } from '@src/components/Modal/ContractModal/ContractModal';
import { AssignmentsBox } from '@src/components/Modal/ContractModal/AssignmentsBox';
import { createUseStyles } from 'react-jss';
import UserAvatar from '@src/components/UserAvatar';
import { getBuilderRate, getClientRate } from './utils';
import logo from './ateam.svg';
import {
  Colors,
  FontSizes,
  FontWeights,
  HelpTooltip,
  Spacing,
} from '@ateams/components';
import { round } from 'lodash';

interface Props extends ModalBasicProps {
  contract?: ContractObject;
  roles?: MissionRole[];
  onAccept(ids: ContractId[]): void;
  isAdmin: boolean;
}

const useStyles = createUseStyles({
  logo: {
    width: 64,
    marginBottom: Spacing.xLarge,
  },
  label: {
    fontWeight: 500,
    width: 150,
    minWidth: 150,
    display: 'inline-block',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    marginBottom: Spacing.xsmall,
    gap: Spacing.xsmall,
  },
  ateamer: {
    display: 'flex',
    gap: Spacing.small,
  },
  helpTooltip: {
    width: '240px !important',
    left: 'calc(50% - 38px)', // to offset the width

    '&::after': {
      left: '55% !important',
      marginLeft: '0 !important',
    },
  },
  miniHelpTooltip: {
    fontSize: FontSizes.small,
  },
  rateTooltip: {
    padding: 0,
  },
  tooltipTitle: {
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.small,
    marginTop: Spacing.xsmall,
    marginBottom: Spacing.small,
    textAlign: 'left',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.xsmall,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  break: {
    margin: 0,
    background: Colors.regularLight,
  },
  bold: {
    fontWeight: FontWeights.bold,
  },
});

export const MemberContractModal = (props: Props): ReactElement | null => {
  const styles = useStyles();
  const { contract, onClose, open, isAdmin } = props;

  const member = contract?.parties.find(
    (party) => party.type === ContractPartyType.MissionRole,
  );
  const client = contract?.parties.find(
    (party) => party.type === ContractPartyType.BillingCustomer,
  );
  const role = props.roles?.find((role) => role.rid === member?.rid);

  const pendingPaymentTerm =
    role?.status === MissionRoleStatus.ScheduledToSwitch
      ? role.pendingPaymentTerm
      : undefined;

  let platformFee =
    role?.clientHourlyRate && role?.hourlyRate
      ? (
          ((role.clientHourlyRate - role.hourlyRate) / role.clientHourlyRate) *
          100
        ).toFixed(2)
      : 0;

  if (pendingPaymentTerm) {
    platformFee = pendingPaymentTerm.margin;
  }

  const clientRate = getClientRate(role);
  const builderRate = getBuilderRate(role);
  const isFullTimeRetainer = pendingPaymentTerm
    ? pendingPaymentTerm.type === 'monthly'
    : !!role?.isFullTimeRetainer;

  return (
    <ContractModal
      loading={!contract}
      onClose={onClose}
      open={open}
      acceptedContracts={contract ? [contract.sid] : []}
      contractUrl={contract?.downloadURL}
      onAccept={props.onAccept}
      allowButtonEnabled={true}
      isFullTimeRetainer={isFullTimeRetainer}
    >
      <img src={logo} alt="logo" className={styles.logo} />
      <AssignmentsBox
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div>
          <p className={styles.section}>
            <span className={styles.row}>
              <span className={styles.label}>A.Teamer:</span>
              <div className={styles.ateamer}>
                <UserAvatar
                  src={member?.user?.profilePictureURL}
                  size={32}
                  containerStyle={{ marginTop: -5 }}
                />
                {member?.user?.fullName}
              </div>
            </span>
          </p>
          <p className={styles.section}>
            <span className={styles.row}>
              <span className={styles.label}>Client Name:</span>{' '}
              {client?.address?.name}
            </span>
            <span className={styles.row}>
              <span className={styles.label}>Assignment:</span> A.Team for{' '}
              {client?.address.name}
            </span>
            <span className={styles.row}>
              <span className={styles.label}>Role:</span> {role?.category.title}
            </span>
            <span className={styles.row}>
              <span className={styles.label}>
                {isFullTimeRetainer ? 'Monthly' : 'Hourly'} rate:
              </span>
              <span>${builderRate}</span>
              {!isAdmin && isFullTimeRetainer && (
                <HelpTooltip className={styles.helpTooltip}>
                  <div className={styles.rateTooltip}>
                    <h4 className={styles.tooltipTitle}>
                      Monthly rate calculation
                    </h4>
                    <div className={styles.table}>
                      <div className={styles.item}>
                        <span>Monthly rate</span>
                        <span>{`$${round(
                          role?.hourlyRate ?? 0,
                          2,
                        ).toLocaleString()}/hr`}</span>
                      </div>
                      <div className={styles.item}>
                        <span>Hours per week</span>
                        <span>x 40</span>
                      </div>
                      <div className={styles.item}>
                        <span>Average weeks in a month</span>
                        <span>x 4.33</span>
                      </div>
                      <hr className={styles.break} />
                      <div className={styles.item}>
                        <span className={styles.bold}>Monthly rate</span>
                        <span className={styles.bold}>
                          ${builderRate.toLocaleString()}
                          /mo
                        </span>
                      </div>
                    </div>
                  </div>
                </HelpTooltip>
              )}
              {isAdmin && (
                <>
                  (Billed to client: ${clientRate}, A.Team service fee{' '}
                  {platformFee}%)
                </>
              )}
            </span>
            {isFullTimeRetainer && (
              <span className={styles.row}>
                <span className={styles.label}>Minimum Duration: </span>
                <span>1 month</span>
                <HelpTooltip className={styles.miniHelpTooltip}>
                  Minimum notice for termination 15 days.
                </HelpTooltip>
              </span>
            )}
          </p>
        </div>
      </AssignmentsBox>
    </ContractModal>
  );
};
