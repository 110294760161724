import {
  BorderRadius,
  Colors,
  FontWeights,
  Icon,
  IconType,
  Paddings,
  Spacing,
} from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { round } from 'lodash';

interface RateRangeProps {
  showHourlyBudget: boolean;
  showMonthlyBudget: boolean;
  builderRateMin: number;
  builderRateMax: number;
  builderMonthlyRateMin: number;
  builderMonthlyRateMax: number;
}

const useStyles = createUseStyles({
  root: {
    marginBottom: Spacing.large,
    display: 'flex',
    flextDirection: 'row',
    gap: Spacing.medium,
  },
  preferredRateWrapper: {
    background: Colors.backgroundLight,
    borderRadius: BorderRadius.medium,
    padding: Paddings.medium,
    fontWeight: FontWeights.semiBold,
    display: 'flex',
    alignItems: 'center',
  },
  cashIcon: {
    marginRight: '10px',
  },
});

function RateRangeV2({
  showHourlyBudget,
  showMonthlyBudget,
  builderRateMin,
  builderRateMax,
  builderMonthlyRateMin,
  builderMonthlyRateMax,
}: RateRangeProps): JSX.Element {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {showHourlyBudget && (
        <div className={styles.preferredRateWrapper}>
          <Icon
            type={IconType.Cash}
            size={'exact'}
            className={styles.cashIcon}
          />
          Hourly budget of ${Math.round(builderRateMin)} — $
          {Math.round(builderRateMax)}
        </div>
      )}
      {showMonthlyBudget && (
        <div className={styles.preferredRateWrapper}>
          <Icon
            type={IconType.Cash}
            size={'exact'}
            className={styles.cashIcon}
          />
          Monthly budget of ${round(builderMonthlyRateMin).toLocaleString()} — $
          {round(builderMonthlyRateMax).toLocaleString()}
        </div>
      )}
    </div>
  );
}

export default RateRangeV2;
