import React, {
  CSSProperties,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createUseStyles } from 'react-jss';
import { UserUsername } from '@a_team/models/dist/UserObject';
import {
  SelectableBuilder,
  TargeterSearchCache,
} from '@src/stores/TeamGraph/TargeterTabManager';
import { RoleFilter } from '@src/components/TeamGraphBuilderQuery';
import Modal from '@src/components/Modal';
import NoResults from '@src/components/NoResults';
import TextButton from '@src/components/TextButton';
import TargeterUserCard from '@src/views/SkillTargeter/TargeterUserCardView';
import { apiTalentSkills, apiTalentSpecializations } from '@ateams/api';
import { useStores } from '@src/stores';
import {
  TalentProfile,
  TalentSpecialization,
  UserTalentSkillAssignment,
} from '@a_team/models/dist/TalentCategories';
import { QueryNextToken } from '@a_team/models/dist/misc';
import AuthStore from '@src/stores/Auth';
import queryString, { StringifiableRecord } from 'query-string';
import { getTargeterUrlFromQuery } from '../tabTransformations';
import { useGetApplicantFlags, useGetProfileByUsername } from '@src/rq/profile';
import { EvaluationScoresBanner } from '@src/views/Profile/Sidebar/EvaluationScoresBanner';
import { BuilderFlagsBanner } from '@src/views/Profile/Sidebar/BuilderFlagsBanner';
import {
  BorderColors,
  Divider,
  Icon,
  IconType,
  Spacing,
} from '@ateams/components';
import Dropdown, { DropdownItem } from '@src/components/Dropdown';
import { useGetUsersByIds } from '@src/rq/users';

interface Props {
  filter: RoleFilter;
  builders: SelectableBuilder[];
  metadata?: TargeterSearchCache['metadata'];
  selectedBuilderCount: number;
  hiddenBuilders: string[];
  handleBuilderSelect: (user: UserUsername, selected?: boolean) => void;
  toggleHideBuilder: (uid: string) => void;
  clearSelectedBuilders: () => void;
  clearHiddenBuilders: () => void;
  clearFilters: () => void;
  awaitingBuilders?: boolean;
}

// Modals require style prop, not className
const modalStyles: CSSProperties = {
  padding: 0,
  margin: 0,
  border: 'none',
  height: '85%',
  width: '85%',
};

const useStyles = createUseStyles({
  profileTitle: {
    height: '4em',
    lineHeight: '4em',
    textAlign: 'center',
    borderBottom: '1px solid gray',
  },
  profileLink: {
    fontWeight: 600,
  },
  profileFrame: {
    height: '100%',
    width: '100%',
    padding: 0,
    margin: 0,
    border: 'none',
  },
  countMessage: {
    marginLeft: '40px',
  },
  countMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Spacing.medium,
    marginLeft: '40px',
  },
  selectionCountMessage: {
    minHeight: 26,
    display: 'flex',
    alignItems: 'center',
  },
  hiddenCountMessageContainer: {
    minHeight: 26,
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.small,
    position: 'relative',
  },
  hiddenCountMessage: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: Spacing.small,
  },
  hiddenCountMessageIcon: {
    cursor: 'pointer',
  },
  messageCountSeparator: {
    height: 16,
    marginLeft: Spacing.medium,
    marginRight: Spacing.medium,
    border: `1px solid ${BorderColors.light}`,
  },
  dropdown: {
    position: 'absolute',
    left: 0,
    top: '100%',
    zIndex: 1000,
    marginTop: Spacing.small,
    backgroundColor: 'white',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  },
  hiddenBuilderItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: Spacing.small,
  },
  hiddenBuilderItemIcon: {
    cursor: 'pointer',
  },
});

export const getAllSpecializations = async (
  auth: AuthStore,
  setSpecializationMap: (s: Map<string, TalentSpecialization>) => void,
): Promise<void> => {
  let next: QueryNextToken | undefined | null = undefined;
  const specializations: Array<[string, TalentSpecialization]> = [];
  do {
    const { items, next: nextToken } =
      await apiTalentSpecializations.queryTalentSpecializations(auth, {}, next);
    specializations.push(
      ...items.map((role) => [role.id, role] as [string, TalentSpecialization]),
    );
    next = nextToken as QueryNextToken | null;
  } while (next);

  setSpecializationMap(new Map(specializations));
};

const TargeterCardList = (props: Props): ReactElement => {
  const { auth, users } = useStores();

  const styles = useStyles();
  const {
    filter,
    builders,
    metadata,
    selectedBuilderCount,
    hiddenBuilders,
    clearFilters,
    clearSelectedBuilders,
    clearHiddenBuilders,
    handleBuilderSelect,
    awaitingBuilders,
    toggleHideBuilder,
  } = props;

  const {
    pageSize = 0,
    totalPages = 0,
    totalRecords = 0,
    currentPage = 0,
    sunsettedBuildersCount = 0,
    suppressedBuildersCount = 0,
  } = metadata || {};
  const [profile, setProfile] = useState<UserUsername | undefined>();
  const { data: hiddenBuildersUsers } = useGetUsersByIds(auth, hiddenBuilders);

  const [specializationMap, setSpecializationMap] =
    useState<Map<string, TalentSpecialization>>();
  const [skillMap, setSkillMap] =
    useState<Map<string, UserTalentSkillAssignment>>();

  const { data: profileUserObject } = useGetProfileByUsername(profile);
  const { data: applicantFlags, isLoading: applicantFlagsLoading } =
    useGetApplicantFlags(profileUserObject?.uid || '');

  const [isHiddenBuildersDropdownOpen, setIsHiddenBuildersDropdownOpen] =
    useState(false);

  const toggleHiddenBuildersDropdown = (open?: boolean) => {
    setIsHiddenBuildersDropdownOpen(
      open !== undefined ? open : !isHiddenBuildersDropdownOpen,
    );
  };

  useEffect(() => {
    getAllSpecializations(auth, setSpecializationMap);

    // query talent skills does not support pagination
    apiTalentSkills.queryTalentSkills({}).then(({ items }) => {
      const skills: Array<[string, UserTalentSkillAssignment]> = items.map(
        ({ id, name }) =>
          [id, { talentSkillId: id, talentSkillName: name }] as [
            string,
            UserTalentSkillAssignment,
          ],
      );
      setSkillMap(new Map(skills));
    });
  }, []);

  const selectedUsers = useMemo(() => {
    return builders
      .filter(({ select }) => select)
      .map(({ username }) => username);
  }, [builders]);

  if (!(specializationMap && skillMap)) return <></>;

  const expandTalentProfile = (
    profile: TalentProfile,
    specializationMap: Map<string, TalentSpecialization>,
  ): TalentProfile => ({
    talentSpecializations: {
      mainTalentSpecialization:
        profile.talentSpecializations?.mainTalentSpecialization ||
        specializationMap?.get(profile.mainTalentSpecializationId as string),
      additionalTalentSpecializations:
        profile.talentSpecializations?.additionalTalentSpecializations ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (profile.additionalTalentSpecializationIds ?? []).map((role: string) =>
          specializationMap?.get(role),
        ),
    },
    talentSkills: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mainTalentSkills: (profile.talentSkills?.mainTalentSkills ?? []).map(
        ({ talentSkillId, rating }: UserTalentSkillAssignment) => ({
          ...skillMap.get(talentSkillId as string),
          rating,
        }),
      ),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      additionalTalentSkills: (
        profile.talentSkills.additionalTalentSkills ?? []
      ).map(({ talentSkillId, rating }: UserTalentSkillAssignment) => ({
        ...skillMap.get(talentSkillId as string),
        rating,
      })),
    },
  });

  const onUserCardClick = (user: SelectableBuilder, index: number): void => {
    setProfile(user.username);

    const query = queryString.stringify(filter as StringifiableRecord, {
      arrayFormat: 'bracket',
    });
    const url = new URL(
      getTargeterUrlFromQuery(query),
      window?.location?.href,
    ).toString();
    users.trackTargeterBuilderCardClicked(
      user.uid,
      user.username,
      index + 1,
      totalRecords,
      url,
    );
  };

  return (
    <>
      <Modal
        open={!!profile}
        onClose={() => setProfile(undefined)}
        style={modalStyles}
      >
        <>
          <div className={styles.profileTitle}>
            Previewing{' '}
            <a
              className={styles.profileLink}
              href={profile}
              target="_blank"
              rel="noreferrer"
            >
              {profile}
            </a>
          </div>
          {profileUserObject?.expertiseScore ? (
            <EvaluationScoresBanner
              scores={{
                accent: profileUserObject.accentScore,
                interactionExperience:
                  profileUserObject.interactionExperienceScore,
                english: profileUserObject.englishScore,
                expertise: profileUserObject.expertiseScore,
              }}
            />
          ) : null}
          {applicantFlags && !applicantFlagsLoading ? (
            <BuilderFlagsBanner
              customTags={profileUserObject?.customTags}
              flags={applicantFlags}
            />
          ) : null}
          <Divider margin="none" />
          <iframe
            className={styles.profileFrame}
            title={`Preview ${profile}`}
            src={`${profile}?preview=1`}
          />
        </>
      </Modal>
      {builders && builders.length > 0 ? (
        <>
          <span className={styles.countMessage}>
            <>
              <strong>{totalRecords}</strong> builders (including{' '}
              <strong>{suppressedBuildersCount}</strong> suppressed and{' '}
              <strong>{sunsettedBuildersCount}</strong> sunsetted) match this
              query.
              <p>
                Showing page <strong>{currentPage}</strong> of{' '}
                <strong>{totalPages}</strong> with <strong>{pageSize}</strong>{' '}
                results per page.
              </p>
            </>
          </span>

          <div className={styles.countMessageContainer}>
            <div className={styles.selectionCountMessage}>
              {selectedBuilderCount > 0 ? (
                <span>
                  {`${selectedBuilderCount} builders selected. `}
                  <TextButton onClick={clearSelectedBuilders} color="primary">
                    Clear
                  </TextButton>
                </span>
              ) : (
                <></>
              )}
            </div>

            {selectedBuilderCount > 0 &&
              hiddenBuildersUsers &&
              hiddenBuildersUsers.length > 0 && (
                <div className={styles.messageCountSeparator}></div>
              )}

            {hiddenBuildersUsers && hiddenBuildersUsers.length > 0 && (
              <div className={styles.hiddenCountMessageContainer}>
                <span
                  onClick={() => toggleHiddenBuildersDropdown()}
                  className={styles.hiddenCountMessage}
                >
                  {`${hiddenBuildersUsers.length} builders hidden`}
                  <Icon
                    type={
                      isHiddenBuildersDropdownOpen
                        ? IconType.ArrowUp
                        : IconType.ArrowDownBlack
                    }
                    size="xsmall"
                    className={styles.hiddenCountMessageIcon}
                  />
                </span>

                {isHiddenBuildersDropdownOpen && (
                  <Dropdown
                    open={true}
                    onClose={() => toggleHiddenBuildersDropdown(false)}
                    className={styles.dropdown}
                  >
                    {hiddenBuildersUsers?.map((user) => (
                      <DropdownItem
                        key={user.uid}
                        onClick={() => {
                          toggleHideBuilder(user.uid);
                          toggleHiddenBuildersDropdown(false);
                        }}
                        className={styles.hiddenBuilderItem}
                      >
                        {user.fullName}
                        <Icon
                          type={IconType.Close}
                          size="xsmall"
                          className={styles.hiddenBuilderItemIcon}
                        />
                      </DropdownItem>
                    ))}
                  </Dropdown>
                )}

                <TextButton onClick={clearHiddenBuilders} color="primary">
                  Clear
                </TextButton>
              </div>
            )}
          </div>

          <ul data-testing-id={'targeter-result-cards-list'}>
            {builders
              .map((user) => ({
                ...user,
                talentProfile: expandTalentProfile(
                  user.talentProfile as never,
                  specializationMap,
                ),
              }))
              .map((user, index) => (
                <TargeterUserCard
                  filter={filter}
                  user={user}
                  withLinkedInRecommendations={auth.withLinkedInRecommendations}
                  selected={selectedUsers.includes(user.username)}
                  selectBuilder={() =>
                    handleBuilderSelect(user.username, !user.select)
                  }
                  hideBuilder={() => toggleHideBuilder(user.uid)}
                  key={`${user.uid}-${index}`}
                  onClick={() => onUserCardClick(user, index)}
                />
              ))}
          </ul>
        </>
      ) : (
        <>
          {awaitingBuilders ? (
            <div>Sit tight! We're finding your builders.</div>
          ) : (
            <NoResults
              imageType="noSearchResults"
              title="No results found!"
              text={
                <>
                  <p>Sorry, we didn't find any builders that fit your needs.</p>
                  <p>
                    Please update your selected filters or{' '}
                    <TextButton onClick={clearFilters} color="primary">
                      start again
                    </TextButton>
                    .
                  </p>
                </>
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default TargeterCardList;
