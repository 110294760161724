import { Button, Modal } from '@a_team/ui-components';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { Breakpoints, Colors } from '@ateams/components';
import { useReportLinkedInError, useUpdatePersonalInfo } from '@src/rq/users';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface LinkedinReportProblemModalProps {
  onClose: () => void;
  isOpen: boolean;
  defaultLinkedinUsername?: string;
  userId: string;
}

const useStyles = createUseStyles<{
  isOpen: boolean;
  isInputEnabled: boolean;
}>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
  },
  modal: {
    '&&': {
      width: '100%',
      maxWidth: '100%',
    },
    '& > div:first-child': {
      padding: '32px 24px 24px 24px !important',
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
    textAlign: 'left',
    marginBottom: '8px',
    lineHeight: '30px',
  },
  desc: {
    textAlign: 'left',
    marginBottom: '16px',
    color: Colors.regularDark,
  },
  link: {
    color: '#7000E3',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  buttonContainer: {
    display: 'flex',
    gap: '16px',
    width: '100%',
    '& > button': {
      flex: 1,
      height: 40,
    },
  },
  inputWrapper: {
    width: '100%',
  },
  linkedinInputBox: {
    border: '1px solid #E5E7EB',
    borderRadius: '8px',
    padding: '8px 12px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  linkedinIcon: {
    width: '24px',
    height: '24px',
  },
  linkedinUrlContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
  },
  linkedinInput: {
    border: 'none',
    outline: 'none',
    padding: '4px 4px 4px 0px',
    fontSize: '16px',
    width: '100%',
    minWidth: 0,
    opacity: ({ isInputEnabled }) => (isInputEnabled ? 1 : 0.5),
    cursor: ({ isInputEnabled }) => (isInputEnabled ? 'text' : 'not-allowed'),
  },

  [`@media (min-width: 1024px)`]: {
    modal: {
      '&&': {
        width: '680px !important',
        maxHeight: '650px !important',
        maxWidth: '680px',
        '& > div': {
          padding: '40px !important',
        },
      },
      '& > div': {
        padding: '40px !important',
      },
      '& > div:last-of-type': {
        display: 'none',
      },
    },
  },

  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      gap: '40px',
    },
    title: {
      textAlign: 'center',
    },
    desc: {
      textAlign: 'center',
    },
    inputWrapper: {
      maxWidth: '296px',
    },
    inputContainer: {
      flexDirection: 'row',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: 'auto',
      '& > button': {
        flex: 'initial',
      },
    },
  },
});

const linkedinSvg =
  'https://ucarecdn.com/b52ed9f3-5301-4e52-8e79-81521be32f70/';

const LinkedinReportProblemModal = ({
  isOpen,
  onClose,
  defaultLinkedinUsername,
  userId,
}: LinkedinReportProblemModalProps): JSX.Element => {
  const [isInputEnabled, setIsInputEnabled] = React.useState(false);
  const styles = useStyles({
    isOpen,
    isInputEnabled,
  });

  const analytics = useAnalytics();

  const { mutate: updatePersonalInfo, isLoading: isUpdatingPersonalInfo } =
    useUpdatePersonalInfo({
      onSuccess: () => {
        onClose();
      },
    });

  const {
    mutate: reportLinkedInError,
    isLoading: isReportLinkedInErrorLoading,
  } = useReportLinkedInError();

  const [linkedinUsername, setLinkedinUsername] = React.useState(
    defaultLinkedinUsername,
  );

  // Add handler for URL check click
  const handleUrlCheckClick = () => {
    setIsInputEnabled(true);
  };

  // Check if the user has modified the LinkedIn username
  const hasModifiedUsername = linkedinUsername !== defaultLinkedinUsername;

  return (
    <Modal
      variant="slideUp"
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modal}
    >
      <div className={styles.container}>
        <div>
          <div className={styles.title}>
            We couldn't verify your LinkedIn URL
          </div>
          <div className={styles.desc}>
            To confirm it’s correct, click Open LinkedIn URL below to open your
            profile. If the link opens your LinkedIn profile successfully,
            return to this page and click Confirm.
            <br />
            <br />
            If the link is incorrect, you can edit the URL in the field below
            and click Update.
          </div>

          <div className={styles.inputContainer}>
            <div>
              <a
                className={styles.link}
                href={`https://www.linkedin.com/in/${linkedinUsername}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleUrlCheckClick}
              >
                Open LinkedIn URL
              </a>
            </div>
            <div className={styles.inputWrapper}>
              <div className={styles.linkedinInputBox}>
                <img
                  src={linkedinSvg}
                  alt="LinkedIn"
                  className={styles.linkedinIcon}
                />
                <div className={styles.linkedinUrlContainer}>
                  <span style={{ color: '#6B7280', flexShrink: 0 }}>
                    linkedin.com/in/
                  </span>
                  <input
                    type="text"
                    placeholder="username"
                    value={linkedinUsername}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Extract username if full LinkedIn URL is pasted
                      if (value.includes('linkedin.com/in/')) {
                        const urlParts = value.split('linkedin.com/in/');
                        const username = urlParts[1]
                          ?.split('/')[0]
                          ?.split('?')[0];
                        setLinkedinUsername(username || value);
                      } else {
                        setLinkedinUsername(value);
                      }
                    }}
                    disabled={!isInputEnabled}
                    className={styles.linkedinInput}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            variant="secondary"
            disabled={isReportLinkedInErrorLoading}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="main"
            loading={isReportLinkedInErrorLoading || isUpdatingPersonalInfo}
            disabled={isReportLinkedInErrorLoading || !isInputEnabled}
            onClick={() => {
              if (hasModifiedUsername) {
                updatePersonalInfo({
                  personalInfo: {
                    linkedinUsername: linkedinUsername,
                  },
                });
                analytics.trackLinkedInProfileUpdatedFromErrorBanner(userId);
              } else {
                reportLinkedInError({
                  linkedinUrl: `https://www.linkedin.com/in/${linkedinUsername}`,
                });
              }
            }}
          >
            {hasModifiedUsername ? 'Update' : 'Confirm'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default LinkedinReportProblemModal;
