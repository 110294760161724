import MissionObject, {
  MissionAdminObject,
} from '@a_team/models/dist/MissionObject';
import { BasicUserObject, UserId } from '@a_team/models/dist/UserObject';
import ItemsCarousel from '@src/components/ItemsCarousel';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import { createUseStyles } from 'react-jss';
import BuilderContractCard from './BuilderContractCard';
import { MissionRoleId } from '@a_team/models/src/MissionRole';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';

export interface BuilderContractCardsProps {
  mission?: MissionAdminObject | MissionObject;
  canUpload: boolean;
  onCreateContract(
    uid: UserId,
    rid: MissionRoleId,
    downloadURL: string,
  ): Promise<void>;
}

const useStyles = createUseStyles({
  cards: {
    marginLeft: -8,
    marginRight: 50,
  },
  carousel: {
    width: 'auto',
    margin: '0 36px',
  },
  carouselControl: {
    padding: 0,
    width: 24,
    height: 24,
    boxShadow: 'none',
    left: '8px !important',
    '& ~ &': {
      left: 'auto !important',
      right: '0 !important',
    },
  },
});

const BuilderContractCards: FC<BuilderContractCardsProps> = (props) => {
  const { mission, onCreateContract, canUpload } = props;
  const styles = useStyles();
  const [carouselActive, setCarouselActive] = useState(false);

  if (!mission) {
    return <></>;
  }

  const { roles } = mission;

  const filledRoles = roles.filter(
    ({ status, user }) =>
      [
        MissionRoleStatus.Active,
        MissionRoleStatus.ScheduledToEnd,
        MissionRoleStatus.Open,
        MissionRoleStatus.ScheduledToSwitch,
      ].includes(status) && !!user,
  );
  const enableCarousel = carouselActive || filledRoles.length >= 4;

  return (
    <>
      <div className={styles.cards}>
        <ItemsCarousel
          arrowClassName={styles.carouselControl}
          className={cx({ [styles.carousel]: enableCarousel })}
          autoHideControls={!enableCarousel}
          onCarouselActive={setCarouselActive}
        >
          {filledRoles.map(({ user, category, rid }) => (
            <BuilderContractCard
              key={rid}
              disable={!canUpload}
              category={category}
              onClick={console.info}
              onFileUrl={(file) =>
                onCreateContract((user as BasicUserObject).uid, rid, file)
              }
              user={user as BasicUserObject}
            />
          ))}
        </ItemsCarousel>
      </div>
    </>
  );
};

export default observer(BuilderContractCards);
