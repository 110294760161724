import React, { InputHTMLAttributes } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import {
  BorderColors,
  BorderRadius,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
  TextColors,
} from '@ateams/components';

const useStyles = createUseStyles({
  inputLabel: {
    fontWeight: FontWeights.medium,
    color: TextColors.regular,
    display: 'block',
    fontSize: FontSizes.regular,
    marginBottom: Spacing.small,
  },
  rateInput: {
    position: 'relative',
    margin: 0,
  },
  numberInput: {
    border: `1px solid ${BorderColors.lighter}`,
    borderRadius: BorderRadius.default,
    padding: `10px 30px 10px 20px`,
    maxWidth: 160,
    fontWeight: FontWeights.regular,

    '&.error': {
      borderColor: '#fe640b',
    },
  },
  prefix: {
    position: 'absolute',
    left: 10,
    top: 11,

    '&.withLabel': {
      top: 40,
    },
  },
  suffix: {
    position: 'absolute',
    right: 10,
    top: 11,
    background: Colors.backgroundWhite,

    '&.withLabel': {
      top: 40,
    },
  },
});

interface RateInputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  prefix?: string;
  label?: string;
  suffix?: string;
}

const RateInputField: React.FC<RateInputProps> = (props: RateInputProps) => {
  const styles = useStyles();
  const { className, prefix, label, suffix, ...inputProps } = props;

  return (
    <div className={styles.rateInput}>
      {prefix && (
        <span className={cx(styles.prefix, { withLabel: !!label })}>
          {prefix}
        </span>
      )}
      {label && <label className={styles.inputLabel}>{label}</label>}
      <input
        type="number"
        className={cx(styles.numberInput, className)}
        {...inputProps}
      />
      {suffix && (
        <span className={cx(styles.suffix, { withLabel: !!label })}>
          {suffix}
        </span>
      )}
    </div>
  );
};

export default RateInputField;
