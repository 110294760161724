import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import {
  BorderRadius,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
  HelpTooltip,
} from '@ateams/components';
import { Paddings } from '@ateams/components';
import { AVG_WEEKS_PER_MONTH, HOURS_PER_WEEK } from '../TeamWorkRoleInput';
import { round } from 'lodash';

const useStyles = createUseStyles({
  rateHint: {
    display: 'flex',
    background: Colors.backgroundLight,
    padding: `${Paddings.small}px ${Paddings.medium}px`,
    borderRadius: BorderRadius.default,
    gap: Spacing.small,
    marginTop: Spacing.xLarge,
  },
  helpTooltip: {
    width: '300px !important',
    left: 'calc(50% - 50px)', // to offset the width
  },
  rateTooltip: {
    padding: 0,
  },
  tooltipTitle: {
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.small,
    marginBottom: Spacing.medium,
    textAlign: 'left',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  break: {
    margin: 0,
    background: Colors.regularLight,
  },
  bold: {
    fontWeight: FontWeights.bold,
  },
});

interface HourlyRateEquivalentProps {
  monthlyRate: number;
}

export const HourlyRateEquivalent: React.FC<HourlyRateEquivalentProps> = (
  props: HourlyRateEquivalentProps,
) => {
  const styles = useStyles();
  const { monthlyRate } = props;

  const hourlyRateEquivalent = useMemo(() => {
    if (!monthlyRate || monthlyRate === 0) {
      return 0;
    }

    return monthlyRate / AVG_WEEKS_PER_MONTH / HOURS_PER_WEEK;
  }, [monthlyRate]);

  return (
    <div className={styles.rateHint}>
      <span>{`${hourlyRateEquivalent.toLocaleString()}/h`}</span>
      <HelpTooltip className={styles.helpTooltip}>
        <div className={styles.rateTooltip}>
          <h4 className={styles.tooltipTitle}>Hourly rate equivalent</h4>
          <div className={styles.table}>
            <div className={styles.item}>
              <span>Monthly rate</span>
              <span>{`$${round(
                monthlyRate ?? 0,
                2,
              ).toLocaleString()} per month`}</span>
            </div>
            <div className={styles.item}>
              <span>Hours per week</span>
              <span>x 40</span>
            </div>
            <div className={styles.item}>
              <span>Average weeks in a month</span>
              <span>x 4.33</span>
            </div>
            <hr className={styles.break} />
            <div className={styles.item}>
              <span className={styles.bold}>Hourly rate</span>
              <span className={styles.bold}>
                ${hourlyRateEquivalent.toLocaleString()} per hour
              </span>
            </div>
          </div>
        </div>
      </HelpTooltip>
    </div>
  );
};
