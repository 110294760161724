import React, { ReactElement, useEffect } from 'react';
import UserAvatar from '@src/components/UserAvatar';
import { Icon, IconType } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import {
  CommunityBadgeObject,
  UserBadge,
} from '@a_team/models/dist/UserObject';
import ProfileImageUploader from '@src/views/ProfileImageUploader';
import Modal from 'react-modal';
import { useScreenClass } from 'react-grid-system';
import { useStores } from '@src/stores';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { observer } from 'mobx-react';

interface Props {
  url?: string;
  src?: string;
  readonly?: boolean;
  disabled?: boolean;
  badges: UserBadge[];
  communityBadges?: CommunityBadgeObject[];
  defaultOpen?: boolean;
  onChange?: (url: string) => Promise<void>;
}

const defaultProps = {
  readonly: false,
  defaultOpen: false,
};

const useStyles = createUseStyles({
  editButton: {
    cursor: 'pointer',
    position: 'absolute',
    background: '#fff',
    padding: 10,
    borderRadius: '50%',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.08)',
    bottom: 0,
    right: 0,
    transition: 'all 0.1s ease',
    '&:hover': {
      background: '#FFFAF7',
    },
  },
});

export const ProfileImage = observer((props: Props): ReactElement => {
  const {
    url,
    src,
    readonly = defaultProps.readonly,
    disabled,
    onChange,
    badges,
    defaultOpen = defaultProps.defaultOpen,
    communityBadges,
  } = props;
  const styles = useStyles();
  const analytics = useAnalytics();
  const screenClass = useScreenClass();
  const { users, missions, uiStore } = useStores();
  const { profile } = users;

  const isMobile = screenClass === 'xs' || screenClass === 'sm';

  const modalCustomStyles = {
    content: {
      top: 20,
      left: isMobile ? 20 : 140,
      right: 20,
      bottom: 20,
      transform: 'none',
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.6)',
    },
  };

  // default value
  useEffect(() => {
    uiStore.toggleProfileImageModal(defaultOpen || false);
  }, [defaultOpen]);

  useEffect(() => {
    // prevent the body to scroll
    if (uiStore.profileImageModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [uiStore.profileImageModalOpen]);

  const trackProfileImageClick = () => {
    profile &&
      analytics.trackProfileImageEditClicked(
        profile.eventsSource,
        profile.application ? missions.currentMission?.data : undefined,
        profile.application?.currentRole,
        profile.application?.aid,
      );
  };

  const onImageEdit = () => {
    trackProfileImageClick();
    uiStore.toggleProfileImageModal();
  };

  return (
    <>
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <a href={url}>
          <UserAvatar
            src={src}
            badges={!readonly || !onChange ? [] : badges}
            communityBadges={!readonly || !onChange ? [] : communityBadges}
            size={160}
            tooltipTheme={'dark'}
            showBadgesUnderneath
          />
        </a>
        {(!readonly || !onChange) && (
          <div
            className={styles.editButton}
            onClick={disabled ? undefined : onImageEdit}
          >
            <Icon type={IconType.Pencil} clickable size={'small'} />
          </div>
        )}
      </div>

      <Modal
        isOpen={uiStore.profileImageModalOpen}
        style={modalCustomStyles}
        onRequestClose={() => uiStore.toggleProfileImageModal()}
        overlayClassName={undefined}
        shouldCloseOnOverlayClick={true}
      >
        <ProfileImageUploader
          withoutMainLayout
          onClose={() => uiStore.toggleProfileImageModal()}
        />
      </Modal>
    </>
  );
});
