import React from 'react';
import { profilePicturesByCategory } from '@a_team/models/dist/constants/Profile';
import AuthStore from '@src/stores/Auth';
import Profile from '@src/stores/Profile/Profile';
import { OnboardingStage } from '@a_team/models/dist/UserObject';

export interface ProfileStep {
  id: string;
  label: string | JSX.Element;
  completed: boolean;
  haveNotHadThreeJobs?: boolean;
  duration?: number;
}

const isDefaultAvatar = (url: string) => {
  return Object.values(profilePicturesByCategory).includes(url);
};

export const useProfileSteps = ({
  profile,
  auth,
  hasCalendarSetup,
  hasProfilePictureIssues,
  handleConnectCalendar,
  isLoading,
  hoverCompletenessItemClassName,
  handleAboutMeClick,
  handleProjectsClick,
  handleIndustriesClick,
  handleSkillsClick,
  handleJobsClick,
  handleProfileImageClick,
  handleLinkedInClick,
  handleResumeUpload,
  handleRateClick,
}: {
  profile: Profile;
  auth: AuthStore;
  hasCalendarSetup: boolean;
  hasProfilePictureIssues: boolean;
  handleConnectCalendar: () => Promise<void>;
  isLoading?: boolean;
  hoverCompletenessItemClassName?: string;
  handleAboutMeClick?: () => void;
  handleProjectsClick?: () => void;
  handleIndustriesClick?: () => void;
  handleSkillsClick?: () => void;
  handleJobsClick?: () => void;
  handleProfileImageClick?: () => void;
  handleLinkedInClick?: () => void;
  handleResumeUpload?: () => void;
  handleRateClick?: () => void;
}): ProfileStep[] => {
  const haveNotHadThreeJobs =
    auth.user?.profileCompleteness?.haveNotHadThreeJobs;

  const hasProfilePicture =
    !!profile?.data.profilePictureURL &&
    !isDefaultAvatar(profile?.data.profilePictureURL);
  const hasAboutMe =
    !!profile?.data?.aboutMe && profile?.data.aboutMe?.length > 249;
  const hasProjects =
    profile?.data?.projects?.filter(
      (project) => !project.isMissingMinimumRequirements,
    ).length >= 2;
  const hasJobs = !!(profile?.data?.jobs?.length > 2 || haveNotHadThreeJobs);
  const hasSkills = profile?.allSkills?.length > 4;
  const hasIndustries = profile?.industryExperiences?.length > 2;
  const hasResumeOrLinkedin =
    !!profile?.data.cvURL || !!profile?.data.linkedIn?.username;

  const hasPreferredRates =
    !!profile?.minimumHourlyRate && !!profile.minimumMonthlyRate;

  const steps: ProfileStep[] = [
    {
      id: 'resumeLinkedin',
      label: (
        <>
          <span
            className={hoverCompletenessItemClassName}
            onClick={handleResumeUpload}
          >
            Upload your resume <span>→</span>
          </span>{' '}
          or{' '}
          <span
            className={hoverCompletenessItemClassName}
            onClick={handleLinkedInClick}
          >
            add your LinkedIn URL <span>→</span>
          </span>
        </>
      ),
      completed: hasResumeOrLinkedin,
    },
    {
      id: 'profilePicture',
      label: (
        <span
          className={hoverCompletenessItemClassName}
          onClick={handleProfileImageClick}
        >
          Upload a profile picture <span>→</span>
        </span>
      ),
      completed: hasProfilePicture,
    },
    {
      id: 'aboutMe',
      label: (
        <span
          className={hoverCompletenessItemClassName}
          onClick={handleAboutMeClick}
        >
          Write an "About me" section with at least 250 characters{' '}
          <span>→</span>
        </span>
      ),
      completed: hasAboutMe,
    },
    {
      id: 'projects',
      label: (
        <span
          className={hoverCompletenessItemClassName}
          onClick={handleProjectsClick}
        >
          Add at least 2 completed projects <span>→</span>
        </span>
      ),
      completed: hasProjects,
    },
    {
      id: 'jobs',
      label: (
        <span
          className={hoverCompletenessItemClassName}
          onClick={handleJobsClick}
        >
          Add at least 3 jobs <span>→</span>
        </span>
      ),
      completed: hasJobs,
      haveNotHadThreeJobs,
    },
    {
      id: 'skills',
      label: (
        <span
          className={hoverCompletenessItemClassName}
          onClick={handleSkillsClick}
        >
          Add at least 5 skills <span>→</span>
        </span>
      ),
      completed: hasSkills,
    },
    {
      id: 'industries',
      label: (
        <span
          className={hoverCompletenessItemClassName}
          onClick={handleIndustriesClick}
        >
          Add at least 3 industries <span>→</span>
        </span>
      ),
      completed: hasIndustries,
    },
    {
      id: 'preferredRates',
      label: (
        <span
          className={hoverCompletenessItemClassName}
          onClick={handleRateClick}
        >
          Add your preferred minimum hourly and monthly rate <span>→</span>
        </span>
      ),
      completed: hasPreferredRates,
    },
  ];

  steps.push({
    id: 'calendar',
    label: (
      <span
        className={hoverCompletenessItemClassName}
        onClick={handleConnectCalendar}
      >
        {isLoading ? (
          'loading...'
        ) : (
          <>
            {' '}
            Connect your calendar or set your availability to enable client
            interviews <span>→</span>{' '}
          </>
        )}
      </span>
    ),
    completed: hasCalendarSetup,
  });

  if (auth.onboardingCompleted) {
    steps.push({
      id: 'profilePictureIssues',
      label:
        'Stand out in client searches by uploading a high quality profile picture',
      completed: !hasProfilePictureIssues,
    });
  }

  return steps;
};

export const checkProfileCompletion = (profileSteps: ProfileStep[]) => {
  const totalCompleted = profileSteps.filter((step) => step.completed).length;
  const totalSteps = profileSteps.length;
  const allCompleted = totalCompleted === totalSteps;

  return { totalCompleted, allCompleted, totalSteps };
};

export const getProfileCompletionContent = ({
  stage,
  builderType,
  isProfileCompleted,
}: {
  stage?: OnboardingStage;
  builderType?: 'sourced' | 'prescrubbed' | 'normal' | undefined;
  isProfileCompleted?: boolean;
}) => {
  if (
    isProfileCompleted === false ||
    (builderType === 'prescrubbed' &&
      stage === OnboardingStage.IncompleteProfile)
  ) {
    return {
      title:
        'Complete your profile to take the first step in unlocking full A.Team access',
      desc: 'Once you’re added the items below, schedule an evaluation call on the setup page.',
    };
  }

  switch (stage) {
    case OnboardingStage.InterviewScheduledWithIncompleteProfile:
    case OnboardingStage.PendingInterviewWithIncompleteProfile:
    case OnboardingStage.ProfileCompleteWithPendingApplication:
      return {
        title: 'Complete your profile to unlock full A.Team access',
        desc: `Once you've completed your evaluation call, you can gain full access to the network by filling in your profile.`,
      };

    case OnboardingStage.InterviewScheduled:
      return {
        title:
          'Almost there! Complete your evaluation call to unlock A.Team access',
        desc: `If you pass the evaluation call, you’ll gain full access to the network.`,
      };

    case OnboardingStage.PendingInterview:
      if (builderType === 'prescrubbed') {
        return {
          title: 'Nice work! Next up, schedule your evaluation call.',
          desc: 'Complete the evaluation call to gain full access to the network.',
        };
      }
      return {
        title:
          'Almost there! Complete your evaluation call to unlock A.Team access',
        desc: `Once you’ve completed your evaluation call, you can gain full access to the network.`,
      };

    case OnboardingStage.Completed:
      return {
        title: `Nice work! You've unlocked full access to A.Team.`,
        desc: `You can now team up, join missions and get featured to clients.`,
      };

    case OnboardingStage.CompletedWithIncompleteProfile:
      return {
        title: `Complete your profile to unlock full A.Team access`,
        desc: `Now that your evaluation call is complete, you can gain full access to the network by filling in your profile.`,
      };

    default:
      return undefined;
  }
};
