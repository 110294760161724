import React, { ReactElement, ReactNode, useMemo } from 'react';
import { Icon, IconType } from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { Colors, Breakpoints } from '@ateams/components';
import cx from 'classnames';
import { ProfileTooltip } from '@src/views/Profile/ProfileTooltip';

interface Props {
  title: string;
  onAddClick?: () => void;
  tooltip?: string;
  error?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  withAddIcon?: boolean;
  empty?: boolean;
  tooltipErrorLabel?: string;
  tooltipEmptyLabel?: string;
  children?: ReactNode;
  addIconTestingId?: string;
  id?: string;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    color: '#62646A',
    fontSize: 14,
    margin: '0 24px',
    marginBottom: 24,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  error: {
    color: Colors.danger,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      margin: 0,
      marginBottom: 40,
    },
  },
});

export const SidebarSection = (props: Props): ReactElement => {
  const styles = useStyles();
  const {
    title,
    children,
    onAddClick,
    readonly,
    disabled = false,
    error,
    empty,
    withAddIcon = true,
    tooltipErrorLabel,
    tooltipEmptyLabel,
    addIconTestingId,
    id,
    className,
  } = props;

  const tooltipText = useMemo(() => {
    let text;
    empty && (text = tooltipEmptyLabel);
    error && (text = tooltipErrorLabel);
    return text;
  }, [error, children]);

  return (
    <div className={cx(styles.container, className)} id={id}>
      <div className={styles.title}>
        <ProfileTooltip title={tooltipText} disabled={!tooltipText}>
          <span className={cx({ [styles.error]: error && !readonly })}>
            {title}
          </span>
        </ProfileTooltip>

        {!readonly && withAddIcon && (
          <ProfileTooltip title={tooltipText} disabled={!tooltipText}>
            <Icon
              title={false}
              type={IconType.PlusPurple}
              onClick={onAddClick}
              style={{
                opacity: disabled ? 0.5 : 1,
                pointerEvents: disabled ? 'none' : 'auto',
              }}
              testingId={addIconTestingId}
            />
          </ProfileTooltip>
        )}
      </div>
      {children}
    </div>
  );
};
