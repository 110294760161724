/**
 * Get the thumbnail image URL for a given Youtube video URL
 * @param {String} url - A Youtube URL
 * @return {String | undefined} thumbnail - The thumbnail URL if it exists
 */
export const getYoutubeVideoThumbnail = (url: string): string | undefined => {
  const id = url.match(
    /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/,
  );
  return id ? `https://img.youtube.com/vi/${id[1]}/0.jpg` : undefined;
};

export const isLinkedInUrl = (url: string): boolean => {
  return !!url.match(/^(https?:\/\/)?([a-z]+\.)?linkedin\.com\/in\//i);
};

export const getLinkedinUsernameFromURL = (url: string): string => {
  return url.replace(
    /(https?:\/\/)?([a-z]+\.)?linkedin\.com\/in\/|\/(in)\/|\//gi,
    '',
  );
};

export const isGithubUrl = (url: string): boolean => {
  return !!url.match(
    /(https?:\/\/)?(www\.)?github\.com\/[a-z\d](?:[a-z0-9\d]|-(?=[a-z0-9\d])){2,}/i,
  );
};

export const getGithubUsernameFromURL = (url: string): string => {
  return url.replace(/(https?:\/\/)?(www\.)?github\.com\//i, '');
};

export const isDribbbleUrl = (url: string): boolean => {
  return !!url.match(/(https?:\/\/)?(www\.)?dribbble\.com\//g);
};

export const getDribbbleUsernameFromURL = (url: string): string => {
  return url.replace(/(https?:\/\/)?(www\.)?dribbble\.com\//i, '');
};

export const isValidUrl = (url: string): boolean => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and tld
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?' + // port
      "(\\/[-a-z\\d%@_.~+&:;,/?=$!*'()#]*)*" + // path
      "(\\?[;&a-z\\d%@_.~+&:;,/?=$!*'()#]*)?" + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i',
  );
  return !!pattern.test(url);
};

export const withHttps = (url: string): string =>
  !/^https?:\/\//i.test(url) ? `https://${url}` : url;

export const isValidLinkedInUrl = (url?: string): boolean => {
  if (!url) {
    return false;
  }

  if (!isLinkedInUrl(url)) {
    return false;
  }

  const username = getLinkedinUsernameFromURL(url);

  return username !== '';
};

/**
 * Checks if the given URL is a valid GitHub profile URL.
 * @param url The URL to check.
 * @returns True if the URL is a valid GitHub profile URL, false otherwise.
 */
export function isValidGithubUrl(url: string | undefined): boolean {
  if (!url) return false;

  try {
    const parsedUrl = new URL(url);

    // Check if the hostname is github.com
    if (parsedUrl.hostname !== 'github.com') return false;

    // Split the pathname and remove empty elements
    const pathParts = parsedUrl.pathname.split('/').filter(Boolean);

    // A valid GitHub profile URL should have exactly one path part (the username)
    if (pathParts.length !== 1) return false;

    // Check if the username follows GitHub's username rules
    // GitHub usernames can contain alphanumeric characters or hyphens
    // Cannot have multiple consecutive hyphens
    // Cannot begin or end with a hyphen
    // Maximum length is 39 characters
    const username = pathParts[0];
    const usernameRegex = /^[a-z\d](?:[a-z\d]|-(?=[a-z\d])){0,38}$/i;

    return usernameRegex.test(username);
  } catch (error) {
    // If the URL is invalid (e.g., missing protocol), this will throw an error
    return false;
  }
}
