import { Breakpoints, Icon, IconType, Tag } from '@ateams/components';
import {
  AvailabilitySummaryObject,
  AvailableType,
  ReminderPeriod,
} from '@a_team/models/dist/AvailabilityObject';
import { DateISOString } from '@a_team/models/dist/misc';
import { tagEditedYellowStyle } from '@src/common-styles/tag-edited';
import Checkbox from '@src/components/Checkbox';
import DropdownInput from '@src/components/Inputs/DropdownInput';
import { inputStyles } from '@src/components/Inputs/InlineInput/styles';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { enumKeys } from '@src/helpers/types';
import { NotificationPreferencesLocationNoToken } from '@src/locations';
import {
  AvailabilityReminderLabels,
  AvailabilityTypeLabels,
} from '@src/stores/NotificationPreferencesUser';
import { HoursInput } from '@src/views/Profile/Main/AvailabilityV2/HoursInput';
import { StatusSelect } from '@src/views/Profile/Main/AvailabilityV2/StatusSelect';
import { add, format, parseISO } from 'date-fns';
import { observer } from 'mobx-react';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import Section, { SectionProps } from '../partials/Section';
import { NotesInput } from './NotesInput';
import cx from 'classnames';
import UpdateProfileToast from '@src/views/Application/UpdateProfileToast';
import { DateUTCInput } from '@src/components/DateUTCInput';

export enum AvailabilityMode {
  Block = 'Block',
  Inline = 'Inline',
}

export const MAX_AVAILABILITY_HOURS = 80;

interface Props {
  withStatusSelect?: boolean;
  mode?: AvailabilityMode;
  companyHoursPerWeekRequired?: number;
  availability?: AvailabilitySummaryObject;
  applicationAvailability?: {
    startDate: DateISOString;
    hoursPerWeek: number;
    notes?: string;
  };
  keepNotifications?: boolean;
  onKeepNotificationsChange?: (keepNotifications: boolean) => void;
  keepNotificationsPausedEndDate?: string;
  reminderPeriod?: ReminderPeriod;
  onChange(availability: AvailabilitySummaryObject): void;
  onToastClick?: (save: boolean) => void;
  onReminderPeriodChange?: (period: ReminderPeriod) => void;
  disabled?: boolean;
  readOnly?: boolean;
  availabilityError?: boolean;
  availabilityHoursError?: boolean;
  popperBelow?: boolean;
  promptFontSize?: number;
  disableKeepNotificationsIfPaused?: boolean;
  profAvailabilityData?: AvailabilitySummaryObject;
  isApplicationAvailabilityEdited?: boolean;
  isApplicationAvailabilityNotesEdited?: boolean;
  guidanceMessage?: ReactElement;
  type?: SectionProps['type'];
}

const useStyles = createUseStyles({
  inputGroup: {
    display: (props?: Props): string =>
      props?.mode === (AvailabilityMode.Block as AvailabilityMode)
        ? 'block'
        : 'flex',
    width: (props?: Props): string =>
      props?.mode === (AvailabilityMode.Block as AvailabilityMode)
        ? '100%'
        : 'inherit',
    alignItems: 'center',
  },
  break: {
    flexBasis: '100%',
    height: 0,
    marginTop: 8,
  },
  prompt: {
    flexShrink: 0,
    fontSize: (props?: Props): number => props?.promptFontSize ?? 15,
  },
  statusSelect: {
    background: 'none',
    border: 'none',
    ...inputStyles,
    padding: '0 8px',
    marginBottom: 0,
    paddingBottom: 0,
    marginRight: 8,
  },
  select: {
    background: 'none',
    border: 'none',
    ...inputStyles,
    padding: '0 8px',
    marginBottom: 0,
    paddingBottom: 0,
    marginRight: 8,
  },
  checkbox: {
    marginRight: 8,
  },
  sectionLabel: {
    fontSize: 14,
    lineHeight: '2.6em',
    color: '#62646A',
    display: 'block',
    marginTop: 12,
  },
  inputWithBorder: {
    border: '1px solid #DADADC',
    background: 'none',
    padding: '8px 12px',
    borderRadius: 4,
    fontSize: 15,
    '& select': {
      background: 'none',
    },
  },
  dateInput: {
    width: 99,
  },
  blockDatePickerWrapper: {
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '0.5em',
  },
  blockDatePicker: {
    padding: '1em',
    minHeight: 56,
    width: '100%',
    lineHeight: 'initial',
    background: 'none',
    border: 'none',
  },
  blockHourInput: {
    maxWidth: 150,
  },
  inputWrapper: {
    position: 'relative',
  },
  suffix: {
    position: 'absolute',
    right: 10,
    top: 9,
  },
  hoursInput: {
    border: '1px solid #DADADC',
    width: '120px',
    padding: '8px 12px',
    fontWeight: 400,
    borderRadius: 4,
  },
  notesWrapper: {
    marginTop: 24,
  },
  smallText: {
    margin: 0,
    fontSize: 12,
    color: '#999',
  },
  tagEdited: {
    ...tagEditedYellowStyle,
  },
  availableFromWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  availableFromLabelWrapper: {
    marginBottom: 12,
  },
  availableFromInputsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '> .react-datepicker-popper': {
      zIndex: 5,
    },
  },
  toast: {
    marginTop: -15,
    marginBottom: 24,
    maxWidth: 800,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    availableFromWrapper: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    availableFromLabelWrapper: {
      marginBottom: 0,
    },
    availableFromInputsWrapper: {
      marginLeft: ({ readOnly }: { readOnly?: boolean }) => (readOnly ? 8 : 16),
    },
  },
});

const AvailabilityV2 = (props: Props): ReactElement | null => {
  const {
    withStatusSelect = true,
    mode = AvailabilityMode.Inline,
    companyHoursPerWeekRequired,
    availability,
    applicationAvailability,
    keepNotifications,
    onKeepNotificationsChange,
    keepNotificationsPausedEndDate,
    reminderPeriod,
    onChange,
    onToastClick,
    onReminderPeriodChange,
    disabled,
    readOnly,
    availabilityError,
    availabilityHoursError,
    popperBelow,
    profAvailabilityData,
    disableKeepNotificationsIfPaused = true,
    isApplicationAvailabilityEdited,
    isApplicationAvailabilityNotesEdited,
    guidanceMessage,
    type,
  } = props;
  const styles = useStyles(props);
  const [hours, setHours] = useState<number | undefined>(
    availability?.weeklyHoursAvailable,
  );
  const [fromDate, setFromDate] = useState<DateISOString | undefined>(
    availability?.availableFrom,
  );
  const [notes, setNotes] = useState<string | undefined>(availability?.notes);
  const [isToastShown, setShowToast] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    // Handles value update as user types
    availability?.weeklyHoursAvailable &&
      hours !== availability?.weeklyHoursAvailable &&
      setHours(availability.weeklyHoursAvailable);
  }, [availability?.weeklyHoursAvailable]);

  useEffect(() => {
    // Sets initial value on hours
    if (
      !availability?.weeklyHoursAvailable ||
      availability?.weeklyHoursAvailable === 0
    ) {
      setHours(profAvailabilityData?.weeklyHoursAvailable);
    }
  }, []);

  useEffect(() => {
    // Sets initial value on fromDate
    if (profAvailabilityData?.availableFrom) {
      setFromDate(parseISO(profAvailabilityData?.availableFrom));
    }
  }, []);

  useEffect(() => {
    // Handles Date value updates as user type
    availability?.availableFrom &&
      fromDate !== availability?.availableFrom &&
      setFromDate(availability.availableFrom);
  }, [availability?.availableFrom]);

  useEffect(() => {
    availability?.notes &&
      notes !== availability?.notes &&
      setNotes(availability.notes);
  }, [availability?.notes]);

  useEffect(() => {
    if (hours === availability?.weeklyHoursAvailable) return;
    onChange({
      ...availability,
      ...{
        type: availability?.type || AvailableType.UnknownAvailability,
        weeklyHoursAvailable: hours,
        notes: notes,
      },
    });
  }, [hours]);

  useEffect(() => {
    if (fromDate === availability?.availableFrom) return;

    onChange({
      ...availability,
      ...{
        type: availability?.type || AvailableType.UnknownAvailability,
        availableFrom: fromDate
          ? new Date(fromDate).toISOString()
          : new Date().toISOString(),
        notes: notes,
      },
    });
  }, [fromDate]);

  useEffect(() => {
    if (notes === availability?.notes) return;

    onChange({
      ...availability,
      ...{
        type: availability?.type || AvailableType.UnknownAvailability,
        notes: notes,
      },
    });
  }, [notes]);

  const showHoursInput = useMemo(() => {
    return (
      availability?.type !== AvailableType.NotAvailable &&
      availability?.type !== AvailableType.UnknownAvailability
    );
  }, [availability?.type]);

  const showDateInput = useMemo(() => {
    return availability?.type === AvailableType.FutureDate;
  }, [availability?.type]);

  const showReminder = useMemo(() => {
    return availability?.type === AvailableType.NotAvailable;
  }, [availability?.type]);

  const isValidHours = (text: string) => {
    return !isNaN(Number(text)) && Number(text) <= MAX_AVAILABILITY_HOURS;
  };

  const onHoursChange = (value: string) => {
    if (!isValidHours(value)) return;
    setHours(Number(value));
    isToastShown === undefined && setShowToast(true);
  };

  const onNotesChange = useCallback((value: string) => {
    setNotes(value);
  }, []);

  const onReminderChange = (label: string) => {
    const period = enumKeys(AvailabilityReminderLabels).find(
      (key) => key === label,
    );
    if (period && onReminderPeriodChange) {
      onReminderPeriodChange(period as ReminderPeriod);
    }
  };

  const onStatusChange = (label: string) => {
    const status = enumKeys(AvailabilityTypeLabels).find(
      (key) => AvailabilityTypeLabels[key] === label,
    );
    onChange({
      ...availability,
      type: (status as AvailableType) || AvailableType.UnknownAvailability,
    });
  };

  const isBlock = mode === (AvailabilityMode.Block as AvailabilityMode);

  if (withStatusSelect) {
    if (readOnly) return null;

    return (
      <>
        <Section
          title="Availability"
          readonly={readOnly}
          type={type}
          tooltipText="Please enter the number of hours you'll be available to work on Missions"
        >
          <div className={styles.availableFromWrapper}>
            <div className={styles.availableFromLabelWrapper}>
              I am available from
            </div>

            <div className={styles.availableFromInputsWrapper}>
              <StatusSelect
                error={availabilityError}
                onChange={(e) => onStatusChange(e.target.value)}
                className={cx(
                  styles.inputWithBorder,
                  isBlock ? styles.statusSelect : undefined,
                )}
                width={isBlock ? 'default' : 'fixed'}
                noBorder={!isBlock}
                value={
                  availability?.type === AvailableType.UnknownAvailability
                    ? ''
                    : availability
                    ? AvailabilityTypeLabels[availability.type]
                    : ''
                }
              />

              {showDateInput && (
                <>
                  {isBlock ? (
                    <div className={styles.sectionLabel}> Starting at </div>
                  ) : (
                    <div
                      style={{
                        margin: '0 15px',
                      }}
                    >
                      <Icon type={IconType.HorizontalInLineDivider} />
                    </div>
                  )}
                  <DateUTCInput
                    minDate={add(new Date(), { days: 1 })}
                    popperPlacement={
                      isBlock || popperBelow ? undefined : 'top-end'
                    }
                    className={cx(
                      styles.inputWithBorder,
                      styles.dateInput,
                      isBlock ? styles.blockDatePicker : undefined,
                    )}
                    wrapperClassName={
                      isBlock ? styles.blockDatePickerWrapper : undefined
                    }
                    selected={fromDate ? new Date(fromDate) : new Date()}
                    onChange={(date) =>
                      setFromDate(
                        date ? (date as Date).toISOString() : undefined,
                      )
                    }
                    disabled={disabled}
                  />
                </>
              )}

              {showHoursInput && (
                <>
                  {isBlock ? (
                    <div>
                      <div className={styles.sectionLabel}>
                        {' '}
                        Available Hours{' '}
                      </div>
                      <OutlinedInput
                        value={hours || ''}
                        onChange={(e) => onHoursChange(e.target.value)}
                        endAdornment={<span>hr/wk</span>}
                        className={styles.blockHourInput}
                        fullWidth={false}
                        placeholder={'XX'}
                        margin="none"
                      />
                    </div>
                  ) : (
                    <>
                      <span
                        style={{ margin: readOnly ? '0 0 0 8px' : '0 16px' }}
                      >
                        for
                      </span>
                      <div className={styles.inputWrapper}>
                        <HoursInput
                          disabled={disabled}
                          error={availabilityHoursError}
                          value={hours || ''}
                          onChange={(e) => onHoursChange(e.target.value)}
                          className={styles.hoursInput}
                        />
                        <div className={styles.suffix}>h/week</div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div>
            {showReminder && (
              <div style={{ marginTop: 18 }}>
                <div className={styles.inputGroup}>
                  {isBlock ? (
                    <div className={styles.sectionLabel}>
                      {' '}
                      Send reminder email in{' '}
                    </div>
                  ) : (
                    <div className={styles.prompt} style={{ marginRight: 16 }}>
                      Remind me to update my availability in
                    </div>
                  )}
                  <DropdownInput
                    margin={'none'}
                    noBorder={!isBlock}
                    placeholder={'Select...'}
                    className={cx(
                      styles.inputWithBorder,
                      isBlock ? undefined : styles.select,
                    )}
                    value={
                      reminderPeriod
                        ? AvailabilityReminderLabels[reminderPeriod]
                        : ''
                    }
                    onChange={(e) => {
                      const periodKey = enumKeys(
                        AvailabilityReminderLabels,
                      ).find(
                        (key) =>
                          AvailabilityReminderLabels[key] === e.target.value,
                      );
                      periodKey &&
                        onReminderChange(periodKey as ReminderPeriod);
                    }}
                  >
                    {enumKeys(AvailabilityReminderLabels).map((key) => (
                      <option key={key}>
                        {AvailabilityReminderLabels[key]}
                      </option>
                    ))}
                  </DropdownInput>
                </div>
                <div className={styles.break} />
                <div className={styles.inputGroup}>
                  <Checkbox
                    disabled={
                      disableKeepNotificationsIfPaused &&
                      !!keepNotificationsPausedEndDate
                    }
                    id="keepNotifications"
                    className={styles.checkbox}
                    checked={keepNotifications}
                    onChange={(e) =>
                      onKeepNotificationsChange &&
                      onKeepNotificationsChange(e.target.checked)
                    }
                  />
                  <label className={styles.prompt} htmlFor="keepNotifications">
                    Continue to send me emails about new recommended mission
                    postings.
                  </label>
                </div>
                <div className={styles.break} />
                {!!keepNotificationsPausedEndDate && (
                  <p className={styles.smallText}>
                    Your notifications are paused until{' '}
                    {keepNotificationsPausedEndDate}.{' '}
                    {disableKeepNotificationsIfPaused ? (
                      <>
                        To change this, go to your{' '}
                        <Link to={NotificationPreferencesLocationNoToken}>
                          notification preferences
                        </Link>
                      </>
                    ) : (
                      <></>
                    )}
                  </p>
                )}
              </div>
            )}
          </div>
        </Section>

        <Section
          title={
            <>
              Note on availability{' '}
              <span style={{ marginLeft: 8, color: '#818388' }}>Optional</span>
            </>
          }
          readonly={readOnly}
          description="Tell us about any special circumstances, like being able to ramp up
          hours, or adjust your usual availability. If you're engaged on another project, mention it and include your
            expected end date."
          tooltipText="Tell us details about your availability"
        >
          <NotesInput
            readonly={readOnly}
            disabled={disabled}
            text={notes || ''}
            onChange={onNotesChange}
          />
        </Section>
      </>
    );
  }

  return (
    <div>
      <Section
        iconType={type !== 'large' ? IconType.Clock : undefined}
        type={type}
        title="Availability"
        readonly={readOnly}
      >
        {!readOnly && (
          <>
            {!!companyHoursPerWeekRequired && (
              <div style={{ marginBottom: 8 }}>
                Company requires a minimum of {companyHoursPerWeekRequired}{' '}
                hours per week
              </div>
            )}

            {companyHoursPerWeekRequired && guidanceMessage}
          </>
        )}
        <div className={styles.availableFromWrapper}>
          <div className={styles.availableFromLabelWrapper}>
            I am available from
          </div>
          <div className={styles.availableFromInputsWrapper}>
            {readOnly ? (
              <div>
                {format(
                  new Date(
                    applicationAvailability
                      ? applicationAvailability.startDate
                      : availability?.availableFrom || '',
                  ),
                  'MMMM dd, yyyy',
                )}
              </div>
            ) : (
              <DateUTCInput
                className={cx(styles.inputWithBorder, styles.dateInput)}
                selected={fromDate ? new Date(fromDate) : new Date()}
                onChange={(date) =>
                  setFromDate(date ? (date as Date).toISOString() : undefined)
                }
                disabled={disabled}
                minDate={new Date()}
              />
            )}
            <div style={{ margin: readOnly ? '0 0 0 8px' : '0 16px' }}>for</div>
            {readOnly ? (
              <>
                <div style={{ marginLeft: 8 }}>
                  {availability?.weeklyHoursAvailable}
                </div>
                <div className={styles.prompt}>h/week</div>
              </>
            ) : (
              <div className={styles.inputWrapper}>
                <HoursInput
                  disabled={disabled}
                  error={availabilityHoursError}
                  value={
                    applicationAvailability
                      ? applicationAvailability.hoursPerWeek
                      : hours || ''
                  }
                  onChange={(e) => onHoursChange(e.target.value)}
                  className={styles.hoursInput}
                />
                <div className={styles.suffix}>h/week</div>
              </div>
            )}
          </div>
          {isApplicationAvailabilityEdited ? (
            <Tag className={styles.tagEdited}>Edited</Tag>
          ) : null}
        </div>
        {(!readOnly || applicationAvailability?.notes || notes) && (
          <Section
            iconType={type !== 'large' ? IconType.Chat : undefined}
            title={
              <>
                Note on availability
                {!readOnly && type !== 'large' && (
                  <>
                    {' '}
                    <span style={{ marginLeft: 8, color: '#818388' }}>
                      Optional
                    </span>
                  </>
                )}
              </>
            }
            readonly={readOnly}
            description={
              type !== 'large'
                ? "Tell us about any special circumstances, like being able to ramp up hours, or adjust your usual availability. If you're engaged on another project, mention it and include your expected end date."
                : undefined
            }
            tooltipText="Tell us details about your availability"
            className={styles.notesWrapper}
          >
            <NotesInput
              rows={2}
              readonly={readOnly}
              disabled={disabled}
              text={
                applicationAvailability?.notes
                  ? applicationAvailability.notes
                  : notes
              }
              onChange={onNotesChange}
              isApplicationAvailabilityNotesEdited={
                isApplicationAvailabilityNotesEdited
              }
            />
          </Section>
        )}
        {onToastClick && (
          <UpdateProfileToast
            onClose={(save) => {
              onToastClick(save);
              setShowToast(false);
            }}
            isShown={!!isToastShown}
            className={styles.toast}
          >
            <>Do you want to save this new availability to your profile?</>
          </UpdateProfileToast>
        )}
      </Section>
    </div>
  );
};

export default observer(AvailabilityV2);
