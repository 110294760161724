import { ServiceAuth, ServiceEndpoint } from './utils';
import { API_SERVICE_BASE_PATH } from '../config';
import {
  MissionCardObject,
  TimesheetMigrationDataRequest,
} from '@a_team/models/src/MissionObject';
import { QueryResult } from '@a_team/models/src/misc';
import TimesheetObject from '@a_team/models/src/TimesheetObject';
import {
  CreateRolePaymentTermRequest,
  PendingPaymentTerm,
} from '@a_team/models/src/MissionRole';

export const BasePath = '/admin';

export interface AllRebrandlyResponse {
  shortUrl: string;
  clicks: number;
  createdAt: string;
  domainName: string;
  id: string;
}

export interface UpsertShortUrlRequest {
  slashtag: string;
  destination: string;
  title: string;
}

export interface UpsertShortUrlResponse {
  shortUrl: string;
}

// exported functions

export default class AdminEndpoint extends ServiceEndpoint {
  public upsertShortUrl(
    auth: ServiceAuth,
    data: UpsertShortUrlRequest,
  ): Promise<UpsertShortUrlResponse> {
    return this.fetch(auth, BasePath + '/short-url', null, 'put', data);
  }

  public getRebrandlyAll(
    auth: ServiceAuth,
    last: string | null,
  ): Promise<AllRebrandlyResponse[]> {
    return this.fetch(auth, BasePath + '/short-url', { last });
  }

  public getRebrandlyTotalCount(auth: ServiceAuth): Promise<number> {
    return this.fetch(auth, BasePath + '/short-url/count');
  }

  public getBuilderActiveMissions(
    auth: ServiceAuth,
    builderId: string,
  ): Promise<QueryResult<MissionCardObject>> {
    return this.fetch(auth, `${BasePath}/query/${builderId}/active-missions`);
  }

  public migrateTimesheet(
    auth: ServiceAuth,
    data: TimesheetMigrationDataRequest,
  ): Promise<TimesheetObject> {
    return this.fetch(auth, `${BasePath}/migrate-timesheet`, null, 'put', data);
  }

  public getExportATeamersURL(auth: ServiceAuth): string {
    return AdminEndpoint.getExportURL(auth, '/export/a-teamers');
  }

  public getExportMissionsURL(auth: ServiceAuth): string {
    return AdminEndpoint.getExportURL(auth, '/export/missions');
  }

  public getExportMissionRolesURL(auth: ServiceAuth): string {
    return AdminEndpoint.getExportURL(auth, '/export/mission-roles');
  }

  public getExportTimesheetsURL(auth: ServiceAuth): string {
    return AdminEndpoint.getExportURL(auth, '/export/timesheets');
  }

  public getExportMissionPaymentCycleInvoicesURL(auth: ServiceAuth): string {
    return AdminEndpoint.getExportURL(
      auth,
      '/export/mission-payment-cycle-invoices',
    );
  }

  public getExportPaymentPendingURL(auth: ServiceAuth): string {
    return AdminEndpoint.getExportURL(auth, '/export/payment-pending');
  }

  public getExportClientsBankAccountsURL(auth: ServiceAuth): string {
    return AdminEndpoint.getExportURL(auth, '/export/clients-bank-accounts');
  }

  public getExportTeamPulsesURL(auth: ServiceAuth): string {
    return AdminEndpoint.getExportURL(auth, '/export/team-pulses');
  }

  public getExportMissionNotifierEmailAnalyticsURL(auth: ServiceAuth): string {
    return AdminEndpoint.getExportURL(
      auth,
      '/export/mission-notifier-email-analytics',
    );
  }

  public createRolePaymentTerm(
    auth: ServiceAuth,
    data: CreateRolePaymentTermRequest,
  ): Promise<PendingPaymentTerm> {
    return this.fetch(
      auth,
      BasePath + '/create-payment-term',
      null,
      'post',
      data,
    );
  }

  private static getExportURL(auth: ServiceAuth, path: string): string {
    return `${API_SERVICE_BASE_PATH}${BasePath}${path}?access_token=${auth.bearerToken}`;
  }
}
