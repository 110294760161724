import { IS_PROD_BUILD } from '@src/config';

let clarityModulePromise: Promise<typeof import('@microsoft/clarity')> | null =
  null;

function loadClarity() {
  if (!clarityModulePromise) {
    // This import() returns a promise that resolves once Clarity is loaded.
    clarityModulePromise = import('@microsoft/clarity');
  }
  return clarityModulePromise;
}

async function initClarity({ projectId }: { projectId: string }) {
  if (
    IS_PROD_BUILD &&
    typeof window !== 'undefined' &&
    window.location.hostname !== 'localhost'
  ) {
    const Clarity = await loadClarity();
    // The default export should be Clarity as an object with init/identify methods.
    Clarity?.default?.init(projectId);
  }
}

async function identifyClarityUser(userId: string, username?: string) {
  if (IS_PROD_BUILD && typeof window !== 'undefined') {
    const Clarity = await loadClarity();
    Clarity?.default?.identify(userId, undefined, undefined, username);
  }
}

async function addClarityPageTag(tag: string) {
  if (IS_PROD_BUILD && typeof window !== 'undefined') {
    const Clarity = await loadClarity();
    Clarity?.default?.setTag('page', tag);
  }
}

export { initClarity, identifyClarityUser, addClarityPageTag };
