import { Modal } from '@a_team/ui-components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Breakpoints, Button } from '@ateams/components';

interface NotInterestedConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
}

const useStyles = createUseStyles({
  modal: {
    padding: 24,
    '& > div': {
      padding: 0,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginTop: 0,
    marginBottom: 8,
  },
  desc: {
    color: '#818388',
    marginTop: 0,
    marginBottom: 40,
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12,

    '& > button': {
      borderRadius: 8,
      padding: '8px 16px',
      fontSize: 14,
      width: 140,
    },
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    modal: {
      width: '680px !important',
      padding: 40,
      '& > div': {
        padding: 0,
      },
    },
    buttonsWrapper: {
      // select btns
      '& > button': {
        width: 160,
      },
    },
  },
});

const NotInterestedConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  isLoading,
}: NotInterestedConfirmationModalProps): JSX.Element => {
  const styles = useStyles();
  return (
    <Modal
      shouldHideGradientStroke
      className={styles.modal}
      variant="slideUp"
      isOpen={open}
      onClose={onClose}
    >
      <div className={styles.container}>
        <h3 className={styles.title}>
          Are you sure you want to remove this mission?
        </h3>
        <p className={styles.desc}>
          When you mark that you are not interested in a mission, we remove it
          from the list of missions for you. You can bring these missions back
          by going to ‘Not interested’ tab.
        </p>
        <div className={styles.buttonsWrapper}>
          <Button onClick={onClose} size="small" color="regularLight">
            Never mind
          </Button>
          <Button
            onClick={onConfirm}
            size="small"
            color="primary"
            loading={!!isLoading}
            disabled={!!isLoading}
          >
            Remove
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NotInterestedConfirmationModal;
