import { observer } from 'mobx-react';
import React, { ChangeEvent, useMemo } from 'react';
import Section from '../partials/Section';
import { GuidanceTooltip } from '../partials/Section/GuidanceTooltip/GuidanceTooltip';
import { TooltipContent } from '../partials/Section/TooltipContent';
import { GuidanceMessage } from '../partials/Section/GuidanceMessage';
import {
  BorderColors,
  Breakpoints,
  Colors,
  FontSizes,
  FontWeights,
  HelpTooltip,
  IconType,
} from '@ateams/components';
import { createUseStyles } from 'react-jss';
import TextInput from '@src/components/Inputs/TextInput/TextInput';
import Profile from '@src/stores/Profile/Profile';
import { round } from 'lodash';

interface ProfileRatesProps {
  profile?: Profile;
}

const useStyles = createUseStyles({
  rateSection: {
    marginBottom: 48,
  },
  rateTitle: {
    fontWeight: FontWeights.semiBold,
    fontSize: FontSizes.regular,
  },
  rateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  rateLabel: {
    marginBottom: 12,
  },
  rateInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rateInput: {
    position: 'relative',
    margin: 0,
  },
  prefix: {
    position: 'absolute',
    left: 10,
    top: 9,
  },
  suffix: {
    position: 'absolute',
    right: 10,
    top: 9,
  },
  input: {
    border: `1px solid ${BorderColors.lighter}`,
    borderRadius: 4,
    padding: '8px 30px 8px 26px',
    width: 140,
    fontWeight: 400,
  },
  rateHint: {
    display: 'flex',
    background: Colors.backgroundLight,
    padding: '9px 12px',
    borderRadius: 4,
    gap: 8,
  },
  helpTooltip: {
    width: '300px !important',
  },
  rateTooltip: {
    padding: 0,
  },
  tooltipTitle: {
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.small,
    marginBottom: 16,
    textAlign: 'left',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  break: {
    margin: 0,
    background: Colors.regularLight,
  },
  bold: {
    fontWeight: FontWeights.bold,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    rateWrapper: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    rateLabel: {
      marginBottom: 0,
    },
  },
});

const AVG_WEEKS_PER_MONTH = 4.33;
const HOURS_PER_WEEK = 40;
const LOW_RATE_FACTOR = 0.75;
const HIGHT_RATE_FACTOR = 0.85;
const HIDE_HOURLY_EQUIVALENT = true; // BX-3335 hide for now

const ProfileRates = (props: ProfileRatesProps) => {
  const { profile } = props;
  const styles = useStyles();

  const handleHourlyRateChange = (e: ChangeEvent<HTMLInputElement>) =>
    profile?.updateDefaultHourlyRate(Number(e.target.value));

  const handleMonthlyRateChange = (e: ChangeEvent<HTMLInputElement>) =>
    profile?.updateDefaultMonthlyRate(Number(e.target.value));

  const lowMonthlyRecommendation = useMemo(() => {
    if (!profile?.minimumHourlyRate || profile.minimumHourlyRate === 0) {
      return '';
    }

    const recommendation = Math.round(
      profile?.minimumHourlyRate *
        AVG_WEEKS_PER_MONTH *
        HOURS_PER_WEEK *
        LOW_RATE_FACTOR,
    );

    return `$${recommendation.toLocaleString()}`;
  }, [profile?.minimumHourlyRate]);

  const highMonthlyRecommendation = useMemo(() => {
    if (!profile?.minimumHourlyRate || profile.minimumHourlyRate === 0) {
      return '';
    }

    const recommendation = Math.round(
      profile?.minimumHourlyRate *
        AVG_WEEKS_PER_MONTH *
        HOURS_PER_WEEK *
        HIGHT_RATE_FACTOR,
    );

    return `$${recommendation.toLocaleString()}`;
  }, [profile?.minimumHourlyRate]);

  const hourlyRateEquivalent = useMemo(() => {
    if (!profile?.minimumMonthlyRate || profile.minimumMonthlyRate === 0) {
      return 0;
    }

    return profile.minimumMonthlyRate / AVG_WEEKS_PER_MONTH / HOURS_PER_WEEK;
  }, [profile?.minimumMonthlyRate]);

  const monthlyRateEquivalent = useMemo(() => {
    return hourlyRateEquivalent * HOURS_PER_WEEK * AVG_WEEKS_PER_MONTH;
  }, [hourlyRateEquivalent]);

  const shouldShowHourlyEquivalent = useMemo(() => {
    if (HIDE_HOURLY_EQUIVALENT) {
      return false;
    }

    return (profile?.minimumMonthlyRate ?? 0) > 0;
  }, [profile?.minimumMonthlyRate]);

  const minimumHourlyRate = profile?.minimumHourlyRate ?? 0;
  const minimumMonthlyRate = profile?.minimumMonthlyRate ?? 0;
  const historicRate = profile?.historicRate ?? 0;

  return (
    <Section
      title="Rate"
      id="rate-section-profile"
      type="large"
      tooltipText="Please enter the number of hours you'll be available to work on Missions"
      guidanceTooltip={
        <GuidanceTooltip label={TooltipContent.profileRates.label}>
          {TooltipContent.profileRates.component}
        </GuidanceTooltip>
      }
      className={styles.rateSection}
    >
      <Section
        title={
          <h4 className={styles.rateTitle}>
            Hourly missions — variable hours based on company needs.
          </h4>
        }
      >
        {minimumHourlyRate === 0 && historicRate > 0 ? (
          <GuidanceMessage
            text={`Based on your past applications, we suggest a minimum hourly rate of $${round(
              historicRate,
              2,
            )} per hour.`}
            type={'secondary'}
            iconType={IconType.StarsPurple}
          />
        ) : null}
        <div className={styles.rateWrapper}>
          <div className={styles.rateLabel}>My minimum hourly rate is</div>
          <div className={styles.rateInputWrapper}>
            <div className={styles.rateInput}>
              <span className={styles.prefix}>$</span>
              <TextInput
                variant="dashed"
                placeholder={'XXX'}
                className={styles.input}
                value={minimumHourlyRate > 0 ? minimumHourlyRate : ''}
                onChange={handleHourlyRateChange}
                onBlur={handleHourlyRateChange}
              />
              <span className={styles.suffix}>/h</span>
            </div>
          </div>
        </div>
      </Section>
      <Section
        title={
          <h4 className={styles.rateTitle}>
            Monthly missions — fixed 40h per week and consistent income.
          </h4>
        }
        className={styles.rateSection}
      >
        {minimumMonthlyRate === 0 &&
          lowMonthlyRecommendation !== '' &&
          highMonthlyRecommendation !== '' && (
            <GuidanceMessage
              text={`Get noticed for monthly missions by charging ${lowMonthlyRecommendation} to ${highMonthlyRecommendation} per month.`}
              type={'secondary'}
              iconType={IconType.StarsPurple}
            />
          )}
        <div className={styles.rateWrapper}>
          <div className={styles.rateLabel}>My minimum monthly rate is</div>
          <div className={styles.rateInputWrapper}>
            <div className={styles.rateInput}>
              <span className={styles.prefix}>$</span>
              <TextInput
                variant="dashed"
                placeholder={'XXX'}
                className={styles.input}
                value={minimumMonthlyRate > 0 ? minimumMonthlyRate : ''}
                onChange={handleMonthlyRateChange}
                onBlur={handleMonthlyRateChange}
              />
              <span className={styles.suffix}>/m</span>
            </div>
          </div>
          {shouldShowHourlyEquivalent ? (
            <div className={styles.rateHint}>
              <span>{`Hourly equivalent — ${round(
                hourlyRateEquivalent,
                2,
              ).toLocaleString()}/h`}</span>
              <HelpTooltip className={styles.helpTooltip}>
                <div className={styles.rateTooltip}>
                  <h4 className={styles.tooltipTitle}>
                    How is your rate calculated
                  </h4>
                  <div className={styles.table}>
                    <div className={styles.row}>
                      <span>Hourly rate</span>
                      <span>{`$${round(
                        hourlyRateEquivalent,
                        2,
                      ).toLocaleString()} per hour`}</span>
                    </div>
                    <div className={styles.row}>
                      <span>Hours per week</span>
                      <span>x 40</span>
                    </div>
                    <div className={styles.row}>
                      <span>Average weeks in a month</span>
                      <span>x 4.33</span>
                    </div>
                    <hr className={styles.break} />
                    <div className={styles.row}>
                      <span className={styles.bold}>Monthly rate</span>
                      <span className={styles.bold}>
                        ${round(monthlyRateEquivalent, 2).toLocaleString()} per
                        month
                      </span>
                    </div>
                  </div>
                </div>
              </HelpTooltip>
            </div>
          ) : null}
        </div>
      </Section>
    </Section>
  );
};

export default observer(ProfileRates);
