import React, { ReactElement, useState } from 'react';
import { observer } from 'mobx-react';
import SectionHeading from '@src/components/SectionHeading';
import TimesheetsTable from './TimesheetsTable';
import {
  TimesheetId,
  TimesheetRecordData,
} from '@a_team/models/dist/TimesheetObject';
import ActionBar from './ActionBar';
import useLoadingState from '@src/hooks/useLoadingState';
import { MissionPaymentCycleId } from '@a_team/models/dist/MissionPaymentCycleObject';
import PaymentNotification from '@src/views/Mission/TimeTracking/PaymentNotification';
import ClosePaymentCycleModal from '@src/components/Modal/ClosePaymentCycleModal';
import { useStores } from '@src/stores';
import MissionLayout from '@src/layouts/Mission';
import { loadMission, MissionMatch } from '@src/url-loaders/loadMission';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { Redirect, useLocation } from 'react-router-dom';
import { MissionControlBase, MissionPageLocation } from '@src/locations';
import PaymentDetailsModal from '@src/components/Modal/PaymentDetailsModal';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';
import { InvoiceGenerateModal } from '@src/components/Modal/InvoiceGenerateModal';
import Submission from './Submission';
import { IconType } from '@ateams/components';
import NewTeamPulseModal from '../TeamPulse/components/newTeamPulseModal';
import TanstackTableWrapper from './Table';
import PaymentCyclesV2 from './PaymentCyclesV2/PaymentCycles';
import TeamPaymentCycles from './TeamPaymentCycles/TeamPaymentCycles';
import TimesheetsStatusesV2 from '../Admin/TimesheetsStatusesV2';
import AdminTable from './Table/AdminTable';
import Summary from './Summary';
import { FeatureFlagNames } from '@a_team/models/dist/FeatureFlag';
import { useEvaluateFeatureFlagForAccount } from '@src/rq/users';

interface Props {
  match: MissionMatch;
  adminView?: boolean;
}

const defaultProps: Partial<Props> = {
  adminView: false,
};

export const missionTimeTrackingViewLoader = loadMission;

const MissionTimeTrackingView = (props: Props): ReactElement | null => {
  const [summaryModalOpen, setSummaryModalOpen] = useState(false);
  const { match, adminView } = props;
  const stores = useStores();
  const { missions, auth, payments } = stores;
  const { currentMission } = missions;
  const [loading, setLoading] = useLoadingState();
  const [isSubmissionOpen, setSubmissionOpen] = useState(false);
  const [isSubmissionWithTeamPulseOpen, setIsSubmissionWithTeamPulseOpen] =
    useState(false);
  const [setupModalOpen, setSetupModalOpen] = useState(false);
  const [closeCycleModalOpen, setCloseCycleModalOpen] = useState(false);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [paymentCycleToSubmit, setPaymentCycleToSubmit] =
    useState<MissionPaymentCycleId>();
  const location = useLocation();
  const isTeamTimeTracking = location.pathname.includes('team-time-tracking');
  const [showErrorsForTimesheetId, setShowErrorsForTimesheetId] =
    useState<TimesheetId>();

  const {
    data: withTimesheetSummariesViaLLM,
    isLoading: isLoadingWithTimesheetSummariesViaLLM,
  } = useEvaluateFeatureFlagForAccount({
    accountId: currentMission?.accountId ?? '',
    uid: auth.uid ?? '',
    featureFlag: FeatureFlagNames.TimesheetSummariesViaLLM,
    enabled: !!currentMission?.accountId && !!auth.uid,
  });

  const addRecord = async (sid: TimesheetId, data: TimesheetRecordData) => {
    if (!currentMission) return;
    setLoading(currentMission.addRecord(sid, data));
  };

  const updateSelectedCycle = (yid: MissionPaymentCycleId) => {
    if (!currentMission) return;
    setLoading(true);
    currentMission &&
      setLoading(currentMission.setSelectedPaymentCycle(yid, adminView), null);
  };

  if (!currentMission) {
    return null;
  }

  const { cycleDates, timesheetsView, isMissionManager, currentUserRole } =
    currentMission;

  const isActiveRole =
    currentUserRole?.status === MissionRoleStatus.Active ||
    currentUserRole?.status === MissionRoleStatus.ScheduledToEnd;

  let notAllowed =
    (props.adminView && !auth.isAdmin) ||
    currentMission.timeTrackingTabDisabled;

  auth.isAdmin && (notAllowed = false);

  const onPaymentNotificationClick = () => {
    if (isLoadingWithTimesheetSummariesViaLLM) {
      return;
    }

    if (
      auth.withNewTimesheets &&
      currentMission.groupedUserTimesheetsRecords.length > 0 &&
      withTimesheetSummariesViaLLM
    ) {
      setSummaryModalOpen(true);
    } else {
      handleContinueAfterSummary();
    }
  };

  const handleContinueAfterSummary = () => {
    setSummaryModalOpen(false);
    const totalMinutes =
      currentMission.selectedPaymentCycle?.data.summary?.totalMinutes || 0;
    if (currentMission.nextTeamPulseSurvey && totalMinutes > 0) {
      setIsSubmissionWithTeamPulseOpen(true);
    } else {
      setSubmissionOpen(true);
    }
  };

  if (notAllowed) {
    return (
      <Redirect
        to={
          match.params.mid
            ? MissionPageLocation(match.params.mid)
            : MissionControlBase
        }
      />
    );
  }

  return (
    <MissionLayout
      title={`${currentMission.data.title} | Time Tracking`}
      match={props.match}
    >
      {payments.setupDetails && (
        <PaymentDetailsModal
          iframeUrl={payments.setupDetails.setupIFrameURL}
          open={setupModalOpen}
          onClose={() => {
            payments?.getSetupDetails();
            setSetupModalOpen(false);
          }}
        />
      )}

      {currentMission.selectedPaymentCycle && (
        <>
          <Submission
            isOpen={isSubmissionOpen}
            onCloseSubmission={() => setSubmissionOpen(false)}
            setLoading={setLoading}
          />

          {currentMission &&
            currentMission.nextTeamPulseSurvey &&
            isSubmissionWithTeamPulseOpen && (
              <NewTeamPulseModal
                withSubmitTimesheets
                onClose={() => setIsSubmissionWithTeamPulseOpen(false)}
                teamPulseSurvey={currentMission.nextTeamPulseSurvey}
              />
            )}

          {closeCycleModalOpen && (
            <ClosePaymentCycleModal
              mission={currentMission}
              defaultSelectedCycleId={currentMission.selectedPaymentCycle?.yid}
              onSubmit={(yid, request) =>
                setLoading(currentMission.closePaymentCycle(yid, request))
              }
              open={closeCycleModalOpen}
              onClose={() => setCloseCycleModalOpen(false)}
              data-testing-id="close-cycle-modal"
            />
          )}

          {invoiceModalOpen && currentMission.selectedPaymentCycle && (
            <InvoiceGenerateModal
              data-testing-id="generate-invoice-modal"
              onConfirm={(onlyCurrent) => {
                setInvoiceModalOpen(false);

                currentMission.selectedPaymentCycle &&
                  setLoading(
                    onlyCurrent
                      ? currentMission.generatePaymentCycleInvoice(
                          currentMission.selectedPaymentCycle.yid,
                        )
                      : currentMission.generateInvoices(),
                  );
              }}
              open
              onClose={() => setInvoiceModalOpen(false)}
            />
          )}
        </>
      )}
      {!adminView &&
        currentMission?.isPaymentSetupNoticeShown &&
        !currentMission.isPaymentAccountSuspended && (
          <PaymentNotification
            title={
              currentMission.isPaymentSetupPending
                ? `Verification in progress`
                : "Let's get you paid!"
            }
            text={
              currentMission.isPaymentSetupPending
                ? `We’re verifying your payment method, this usually takes 1-3 days.`
                : currentMission.hasPaymentIssues
                ? `There's an issue with your payment setup. Please setup again.`
                : "We just need a few payment details, and you'll be in business."
            }
            withButton={!currentMission.isPaymentSetupPending}
            icon={IconType.CreditCard}
            buttonText={'Set Up Payments'}
            onButtonClick={() => setSetupModalOpen(true)}
            backgroundColor={`linear-gradient(
            90deg,
            rgba(99, 42, 83, 1) 19%,
            rgba(65, 17, 98, 1) 52%,
            rgba(90, 40, 25, 1) 100%
          )`}
            buttonColor={'primary'}
            style={{ marginBottom: 50 }}
          />
        )}

      {auth.withNewTimesheets === false && (
        <SectionHeading isFirst style={{ marginBottom: '32px' }}>
          Time Tracking
        </SectionHeading>
      )}

      {auth.withNewTimesheets === false && (
        <ActionBar
          setLoading={setLoading}
          addRecord={addRecord}
          cycleDates={cycleDates}
          updateSelectedCycle={updateSelectedCycle}
          mission={currentMission}
          adminView={adminView}
          clientView={isMissionManager}
          disableAdd={
            !!currentMission.selectedPaymentCycle?.submitted || loading === true
          }
        />
      )}

      {auth.withNewTimesheets && (
        <>
          {isTeamTimeTracking ? (
            <TeamPaymentCycles
              mission={currentMission}
              updateSelectedCycle={updateSelectedCycle}
            />
          ) : (
            <PaymentCyclesV2
              adminView={adminView}
              isActiveRole={isActiveRole}
              mission={currentMission}
              updateSelectedCycle={updateSelectedCycle}
              setPaymentCycleToSubmit={setPaymentCycleToSubmit}
            />
          )}
        </>
      )}

      {auth.withNewTimesheets && adminView && (
        <TimesheetsStatusesV2
          mission={currentMission}
          setLoading={setLoading}
          setShowErrorsForTimesheetId={setShowErrorsForTimesheetId}
        />
      )}

      {currentMission.selectedPaymentCycle && auth.withNewTimesheets === false && (
        <SectionHeading>
          {currentMission.selectedPaymentCycle?.formattedStartDate} -{' '}
          {currentMission.selectedPaymentCycle?.formattedEndDate} Timesheet
        </SectionHeading>
      )}

      {auth.withNewTimesheets === false &&
        !adminView &&
        !isMissionManager &&
        currentMission.selectedPaymentCycle?.paymentNotificationShown &&
        !currentMission.isPaymentSetupNoticeShown &&
        isActiveRole && (
          <PaymentNotification
            data-testing-id="submit-timesheet-notification"
            title="It's time to get paid!"
            text="Check over this period’s timesheet. Then take a few seconds to check in and submit your timesheet."
            buttonText="Let’s Do It"
            withButton
            onButtonClick={onPaymentNotificationClick}
          />
        )}
      {adminView && (
        <>
          {currentMission.selectedPaymentCycle?.closeNotificationShown && (
            <PaymentNotification
              data-testing-id="close-payment-cycle-notification"
              title={`It’s Time to Close the Payment Cycle (${currentMission.selectedPaymentCycle?.formattedStartDate}-${currentMission.selectedPaymentCycle?.formattedEndDate})!`}
              text={
                "Check over this period's timesheets. Once everything looks good, hit the close button."
              }
              withButton
              buttonText={'Close Payment Cycle'}
              onButtonClick={() => setCloseCycleModalOpen(true)}
            />
          )}

          {currentMission.selectedPaymentCycle?.invoiceNotificationShown && (
            <PaymentNotification
              data-testing-id="generate-invoice-notification"
              title={`Generate an invoice for ${currentMission.selectedPaymentCycle?.formattedStartDate}-${currentMission.selectedPaymentCycle?.formattedEndDate}!`}
              text={
                "Check over this period's timesheets. Once everything looks good, hit the generate button."
              }
              withButton
              buttonText={'Generate an Invoice'}
              onButtonClick={() => setInvoiceModalOpen(true)}
            />
          )}
        </>
      )}

      {auth.withNewTimesheets && currentMission?.currentUserTimesheet?.sid && (
        <Summary
          summaryModalOpen={summaryModalOpen}
          setSummaryModalOpen={setSummaryModalOpen}
          sid={currentMission.currentUserTimesheet.sid}
          onContinue={handleContinueAfterSummary}
        />
      )}

      {timesheetsView && auth.withNewTimesheets === false && (
        <TimesheetsTable
          mission={currentMission}
          loading={loading}
          setLoading={setLoading}
          adminView={adminView}
          disabled={
            !!currentMission.selectedPaymentCycle?.submitted || loading === true
          }
        />
      )}
      {timesheetsView && auth.withNewTimesheets && !adminView && (
        <TanstackTableWrapper
          adminView={!!adminView}
          onPaymentNotificationClick={onPaymentNotificationClick}
          updateSelectedCycle={updateSelectedCycle}
          submitAfterLoading={
            !!paymentCycleToSubmit &&
            paymentCycleToSubmit ===
              currentMission?.selectedPaymentCycle?.data.yid
          }
          setPaymentCycleToSubmit={setPaymentCycleToSubmit}
        />
      )}

      {timesheetsView && auth.withNewTimesheets && adminView && (
        <AdminTable showErrorsForTimesheetId={showErrorsForTimesheetId} />
      )}

      <LoadingIndicator loading={loading} />
    </MissionLayout>
  );
};

MissionTimeTrackingView.defaultProps = defaultProps;

export default observer(MissionTimeTrackingView);
