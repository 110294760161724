import React, { ReactElement, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Proposal, { ProposalStoreData } from '@src/stores/Missions/Proposal';
import { Card } from '@ateams/components';
import TextButton from '@src/components/TextButton';
import { Checkbox } from '@ateams/components';
import UserAvatar from '@src/components/UserAvatar';
import { SetLoading } from '@src/hooks/useLoadingState';
import { ProfileTooltip } from '@src/views/Profile/ProfileTooltip';
import { getProposalDataCurrencySymbol } from './utils';
import { CLIENT_APP_V2_BASE_URL } from '@src/config';
import { useStores } from '@src/stores';

interface Props {
  proposals?: ProposalStoreData['proposals'];
  updateProposal: Proposal['updateProposal'];
  setLoading: SetLoading;
}

const useStyles = createUseStyles({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    border: '1px solid #C0C0C0',
    borderLeft: 'none',
    borderTop: 'none',
    fontSize: 15,
    fontWeight: 'normal',
    color: '#62646A',
    padding: 32,
    '&:last-child': {
      borderRight: 'none',
    },
  },
  tr: {
    borderBottom: '1px solid #C0C0C0',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  td: {
    padding: 24,
    borderRight: '1px solid #C0C0C0',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  checkbox: {
    justifyContent: 'center',
  },
});

export const ExistingProposalTable = (props: Props): ReactElement => {
  const styles = useStyles();
  const { proposals, updateProposal, setLoading } = props;
  const {
    account,
    records = [],
    users = [],
    roles = [],
    proposalURLs = [],
  } = proposals || {};
  const [showMoreBuilders, setShowMoreBuilders] = useState<
    Record<string, number>
  >({});
  const builderCountInUi = 4;

  const { auth } = useStores();

  const headers = ['Shared', 'Name', 'Builders', 'Roles', 'Created', 'Shared'];
  if (auth.withProposalsV3) {
    headers.push('Proposals V3');
  }

  const userMap = users?.reduce((acc, user) => {
    acc[user.uid] = user;
    return acc;
  }, {} as Record<string, typeof users[0]>);

  const roleMap = roles.reduce((acc, role) => {
    acc[role.rid] = role;
    return acc;
  }, {} as Record<string, typeof roles[0]>);

  const updateProposalIsShared = async (
    proposalId: string,
    isShared: boolean,
  ) => {
    setLoading(updateProposal({ proposalId, isShared }));
  };

  return (
    <>
      <Card style={{ padding: 0 }}>
        <table className={styles.table}>
          <tbody>
            <tr>
              {headers.map((header, i) => (
                <th key={i} className={styles.th}>
                  {header}
                </th>
              ))}
            </tr>
            {/* support old proposal data */}
            {!records?.length &&
              proposalURLs?.length &&
              proposalURLs.map((url) => {
                return (
                  <>
                    <tr>
                      <td colSpan={6}>
                        <TextButton
                          highlight
                          onClick={() => window.open(url, '_blank')}
                        >
                          {url}
                        </TextButton>
                      </td>
                    </tr>
                  </>
                );
              })}
            {records.map((record, i) => {
              const roleIds: string[] = [];

              const {
                pid,
                publicURL,
                createdAt,
                isShared,
                sharedBy,
                sharedAt,
                candidates = [],
                createdBy,
                version,
              } = record;
              candidates?.forEach((c) => {
                if (c.roleCategoryId) {
                  roleIds.push(c.roleCategoryId);
                }
              });
              const createdByUser = userMap[createdBy];
              const sharedByUser = sharedBy ? userMap[sharedBy] : null;
              const linkText =
                version === 2
                  ? `${account?.name ?? 'Unknown'} #${records.length - i}`
                  : publicURL;
              return (
                <tr key={i} className={styles.tr}>
                  <td className={styles.td}>
                    <Checkbox
                      className={styles.checkbox}
                      checked={isShared}
                      onChange={(e) =>
                        updateProposalIsShared(pid, e.target.checked)
                      }
                    />
                  </td>
                  <td className={styles.td}>
                    <a href={publicURL} target={'_blank'} rel="noreferrer">
                      <TextButton highlight>{linkText}</TextButton>
                    </a>
                  </td>

                  <td className={styles.td}>
                    {candidates
                      ?.filter(
                        (e, i) =>
                          i < Number(showMoreBuilders[pid] || builderCountInUi),
                      )
                      ?.map((candidate) => {
                        const user = userMap[candidate.userId];
                        if (!user?.uid) return null;
                        return (
                          <a href={`/${user.username}`} key={candidate.aid}>
                            <ProfileTooltip
                              title={`
${user.fullName}<br/>
${roleMap[candidate?.roleCategoryId ?? '']?.title}<br/>
${getProposalDataCurrencySymbol(record.currency)}${
                                candidate.hourlyRate?.toFixed(2) ?? '0.00'
                              }/hour`}
                            >
                              <UserAvatar
                                badgesInline
                                src={user.profilePictureURL}
                                size={24}
                                containerStyle={{ marginRight: '8px' }}
                              />
                            </ProfileTooltip>
                          </a>
                        );
                      })}
                    {candidates?.length > builderCountInUi && (
                      <span style={{ fontSize: '12px' }}>
                        {candidates.length >
                          (showMoreBuilders[pid] || builderCountInUi) && (
                          <TextButton
                            onClick={() =>
                              setShowMoreBuilders({
                                ...showMoreBuilders,
                                [pid]: candidates.length,
                              })
                            }
                          >
                            +{candidates.length - builderCountInUi}
                          </TextButton>
                        )}
                        {showMoreBuilders[pid] > builderCountInUi && (
                          <TextButton
                            onClick={() =>
                              setShowMoreBuilders({
                                ...showMoreBuilders,
                                [pid]: builderCountInUi,
                              })
                            }
                          >
                            - Show Less
                          </TextButton>
                        )}
                      </span>
                    )}
                  </td>
                  <td className={styles.td}>
                    {[...new Set(roleIds)]
                      .map((roleId) => roleMap[roleId]?.title)
                      .join(', ')}
                  </td>
                  <td className={styles.td}>
                    {createdByUser && (
                      <div>
                        <a
                          href={`/${createdByUser.username}`}
                          style={{ display: 'flex' }}
                        >
                          <UserAvatar
                            badgesInline
                            src={createdByUser.profilePictureURL}
                            size={24}
                            containerStyle={{ marginRight: '8px' }}
                          />
                          <div>
                            {createdByUser.fullName}
                            <span
                              style={{
                                display: 'block',
                                fontSize: '12px',
                                color: '#666',
                              }}
                            >
                              {new Date(createdAt).toLocaleString()}
                            </span>
                          </div>
                        </a>
                      </div>
                    )}
                  </td>
                  <td className={styles.td}>
                    {isShared && sharedByUser && (
                      <div>
                        <a
                          href={`/${sharedByUser.username}`}
                          style={{ display: 'flex' }}
                        >
                          <UserAvatar
                            badgesInline
                            src={sharedByUser.profilePictureURL}
                            size={24}
                            containerStyle={{ marginRight: '8px' }}
                          />
                          <div>
                            {sharedByUser.fullName}
                            <span
                              style={{
                                display: 'block',
                                fontSize: '12px',
                                color: '#666',
                              }}
                            >
                              {isShared && sharedAt && (
                                <>{new Date(sharedAt).toLocaleString()}</>
                              )}
                            </span>
                          </div>
                        </a>
                      </div>
                    )}
                  </td>
                  {auth.withProposalsV3 && (
                    <td className={styles.td}>
                      <a
                        href={`${CLIENT_APP_V2_BASE_URL}/proposals/${pid}`}
                        target={'_blank'}
                        rel="noreferrer"
                      >
                        <TextButton color="secondaryDark">
                          {linkText}
                        </TextButton>
                      </a>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </>
  );
};
