import React, { useMemo } from 'react';
import { Modal } from '@a_team/ui-components';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { useForm } from 'react-hook-form';
import {
  BorderColors,
  BorderRadius,
  Breakpoints,
  Button,
  Colors,
  FontSizes,
  FontWeights,
  HelpTooltip,
  Spacing,
  TextColors,
} from '@ateams/components';
import { round } from 'lodash';
import {
  AVG_WEEKS_PER_MONTH,
  HOURS_PER_WEEK,
} from './TeamWorkRolesInput/TeamWorkRoleInput';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAdminCreatePaymentTerm } from '@src/rq/missions';
import { PendingPaymentTerm } from '@a_team/models/dist/MissionRole';
import {
  toBuilderRate,
  toClientRate,
} from './TeamWorkRolesInput/RatesV2/utils';

const useStyles = createUseStyles({
  modal: {
    padding: 0,

    '& > div': {
      overflowX: 'hidden',
    },
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 40,
  },
  hero: {
    padding: '0 35px',
    textAlign: 'center',
  },
  modalTitle: {
    fontSize: FontSizes.large,
    fontWeight: FontWeights.medium,
    margin: 0,
    textAlign: 'center',
  },
  heroCopy: {
    color: '#818388',
    textAlign: 'center',
    padding: '0 15px',
  },
  ratesWrapper: {
    paddingBottom: 24,
    marginBottom: 24,
    borderBottom: `1px solid #D3D6DC`,
  },
  ratesRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    alignItems: 'center',
  },
  inputLabel: {
    fontWeight: FontWeights.medium,
    color: TextColors.regular,
    display: 'block',
    fontSize: FontSizes.regular,
    marginBottom: Spacing.small,
  },
  rateInput: {
    position: 'relative',
    margin: 0,
  },
  numberInput: {
    border: `1px solid ${BorderColors.lighter}`,
    borderRadius: BorderRadius.default,
    padding: `10px 30px 10px 20px`,
    maxWidth: 160,
    fontWeight: FontWeights.regular,

    '&.error': {
      borderColor: '#fe640b',
    },
  },
  prefix: {
    position: 'absolute',
    left: 10,
    top: 40,
  },
  suffix: {
    position: 'absolute',
    right: 10,
    top: 40,
    background: Colors.backgroundWhite,
  },
  operator: {
    marginTop: Spacing.large,
  },
  recipientsRow: {
    width: '100%',
  },
  lightCopy: {
    textAlign: 'center',
    color: '#818388',
    marginTop: 0,
    marginBottom: 16,
  },
  recipientRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'space-between',
    gap: 16,
    marginBottom: 16,
  },
  rateHint: {
    marginTop: 12,
    padding: 10,
    textAlign: 'center',
    background: '#F9FAFC',
    borderRadius: BorderRadius.default,
  },
  recipientInput: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    width: '100%',

    '& > label': {
      fontWeight: FontWeights.medium,
    },
  },
  textInput: {
    border: '1px solid #DADADC',
    padding: '8px 12px',
    borderRadius: 4,
    maxWidth: '100%',
  },
  buttonsRow: {
    display: 'flex',
    justifyContent: 'center',
    gap: 12,
    marginTop: 40,
  },
  button: {
    maxWidth: 185,
    borderRadius: 8,
    transition: 'background 0.3s ease',
    width: 'fit-content',
    padding: '8px 12px',
    fontSize: 14,
    minWidth: 132,
  },
  secondaryActionButton: {
    '&:hover': {
      background: '#DADADC',
    },
  },
  primaryActionButton: {
    '&:hover': {
      background: '#5900b3',
    },
  },
  helpTooltip: {
    width: '300px !important',
    left: 'calc(50% - 50px)', // to offset the width
  },
  rateTooltip: {
    padding: 0,
  },
  tooltipTitle: {
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.small,
    marginBottom: Spacing.medium,
    textAlign: 'left',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  break: {
    margin: 0,
    background: Colors.regularLight,
  },
  bold: {
    fontWeight: FontWeights.bold,
  },
  error: {
    color: '#fe640b',
    fontSize: 14,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      width: '100% !important',
      maxWidth: 720,
      padding: 24,
    },
  },
});

const NewPaymentTermSchema = z.object({
  builderRate: z.number().min(1, 'Builder rate is required'),
  margin: z.number().min(1, 'Role markeup is required'),
  clientRate: z.number().min(1, 'Client rate is required'),
  clientFirstName: z.string().min(1, 'First name is required'),
  clientLastName: z.string().min(1, 'Last name is required'),
  clientEmail: z.string().email('Invalid email address'),
  clientTitle: z.string().min(1, 'Title is required'),
});

export interface NewPaymentTermModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (pendingPaymentTerm: PendingPaymentTerm) => void;
  currentPaymentTerm: 'monthly' | 'hourly';
  missionId: string;
  roleId: string;
}

type NewRateTermRequest = z.infer<typeof NewPaymentTermSchema>;

const NewPaymentTermModal: React.FC<NewPaymentTermModalProps> = ({
  open,
  onClose,
  onSuccess,
  currentPaymentTerm,
  missionId,
  roleId,
}: NewPaymentTermModalProps) => {
  const styles = useStyles();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
    trigger,
    reset,
  } = useForm<NewRateTermRequest>({
    defaultValues: {
      builderRate: undefined,
      margin: undefined,
      clientRate: undefined,
      clientFirstName: '',
      clientLastName: '',
      clientEmail: '',
      clientTitle: '',
    },
    resolver: zodResolver(NewPaymentTermSchema),
  });
  const {
    mutate: createPaymentTerm,
    isLoading: isCreating,
    isError,
    error: remoteError,
  } = useAdminCreatePaymentTerm(onSuccess);

  const newPaymentRequest: NewRateTermRequest = {
    builderRate: watch('builderRate'),
    margin: watch('margin'),
    clientRate: watch('clientRate'),
    clientFirstName: watch('clientFirstName'),
    clientLastName: watch('clientLastName'),
    clientEmail: watch('clientEmail'),
    clientTitle: watch('clientTitle'),
  };

  const hourlyRateEquivalent = useMemo(() => {
    if (!newPaymentRequest.builderRate) {
      return 0;
    }

    return newPaymentRequest.builderRate / AVG_WEEKS_PER_MONTH / HOURS_PER_WEEK;
  }, [newPaymentRequest.builderRate]);

  // the parent component has some weird re-render issue causing the form to re-render
  // that's why i had to hook it up to the submit button and stop the default behavior
  // not sure what else can we do here
  const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    handleSubmit((data) => {
      createPaymentTerm({
        ...data,
        type: currentPaymentTerm === 'hourly' ? 'monthly' : 'hourly',
        missionId,
        roleId,
      });
    })(e);
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Modal
      isOpen={open}
      onClose={handleClose}
      variant="slideUp"
      shouldCloseOnOverlayClick={false}
      className={styles.modal}
      canClose={!isCreating}
      shouldHideGradientStroke
    >
      <div className={styles.modalContainer}>
        <div className={styles.hero}>
          <h4 className={styles.modalTitle}>
            Would you like to update the rate type?
          </h4>
          <p className={styles.heroCopy}>
            When you save changes, the builder and the client will be notified
            of the rate change, and will both be required to sign new contracts.
            Once both contracts are signed, the new rate type will go into
            effect at the start of the next billing cycle.
          </p>
          {isError && (
            <span className={styles.error}>
              {(remoteError as Error).message}
            </span>
          )}
        </div>
        <form className={styles.formContainer}>
          <div className={styles.ratesWrapper}>
            <div className={styles.ratesRow}>
              <div className={styles.rateItem}>
                <div className={styles.rateInput}>
                  <span className={styles.prefix}>$</span>
                  <label className={styles.inputLabel}>Builder Rate</label>
                  <input
                    type="number"
                    placeholder={'Builder rate...'}
                    className={cx(styles.numberInput, {
                      error: !!errors.builderRate?.message,
                    })}
                    {...register('builderRate', {
                      setValueAs: (v) => Number(v),
                      onChange: (e) => {
                        const builderRate = Number(e.currentTarget.value);
                        if (!isNaN(builderRate)) {
                          const margin = getValues('margin');

                          setValue(
                            'clientRate',
                            toClientRate(builderRate, margin),
                          );
                          // on builder rate change trigger client rate validation
                          trigger('clientRate');
                        }
                      },
                    })}
                  />
                  <span className={styles.suffix}>
                    {currentPaymentTerm === 'hourly' ? '/m' : '/hr'}
                  </span>
                </div>
              </div>
              <div className={styles.operator}>x</div>
              <div className={styles.rateItem}>
                <div className={styles.rateInput}>
                  <label className={styles.inputLabel}>Role markeup</label>
                  <input
                    type="number"
                    placeholder={'Role markeup...'}
                    className={cx(styles.numberInput, {
                      error: !!errors.margin?.message,
                    })}
                    {...register('margin', {
                      setValueAs: (v) => Number(v),
                      onChange: (e) => {
                        const margin = Number(e.currentTarget.value);
                        const builderRate = getValues('builderRate');

                        setValue(
                          'clientRate',
                          toClientRate(builderRate, margin),
                        );
                        // on margin change, trigger client rate validation
                        trigger('clientRate');
                      },
                    })}
                  />
                  <span className={styles.suffix}>%</span>
                </div>
              </div>
              <div className={styles.operator}>=</div>
              <div className={styles.rateItem}>
                <div className={styles.rateInput}>
                  <span className={styles.prefix}>$</span>
                  <label className={styles.inputLabel}>Client rate</label>
                  <input
                    type="number"
                    placeholder={'Client rate...'}
                    className={cx(styles.numberInput, {
                      error: !!errors.clientRate?.message,
                    })}
                    {...register('clientRate', {
                      setValueAs: (v) => Number(v),
                      onChange: (e) => {
                        const updatedRate = Number(e.currentTarget.value);
                        const margin = getValues('margin');
                        const newBuilderRate = toBuilderRate(
                          updatedRate,
                          margin,
                        );

                        setValue('builderRate', newBuilderRate);
                        // on client rate change trigger builder rate validation
                        trigger('builderRate');
                      },
                    })}
                  />
                  <span className={styles.suffix}>
                    {currentPaymentTerm === 'hourly' ? '/m' : '/hr'}
                  </span>
                </div>
              </div>
            </div>
            {currentPaymentTerm === 'hourly' && (
              <div className={styles.rateHint}>
                This monthly rate equivalent to
                <span>{` ${hourlyRateEquivalent.toLocaleString()} per hour `}</span>
                <HelpTooltip className={styles.helpTooltip}>
                  <div className={styles.rateTooltip}>
                    <h4 className={styles.tooltipTitle}>
                      Hourly rate equivalent
                    </h4>
                    <div className={styles.table}>
                      <div className={styles.item}>
                        <span>Monthly rate</span>
                        <span>{`$${round(
                          newPaymentRequest.builderRate ?? 0,
                          2,
                        ).toLocaleString()} per month`}</span>
                      </div>
                      <div className={styles.item}>
                        <span>Hours per week</span>
                        <span>x 40</span>
                      </div>
                      <div className={styles.item}>
                        <span>Average weeks in a month</span>
                        <span>x 4.33</span>
                      </div>
                      <hr className={styles.break} />
                      <div className={styles.item}>
                        <span className={styles.bold}>Hourly rate</span>
                        <span className={styles.bold}>
                          ${hourlyRateEquivalent.toLocaleString()} per hour
                        </span>
                      </div>
                    </div>
                  </div>
                </HelpTooltip>
              </div>
            )}
          </div>
          <div className={styles.recipientsRow}>
            <p className={styles.lightCopy}>
              Indicate where the client service order should be sent:
            </p>
            <div className={styles.recipientRow}>
              <div className={styles.recipientInput}>
                <label>First name</label>
                <input
                  placeholder="Client's first name"
                  type="text"
                  className={styles.textInput}
                  {...register('clientFirstName', { required: true })}
                />
                <span className={styles.error}>
                  {errors.clientFirstName?.message ?? ''}
                </span>
              </div>
              <div className={styles.recipientInput}>
                <label>Last name</label>
                <input
                  placeholder="Client's last name"
                  type="text"
                  className={styles.textInput}
                  {...register('clientLastName', { required: true })}
                />
                <span className={styles.error}>
                  {errors.clientLastName?.message ?? ''}
                </span>
              </div>
            </div>
            <div className={styles.recipientRow}>
              <div className={styles.recipientInput}>
                <label>Email address</label>
                <input
                  placeholder="Client's email address"
                  type="text"
                  className={styles.textInput}
                  {...register('clientEmail', { required: true })}
                />
                <span className={styles.error}>
                  {errors.clientEmail?.message ?? ''}
                </span>
              </div>
              <div className={styles.recipientInput}>
                <label>Title</label>
                <input
                  placeholder="Client's title"
                  type="text"
                  className={styles.textInput}
                  {...register('clientTitle', { required: true })}
                />
                <span className={styles.error}>
                  {errors.clientTitle?.message ?? ''}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.buttonsRow}>
            <Button
              className={cx(styles.button, styles.secondaryActionButton)}
              onClick={handleClose}
              size="small"
              color={'backgroundDark'}
              disabled={isCreating}
            >
              Cancel
            </Button>

            <Button
              className={cx(styles.button, styles.primaryActionButton)}
              size="small"
              color={'secondaryDark'}
              type="submit"
              onClick={onSubmit}
              disabled={isCreating}
            >
              {isCreating ? 'Please wait...' : 'Confirm'}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default NewPaymentTermModal;
