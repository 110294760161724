import TextEditor from '@src/components/TextEditor';
import { useEditor } from '@src/hooks/useEditor';
import { Editor } from '@tiptap/react';
import React, { useMemo } from 'react';
import { UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { FormValues } from '.';

interface DescriptionProps {
  setValue: UseFormSetValue<FormValues>;
  trigger: UseFormTrigger<FormValues>;
  watch: UseFormWatch<FormValues>;
}

const useStyles = createUseStyles({
  container: {
    // fixes issue placeholder not showing up https://github.com/ueberdosis/tiptap/issues/2659#issuecomment-1081864697
    '& .ProseMirror p.is-editor-empty:first-child::before': {
      content: 'attr(data-placeholder)',
      float: 'left',
      color: '#adb5bd',
      pointerEvents: 'none',
      height: 0,
    },
  },
  label: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '24px',
  },
});

const Description = ({
  setValue,
  trigger,
  watch,
}: DescriptionProps): JSX.Element => {
  const descriptionInitialValue = useMemo(() => {
    let initialValue = ``;
    const descriptionHTML = watch('descriptionHTML');
    const description = watch('description');
    if (descriptionHTML) {
      initialValue = descriptionHTML;
    } else if (description && description.includes('\n')) {
      const paragraphs = description.split('\n\n');

      initialValue = paragraphs
        .filter((paragraph) => paragraph.trim() !== '')
        .map((paragraph) => {
          const formattedParagraph = paragraph.replace(/\n/g, '<br>');
          return `<p>${formattedParagraph}</p>`;
        })
        .join('');
    } else {
      initialValue = `<p>${description}</p>`;
    }

    return initialValue;
  }, [watch('descriptionHTML'), watch('description')]);

  const styles = useStyles();
  const { editor } = useEditor({
    noTitle: true,
    placeholder: 'Add a description',
    initialValue: descriptionInitialValue,
    onDescriptionChange: (editor: Editor) => {
      setValue('description', editor.getText(), {
        shouldDirty: true,
      });
      setValue('descriptionHTML', editor.getHTML());
      trigger('description');
      trigger('descriptionHTML');
    },
  });

  const charCount = editor?.getText().length;

  return (
    <div className={styles.container}>
      <div>
        <label className={styles.label}>Description</label>
      </div>
      {editor && (
        <TextEditor
          hideHeading
          maxCharCount="2,000"
          editor={editor}
          charCount={charCount}
        />
      )}
    </div>
  );
};

export default Description;
