import { useSetCurrentUserCV } from '@src/rq/profile';
import { useEnhanceProfileWithPolling } from '@src/rq/profileSuggestions';
import * as uploadcareApi from '@src/logic/uploadcare';
import { FileRejection, FileWithPath } from 'react-dropzone';
import useLoadingState from './useLoadingState';

interface UseResumeUploadProps {
  onUpload?: (downloadUrl: string) => void;
  onError?: (error: Error) => void;
}

export const useResumeUpload = ({
  onUpload,
  onError,
}: UseResumeUploadProps = {}) => {
  const [uploading, setUploading] = useLoadingState(null);
  const { mutate: updateResumeURL } = useSetCurrentUserCV();
  const { initiateSuggestionsPolling } = useEnhanceProfileWithPolling();

  const handleUpload = async (downloadUrl: string) => {
    await updateResumeURL(downloadUrl);
    onUpload?.(downloadUrl);

    setUploading('Resume Uploaded');
    setUploading(null);
    initiateSuggestionsPolling();
  };
  const handleFileSelected = async (
    files: Array<FileWithPath>,
    rejectedFiles: Array<FileRejection>,
  ) => {
    try {
      setUploading(null);

      if (rejectedFiles.length > 0) {
        const error = new Error(rejectedFiles[0].errors[0].message);
        setUploading(error);
        onError?.(error);
        return;
      }

      if (!files.length) return;

      const [file] = files;
      setUploading(true);

      const downloadUrl = await uploadcareApi.uploadFile(
        new File([file], file.name, { type: file.type }),
      );

      handleUpload(downloadUrl);
    } catch (err) {
      const error = err as Error;
      setUploading(error);
      onError?.(error);
    }
  };

  return {
    uploading,
    handleUpload,
    handleFileSelected,
  };
};
