import React, { ReactElement, useEffect, useMemo, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import TeamWorkRoleInput from './TeamWorkRoleInput';
import MissionRole from '@a_team/models/dist/MissionRole';
import { MissionStoreData } from '@src/stores/Missions/Mission';
import { BasicUserObject, UserId } from '@a_team/models/dist/UserObject';
import {
  BorderRadius,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
} from '@ateams/components';
import cx from 'classnames';
import UserAvatar from '@src/components/UserAvatar';
import { sortBy } from 'lodash';

export interface EditedMissionRole extends MissionRole {
  edited?: boolean;
}

interface Props {
  missionId?: string;
  roles: Array<MissionRole | EditedMissionRole>;
  onChange(role: EditedMissionRole): void;
  onDelete(role: EditedMissionRole): void;
  disabled?: boolean;
  applications?: MissionStoreData['applications'];
}

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    gap: Spacing.medium,
  },
  form: {
    flex: 3,
  },
  roleList: {
    flex: 1,
    background: Colors.regularLight,
    borderRadius: BorderRadius.default,
    overflow: 'hidden',
    minWidth: 200,
    '& ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      '& li': {
        '& button': {
          width: '100%',
          padding: `${Spacing.small}px ${Spacing.medium}px`,
          background: 'transparent',
          cursor: 'pointer',
          textAlign: 'left',
          border: 'none',
          display: 'flex',
          alignItems: 'center',
          fontSize: FontSizes.small,
          '&.active': {
            background: Colors.primaryLight,
          },
          '& img': {
            marginRight: Spacing.small,
          },
        },
      },
    },
  },
  categoryTitle: {
    fontWeight: FontWeights.medium,
  },
  user: {},
  navLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default function TeamWorkRolesInput(props: Props): ReactElement {
  const { roles, disabled = false, onChange, onDelete } = props;
  const [activeRoleIdx, setActiveRoleIdx] = React.useState(0);

  // use ref to avoid re-rendering the whole component and get the latest value
  const rolesRef = useRef(roles);

  useEffect(() => {
    rolesRef.current = roles;
  }, [roles]);

  const styles = useStyles();

  const handleRoleChange = (role: MissionRole): void => {
    onChange(role);
  };

  const handleRoleRemove = (role: MissionRole): void => {
    setActiveRoleIdx(0);
    onDelete(role);
  };

  const sortedRoles = useMemo(() => sortBy(roles, 'category.title'), [roles]);

  const roleApplicants = (role: MissionRole): UserId[] | undefined => {
    return props.applications
      ?.filter((application) => application.rid === role.rid)
      .map((application) => application.user.uid);
  };

  return (
    <div className={styles.root}>
      <div className={styles.roleList}>
        <ul>
          {sortedRoles.map((role, idx) => (
            <li key={role.rid}>
              <button
                type="button"
                onClick={() => setActiveRoleIdx(idx)}
                className={cx({ active: idx === activeRoleIdx })}
              >
                {(role.user as BasicUserObject)?.profilePictureURL ? (
                  <UserAvatar
                    size={25}
                    src={(role.user as BasicUserObject)?.profilePictureURL}
                  />
                ) : null}
                <span className={styles.navLabel}>
                  <span className={styles.categoryTitle}>
                    {role.category.title}
                  </span>
                  <span className={styles.user}>
                    {role.user ? role.user.fullName || role.user.email : null}
                  </span>
                </span>
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.form}>
        {sortedRoles.map((role, idx) => (
          <TeamWorkRoleInput
            missionId={props.missionId}
            key={`${role.rid}-${role.status}`}
            role={role}
            onChange={(role): void => handleRoleChange(role)}
            onRemove={
              !disabled ? (): void => handleRoleRemove(role) : undefined
            }
            disabled={!!disabled}
            roleApplicants={roleApplicants(role)}
            visible={idx === activeRoleIdx}
          />
        ))}
      </div>
    </div>
  );
}
