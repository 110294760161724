import React, { memo, ReactElement, useCallback, useMemo } from 'react';
import { SidebarSection } from '@src/views/Profile/Sidebar/SidebarSection/index';
import { propComparison } from '@src/helpers/propComparison';
import { Tag, TagList } from '@ateams/components';
import { ShowMoreButton } from '@src/views/VettingFeedbackForm/components/show-more';
import { createUseStyles } from 'react-jss';

interface Props {
  title: string;
  items: string[];
  onChange(items: string[]): void;
  onAddClick?: () => void;
  readonly?: boolean;
  error?: boolean;
  disabled?: boolean;
  tooltipErrorLabel?: string;
  tooltipEmptyLabel?: string;
  id?: string;
}

const useStyles = createUseStyles({
  tags: {
    marginTop: 16,
    flexWrap: 'wrap',
  },
  tag: {
    padding: 10,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
  },
});

const DEFAULT_NUMBER_OF_ITEMS_TO_SHOW = 4;

export const SidebarTagList = memo((props: Props): ReactElement => {
  const {
    title,
    items,
    onChange,
    onAddClick,
    readonly = true,
    disabled,
    error,
    tooltipErrorLabel,
    tooltipEmptyLabel,
    id,
  } = props;

  const styles = useStyles();
  const handleRemove = useCallback(
    (tag: string) => () => {
      onChange(items.filter((item) => item !== tag));
    },
    [items],
  );

  const showAll = !!readonly && items.length > DEFAULT_NUMBER_OF_ITEMS_TO_SHOW;

  const [numberOfItemsToShow, setNumberOfItemsToShow] = React.useState(
    DEFAULT_NUMBER_OF_ITEMS_TO_SHOW,
  );

  const itemsToShow = useMemo(() => {
    let itemsToDisplay: string[] = [];
    if (readonly) {
      itemsToDisplay = items.slice(0, numberOfItemsToShow);
    } else {
      itemsToDisplay = [...items];
    }
    return itemsToDisplay;
  }, [items, numberOfItemsToShow, readonly]);

  const isShowingMore = itemsToShow.length === items.length;

  const handleShowAll = () => {
    if (isShowingMore) {
      setNumberOfItemsToShow(DEFAULT_NUMBER_OF_ITEMS_TO_SHOW);
    } else {
      setNumberOfItemsToShow(items.length);
    }
  };

  return (
    <SidebarSection
      title={title}
      onAddClick={() => {
        props.onChange([...items, '']);
        onAddClick && onAddClick();
      }}
      readonly={readonly}
      disabled={disabled}
      error={error}
      empty={!items.length}
      tooltipErrorLabel={tooltipErrorLabel}
      tooltipEmptyLabel={tooltipEmptyLabel}
      id={id}
    >
      <TagList className={styles.tags}>
        {itemsToShow.map((tag) => (
          <Tag
            className={styles.tag}
            key={tag}
            onRemove={!readonly ? handleRemove(tag) : undefined}
            color="backgroundLight"
          >
            {tag}
          </Tag>
        ))}
      </TagList>
      {showAll && (
        <ShowMoreButton
          showMoreLabel="Show all"
          showLessLabel="Show less"
          isShowingMore={isShowingMore}
          onClick={handleShowAll}
          style={{ marginTop: 16 }}
        />
      )}
    </SidebarSection>
  );
}, propComparison('onChange'));
